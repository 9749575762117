import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../../styles/styles'
import { useIntl } from 'react-intl'

const RadioButtonGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  border: 2px solid ${colors.black};

  &:after {
    transition: opacity 0.25s ease-out;
    opacity: 0;
    content: '';
    position: absolute;
    top: 0.35rem;
    left: 0.35rem;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background: ${colors.black};
  }
`

const RadioButton = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ ${Checkmark}:after {
    opacity: 1;
  }

  input:checked ~ ${Checkmark} {
    border: 2px solid ${colors.black};
    border-radius: 50%;
  }

  input:checked ~ ${Checkmark}:after {
    opacity: 1;
  }

  &:not(:last-child) {
    margin-bottom: ${spacing.lg};
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`

type Props = {
  options: { key: { id: string; defaultMessage: string }; value: string }[]
  selected: string
  onSelected: (option: string) => void
}

export const RadioButtonGroup: FunctionComponent<Props> = ({
  options,
  selected,
  onSelected,
}) => {
  const intl = useIntl()

  return (
    <RadioButtonGroupStyled>
      {options.map((option) => (
        <RadioButton htmlFor={option.key.id}>
          <input
            id={option.key.id}
            key={`option-${option.key.id}`}
            type="radio"
            value={option.value}
            checked={selected === option.value}
            onChange={() => onSelected(option.value)}
          />
          {intl.formatMessage(option.key)}
          <Checkmark />
        </RadioButton>
      ))}
    </RadioButtonGroupStyled>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { CardItem } from '../../../shared/card/Card'
import { Button } from '../../../shared/Buttons'
import { SearchField } from '../../../shared/SearchField'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { UserListItem } from '../../../shared/list'
import { ListItemLoader } from '../../../shared/Loading'
import { ErrorMessage } from '../../../shared/Messages'
import { ConfirmDialog } from '../../../shared/modal/ConfirmDialog'
import {
  Common_Cancel,
  Common_Edit,
  Common_ErrorOccurred,
  Common_Moderator,
  Moderator_NotSet,
  Moderator_Set,
  Moderator_Update,
  Users_SearchPlaceholder,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { spacing } from '../../../../styles/styles'

type Props = {
  serviceReceiver: any
  updateModerator: (userId: string) => Promise<void>
}

export const Moderator: FunctionComponent<Props> = ({
  serviceReceiver,
  updateModerator,
}) => {
  const intl = useIntl()
  const [showModeratorForm, setShowModeratorForm] = useState<boolean>(false)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [users, setUsers] = useState<any[]>([])
  const [term, setTerm] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<any>(undefined)
  const [error, setError] = useState<string>('')
  const [showConfirmSetModerator, setShowConfirmSetModerator] = useState<
    boolean
  >(false)

  const canUpdate = true

  const onUpdateModerator = (user: any) => {
    setShowModeratorForm(false)
    setSelectedUser(user)
    setError('')
    setShowConfirmSetModerator(true)
  }

  const confirmSetModerator = () => {
    setShowConfirmSetModerator(false)

    updateModerator(selectedUser.id)
      .then(() => setSelectedUser(undefined))
      .catch(() => setError(intl.formatMessage(Common_ErrorOccurred)))
  }

  useEffect(() => {
    if (term.length > 2) {
      setStatus('pending')

      authenticatedFetch(`${environment.API_URL}/users/search?term=${term}`)
        .then(({ results }) => {
          setStatus('idle')
          setUsers(results)
        })
        .catch(() => {
          setStatus('failure')
        })
    }
  }, [term])

  return (
    <React.Fragment>
      <h3 id="moderatorTitle" style={{ marginBottom: spacing.sm }}>
        {intl.formatMessage(Common_Moderator)}
      </h3>

      <CardItem aria-labelledby="moderatorTitle">
        {selectedUser ? (
          <ListItemLoader />
        ) : showModeratorForm ? (
          <React.Fragment>
            <SearchField
              searching={status === 'pending'}
              placeholder={Users_SearchPlaceholder}
              onChange={(searchTerm) => setTerm(searchTerm)}
              results={users}
              itemSelected={(user) => onUpdateModerator(user)}
              ListItemComponent={UserListItem}
            />
            <Button
              label={intl.formatMessage(Common_Cancel)}
              ariaLabel={intl.formatMessage(Common_Cancel)}
              onClick={() => setShowModeratorForm(false)}
              isSmall={true}
              variant="secondary"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {serviceReceiver.moderator?.name ??
              intl.formatMessage(Moderator_NotSet)}

            {canUpdate && (
              <Button
                label={intl.formatMessage(
                  serviceReceiver.moderator ? Common_Edit : Moderator_Set
                )}
                ariaLabel={intl.formatMessage(
                  serviceReceiver.moderator ? Common_Edit : Moderator_Set
                )}
                onClick={() => setShowModeratorForm(true)}
                isSmall={true}
                variant="primary"
              />
            )}
          </React.Fragment>
        )}
      </CardItem>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <ConfirmDialog
        show={showConfirmSetModerator}
        title={Moderator_Update}
        onClose={() => setShowConfirmSetModerator(false)}
        onConfirm={confirmSetModerator}
        onCancel={() => {
          setShowConfirmSetModerator(false)
          setSelectedUser(undefined)
        }}
      />
    </React.Fragment>
  )
}

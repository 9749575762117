import React, { FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'

type IconProps = {
  size: 'small' | 'medium'
}

const Icon = styled.div`
  margin-right: ${spacing.md};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: IconProps) => (props.size === 'small' ? '2rem' : '3.75rem')};
  height: ${(props: IconProps) =>
    props.size === 'small' ? '2rem' : '3.75rem'};
  border-radius: 50%;
  background: ${colors.joda};

  svg {
    color: white;
    font-size: ${(props: IconProps) =>
      props.size === 'small' ? '1rem' : '1.5rem'};
  }
`

type Props = {
  icon: any
  size?: 'small' | 'medium'
}

export const ListAvatarIcon: FunctionComponent<Props> = ({ icon, size }) => {
  return (
    <Icon size={size ?? 'medium'}>
      <FontAwesomeIcon icon={icon} />
    </Icon>
  )
}

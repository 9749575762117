import { TranslatedMessage } from '../types'
import {
  Common_Book,
  Common_Calendar,
  Common_Groups,
  Common_Institutions,
  Common_Messages,
  Common_Moderator,
  Common_More,
  Common_ServiceReceiver,
  Common_ServiceReceivers,
  EULA_Terms,
} from '../translations/messages'

export const pathnameMap: { [key: string]: TranslatedMessage } = {
  '/feed': Common_Book,
  '/service-receivers': Common_ServiceReceivers,
  '/service-receiver': Common_ServiceReceiver,
  '/groups': Common_Groups,
  '/messages': Common_Messages,
  '/calendar': Common_Calendar,
  '/more': Common_More,
  '/moderator': Common_Moderator,
  '/institutions': Common_Institutions,
  '/eula': EULA_Terms,
}

export const rootRoutes = [
  '/feed',
  '/calendar',
  '/service-receivers',
  '/service-receiver',
  '/groups',
  '/messages',
  '/more',
]

import styled from 'styled-components'
import { breakpoints, colors, spacing } from '../../../../styles/styles'
import React, { FunctionComponent } from 'react'
import { H1 } from '../../../shared/Typography'

const Illustration = styled.div`
  display: none;
  text-align: center;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    display: inline-block;
    flex: 1;

    img {
      width: 100%;
      height: auto;
    }
  }
`

const FlexContainer = styled.div`
  display: flex;
  padding: ${spacing.xl2} ${spacing.xl};
  min-height: 80vh;
  max-height: 90vh;
  align-items: center;
  gap: ${spacing.xl};
`

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints.XSMALL}) {
    width: 80%;
  }
`

const Steps = styled.div`
  margin-bottom: ${spacing.lg};
  display: flex;
  gap: ${spacing.lg};
  justify-content: center;
`

const Step = styled.div`
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background: ${colors.black30};

  &.current {
    background: ${colors.black};
  }
`

type Props = {
  title?: string
  illustration: string
  numSteps?: number
  currentStep?: number
}

export const WizardContainer: FunctionComponent<Props> = ({
  title,
  illustration,
  children,
  numSteps = 0,
  currentStep = 1,
}) => {
  const steps = Array.from(Array(numSteps).keys())

  return (
    <FlexContainer>
      <Illustration>
        <Steps>
          {steps.map((step) => (
            <Step className={step <= currentStep - 1 ? 'current' : ''} />
          ))}
        </Steps>

        {title && (
          <div style={{ marginBottom: spacing.lg }}>
            <H1>{title}</H1>
          </div>
        )}

        <img alt="Illustrasjon" src={illustration} />
      </Illustration>
      <TextContainer>{children}</TextContainer>
    </FlexContainer>
  )
}

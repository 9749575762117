import React, { Dispatch, useContext, useEffect, useState } from 'react'
import { createContext, FunctionComponent } from 'react'
import { authenticatedFetch } from '../lib/service'
import { environment } from '../environments'

type UserCtxt = {
  hasProvider: boolean
  user: any
  setUser: Dispatch<any>
  profile: any
  setProfile: Dispatch<any>
}

export const UserContext = createContext<UserCtxt>({
  hasProvider: false,
  user: undefined,
  setUser: () => {},
  profile: undefined,
  setProfile: () => {},
})

export const UserProvider: FunctionComponent = ({ children }) => {
  const hasProvider = true
  const [user, setUser] = useState<any>()
  const [profile, setProfile] = useState<any>()

  return (
    <UserContext.Provider
      value={{
        hasProvider,
        user,
        setUser,
        profile,
        setProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = (id?: string) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [profileStatus, setProfileStatus] = useState<
    'idle' | 'pending' | 'failure'
  >('idle')

  const context = useContext(UserContext)
  if (!context.hasProvider) {
    throw new Error('useUser must be used within a UserProvider')
  }

  const { user, setUser, profile, setProfile } = context

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/users/${id}`)
      .then((response) => {
        setUser(response)
        setStatus('idle')
      })
      .catch((error) => {
        setStatus('failure')
      })

    authenticatedFetch(`${environment.API_URL}/users/${id}/profile`)
      .then((response) => {
        setProfile(response)
        setProfileStatus('idle')
      })
      .catch((error) => {
        setProfileStatus('failure')
      })
  }, [id, setProfile, setUser])

  const updateUser = (data: any): Promise<void> => {
    setStatus('pending')

    return authenticatedFetch(
      `${environment.API_URL}/users/${user.id}`,
      {
        method: 'PATCH',
      },
      data
    )
      .then((updated) => {
        setStatus('idle')
        setUser(updated)
      })
      .catch(() => {
        setStatus('failure')
        throw new Error('Unable to update user')
      })
  }

  const updateProfile = (data: any): Promise<void> => {
    setStatus('pending')

    return authenticatedFetch(
      `${environment.API_URL}/users/${user.id}/profile`,
      {
        method: 'PATCH',
      },
      data
    )
      .then((updated) => {
        setStatus('idle')
        setProfile(updated)
      })
      .catch(() => {
        setStatus('failure')
        throw new Error('Unable to update user profile')
      })
  }

  return {
    status,
    user,
    profileStatus,
    profile,
    updateUser,
    updateProfile,
  }
}

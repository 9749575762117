import styled from 'styled-components'
import { colors } from '../../styles/styles'
import React, { CSSProperties, FunctionComponent, useState } from 'react'
import fallback from '../../images/no-profile-img.png'

type AvatarProps = {
  size: 'xsmall' | 'small' | 'medium' | 'large'
}

const AvatarStyled = styled.img`
  opacity: 0;
  width: ${({ size }: AvatarProps) =>
    size === 'xsmall'
      ? '40px'
      : size === 'small'
      ? '60px'
      : size === 'medium'
      ? '120px'
      : '250px'};
  height: ${({ size }: AvatarProps) =>
    size === 'xsmall'
      ? '40px'
      : size === 'small'
      ? '60px'
      : size === 'medium'
      ? '120px'
      : '250px'};
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid ${colors.black5};
  background: ${colors.black5};
  transition: all 0.25s ease-out;
  &:hover {
    cursor: pointer;
  }
`

type Props = {
  image: any
  size: 'xsmall' | 'small' | 'medium' | 'large'
  alt: string
  style?: Partial<CSSProperties>
  userCroppedImage?: any
  onClick?: () => void
}
export const Avatar: FunctionComponent<Props> = ({
  image,
  size,
  alt,
  style,
  userCroppedImage,
  onClick,
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const src = image ? image.small : fallback
  const fadeInStyles = {
    opacity: 1,
  }
  return (
    <React.Fragment>
      <AvatarStyled
        onClick={onClick}
        style={imageLoaded ? { ...style, ...fadeInStyles } : { ...style }}
        onLoad={() => setImageLoaded(true)}
        src={
          userCroppedImage && userCroppedImage.name
            ? URL.createObjectURL(userCroppedImage)
            : src
        }
        alt={alt}
        size={size}
      />
    </React.Fragment>
  )
}

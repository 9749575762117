import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/styles'

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`

const SwitchStyled = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${Slider} {
      background-color: ${colors.jodaLight};
    }

    &:focus + ${Slider} {
      box-shadow: 0 0 1px ${colors.jodaLight};
    }

    &:checked + ${Slider}:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
`

type Props = {
  checked: boolean
  onChange: (checked: boolean) => void
}

export const Switch: FunctionComponent<Props> = ({ checked, onChange }) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked)

  return (
    <SwitchStyled>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={({ target: { checked } }) => {
          setIsChecked(checked)
          onChange(checked)
        }}
      />
      <Slider />
    </SwitchStyled>
  )
}

import React from 'react'
import { useEffect, useState } from 'react'
import { authenticatedFetch } from '../../../../../lib/service'
import { environment } from '../../../../../environments'
import { InstitutionHierarchyListItem } from './InstitutionHierarchyListItem'
import { AnimateHeight } from '../../../../shared/animations/AnimateHeight'
import { ListItemLoader } from '../../../../shared/Loading'
import { RequestStatus } from '../../../../../types'
import { Common_ErrorOccurred } from '../../../../../translations/messages'
import { useIntl } from 'react-intl'
import { colors } from '../../../../../styles/styles'

export const InstitutionHierarchy = () => {
  const intl = useIntl()
  const [status, setStatus] = useState<RequestStatus>('idle')
  const [parents, setParents] = useState<any[]>([])

  useEffect(() => {
    setStatus('pending')

    authenticatedFetch(`${environment.API_URL}/institutions/hierarchy`)
      .then(({ results }) => {
        setParents(results)
        setStatus('idle')
      })
      .catch(() => setStatus('failure'))
  }, [])

  return (
    <AnimateHeight animateOn={true}>
      {status === 'failure' ? (
        <div style={{ color: colors.error }}>
          {intl.formatMessage(Common_ErrorOccurred)}
        </div>
      ) : status === 'pending' ? (
        <ListItemLoader />
      ) : (
        <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
          {parents.map((parent) => (
            <li key={`p-${parent.id}`}>
              <InstitutionHierarchyListItem institution={parent} />
            </li>
          ))}
        </ul>
      )}
    </AnimateHeight>
  )
}

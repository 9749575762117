import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { smallScreenBreakpoint, spacing } from '../../../styles/styles'

const PageHeaderStyled = styled.div`
  display: none;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    display: flex;
    padding: ${spacing.md} 0;
    align-items: center;
    justify-content: space-between;
  }
`

const PageTitle = styled.h1`
  margin: 0;
  font-size: 1.6rem;
`

type Props = {
  title: string
  trailingElement?: ReactNode
}

export const PageHeader: FunctionComponent<Props> = ({
  title,
  trailingElement,
}) => {
  return (
    <PageHeaderStyled>
      <PageTitle>{title}</PageTitle>

      {trailingElement && trailingElement}
    </PageHeaderStyled>
  )
}

import React from 'react'
import { MessageFeed } from '../../shared/messageFeed/MessageFeed'
import { PageContainer } from '../../shared/layout/Layout'

export const MessageFeedPage = () => {
  return (
    <PageContainer>
      <MessageFeed canCreate={true} />
    </PageContainer>
  )
}

import React, { useContext } from 'react'
import { InvitationList } from '../../shared/invitations/InvitationList'
import { NavBarContext } from '../../../contexts/NavBarContext'
import { PageContainer } from '../../shared/layout/Layout'
import styled from 'styled-components'
import { Invitations_Invitations } from '../../../translations/messages'
import { useIntl } from 'react-intl'

const Container = styled(PageContainer)`
  margin-left: auto;
  margin-right: auto;
`

export const MyInvitationsPage = () => {
  const intl = useIntl()
  const { updateNavBarTitle } = useContext(NavBarContext)

  updateNavBarTitle(intl.formatMessage(Invitations_Invitations))

  return (
    <Container>
      <h3 style={{ margin: 0 }}>
        {intl.formatMessage(Invitations_Invitations)}
      </h3>

      <InvitationList />
    </Container>
  )
}

import React from 'react'
import { List, ListPageContainer } from '../../../shared/list'
import { environment } from '../../../../environments'
import { ServiceReceiverListItem } from './ServiceReceiverListItem'
import { PageHeader } from '../../../shared/layout/PageHeader'
import {
  Common_ErrorOccurred,
  Common_ServiceReceivers,
  Users_NoUsers,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { AlertDialog } from '../../../shared/modal/AlertDialog'
import { useVideoCalls } from '../../../../contexts/VideoCallsProvider'

export const ServiceReceiverPage = () => {
  const intl = useIntl()
  const { status: videoCallStatus, clearStatus } = useVideoCalls()

  return (
    <ListPageContainer>
      <PageHeader
        title={intl.formatMessage(Common_ServiceReceivers)}
        // trailingElement={
        //   isManager ? (
        //     <Button
        //       label={intl.formatMessage(Common_New)}
        //       ariaLabel={intl.formatMessage(Common_New)}
        //       isSmall
        //       onClick={() => history.push(`service-receivers/create`)}
        //       variant="primary"
        //     />
        //   ) : null
        // }
      />

      <List
        emptyState={intl.formatMessage(Users_NoUsers)}
        ListItem={ServiceReceiverListItem}
        url={`${environment.API_URL}/users/service-receivers`}
      />

      <AlertDialog
        show={videoCallStatus === 'failure'}
        title={Common_ErrorOccurred}
        onClose={clearStatus}
        onConfirm={clearStatus}
      />
    </ListPageContainer>
  )
}

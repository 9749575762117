import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { colors, spacing } from '../../../styles/styles'
import { Player } from '@lottiefiles/react-lottie-player'
import spinner from '../../../../src/lottie/spinner.json'
import { useIntl } from 'react-intl'
import {
  Video_Status_Ingest,
  Video_Status_Unknown,
  Video_Status_Error,
} from '../../../translations/messages'
import VideoThumbnail from 'react-video-thumbnail'

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`
type StyledImageProps = {
  minHeight?: number
}

const VideoPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`
const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const LoadingVideoWrapper = styled.div`
  position: relative;
  padding-left: ${spacing.md};
  padding-right: ${spacing.md};
  width: 100%;
  overflow: hidden;
  min-height: ${(props: StyledImageProps) =>
    props.minHeight ? `${props.minHeight}px` : '100%'};

  > img {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 0.25s ease-out;

    &.imageLoaded {
      opacity: 1;
    }
  }
`
const ErrorText = styled.h6`
  color: ${colors.black};
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 30%;
`

type Props = {
  video: any
}

export const Video: FunctionComponent<Props> = ({ video }) => {
  const intl = useIntl()
  const [thumbnail, setThumbnail] = useState(null)
  const handleCreateThumbnail = (thumbnail) => {
    setThumbnail(thumbnail)
  }

  return (
    <React.Fragment>
      {video && video.mp4_urls && video.mp4_urls.length > 0 ? (
        <VideoWrapper>
          <div style={{ display: 'none' }}>
            <VideoThumbnail
              videoUrl={video.mp4_urls[0]}
              thumbnailHandler={(thumbnail: any) =>
                handleCreateThumbnail(thumbnail)
              }
            />
          </div>
          <VideoPlayer
            controls={true}
            url={video.mp4_urls[0]}
            width="100%"
            height="100%"
            light={thumbnail ?? false}
          />
        </VideoWrapper>
      ) : (video && video.status === 'Ingest') ||
        (video && video.status === 'Unknown') ? (
        <LoadingVideoWrapper minHeight={300}>
          <Loading>
            <Player
              autoplay
              loop
              src={spinner}
              style={{ height: '100px', width: '100px' }}
            />
            {video.status === 'Ingest' && (
              <h6 style={{ color: colors.black, fontSize: '14px' }}>
                {intl.formatMessage(Video_Status_Ingest)}
              </h6>
            )}
            {video.status === 'Unknown' && (
              <h6 style={{ color: colors.black, fontSize: '14px' }}>
                {intl.formatMessage(Video_Status_Unknown)}
              </h6>
            )}
          </Loading>
        </LoadingVideoWrapper>
      ) : (
        <VideoWrapper>
          <ErrorText>{intl.formatMessage(Video_Status_Error)}</ErrorText>
        </VideoWrapper>
      )}
    </React.Fragment>
  )
}

import React, { useContext, useEffect } from 'react'
import { PageContainer } from '../../shared/layout/Layout'
import { PageHeader } from '../../shared/layout/PageHeader'
import { useIntl } from 'react-intl'
import { Common_MyNetwork } from '../../../translations/messages'
import { NavBarContext } from '../../../contexts/NavBarContext'
import { AuthContext } from '../../../contexts/AuthContext'
import { breakpoints } from '../../../styles/styles'
import { RelationList } from '../../shared/relations/RelationList'

export const MyNetworkPage = () => {
  const intl = useIntl()
  const { authUser } = useContext(AuthContext)
  const { updateNavBarTitle } = useContext(NavBarContext)

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Common_MyNetwork))
  }, [intl, updateNavBarTitle])

  return (
    <PageContainer style={{ maxWidth: breakpoints.SMALL }}>
      <PageHeader title={intl.formatMessage(Common_MyNetwork)} />

      <RelationList userId={authUser?.id} />
    </PageContainer>
  )
}

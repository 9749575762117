import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../../styles/styles'
import { Avatar } from '../Avatar'
import { useHistory } from 'react-router-dom'

const ListItem = styled.li`
  border-radius: ${borderRadius};
  background: ${colors.white};
  display: flex;
  align-items: center;
  padding-top: ${spacing.md};
  padding-bottom: ${spacing.md};
  border: none;
  width: 100%;
  font-size: inherit;
  flex-wrap: wrap;
  gap: ${spacing.sm};

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.black5};
  }
`

const ListBody = styled.div`
  flex: 1;
`

type Props = {
  data: any
  onClick?: (item: any) => void
  trailingElement?: ReactNode
}

export const UserListItem: FunctionComponent<Props> = ({
  data,
  onClick,
  trailingElement,
}) => {
  const history = useHistory()

  return (
    <ListItem
      onClick={() =>
        onClick ? onClick(data) : history.push(`/profile/${data.id}`)
      }
    >
      <Avatar
        size="small"
        image={data.image}
        alt={`Bilde av ${data.first_name}`}
      />

      <ListBody>
        <div style={{ textAlign: 'left' }}>
          {data.name ? data.name : `${data.first_name} ${data.last_name}`}
        </div>
        <small>{data.email}</small>
      </ListBody>

      {trailingElement && trailingElement}
    </ListItem>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { Calendar } from '../../../shared/calendar/Calendar'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { MessageLoader } from '../../../shared/Loading'
import { Permissions } from '../../../shared/permissions/Permissions'
import {
  breakpoints,
  smallScreenBreakpoint,
  spacing,
} from '../../../../styles/styles'
import styled from 'styled-components'
import { PostFeed } from '../../../shared/post/PostFeed'
import { Moderator } from './Moderator'
import { SubPageTabs } from '../../../shared/SubPageTabs'
import { MessageFeed } from '../../../shared/messageFeed/MessageFeed'
import { Relations } from '../../../shared/relations/Relations'
import { PostProvider } from '../../../../contexts/PostProvider'
import { ProfileHeader } from '../../../shared/ProfileHeader'
import { useUser } from '../../../../contexts/UserProvider'
import { PageContainer } from '../../../shared/layout/Layout'
import { MessageProvider } from '../../../../contexts/MessageProvider'
import {
  faBookOpen,
  faClock,
  faEnvelope,
  faInfoCircle,
  faProjectDiagram,
} from '@fortawesome/free-solid-svg-icons'
import { hasPermission } from '../../../../lib/permissionHelpers'
import { NavButton } from '../../../shared/NavButton'
import { JodabookSetup } from './Jodabook'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import {
  Common_Book,
  Common_Calendar,
  Common_Edit,
  Common_Info,
  Common_Messages,
  Common_Relations,
} from '../../../../translations/messages'
import { usePermissions } from '../../../../contexts/PermissionProvider'
import { CalendarProvider } from '../../../../hooks/useCalendar'
import { Button } from '../../../shared/Buttons'
import { useIntl } from 'react-intl'

const Container = styled.div`
  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    display: flex;
    justify-content: space-between;
  }
`

const LargeScreenEditButton = styled.div`
  display: none;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    display: initial;
    position: absolute;
    top: ${spacing.md};
    right: ${spacing.md};
  }
`

const Column = styled.div`
  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    flex: 1;

    &:not(:first-of-type) {
      margin-left: ${spacing.md};
    }

    &:not(:last-of-type) {
      margin-right: ${spacing.md};
    }
  }
`

const ProfileDescription = styled.div`
  margin: 0 auto;
  max-width: 75ch;
  text-align: center;
`

const tabs = [
  { label: Common_Info, icon: faInfoCircle },
  { label: Common_Calendar, icon: faClock },
  { label: Common_Book, icon: faBookOpen },
  { label: Common_Messages, icon: faEnvelope },
  { label: Common_Relations, icon: faProjectDiagram },
]

export const ServiceReceiverDetailPage = () => {
  const { id } = useParams<{ id: string }>()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const location = useLocation()
  const intl = useIntl()
  const history = useHistory()

  const { user: serviceReceiver, profileStatus, profile, updateUser } = useUser(
    id
  )
  const { relationsByTargetId } = usePermissions(id)
  const [tab, setTab] = useState<number>(2)

  const relations = relationsByTargetId[id]
  const canCreateMessage = hasPermission(relations, 'Message', 'CREATE')
  const canCreatePost = hasPermission(relations, 'Post', 'CREATE')
  const canEdit = hasPermission(relations, 'UserProfile', 'UPDATE')

  useEffect(() => {
    if (serviceReceiver) {
      updateNavBarTitle(serviceReceiver.first_name)
    }
  }, [updateNavBarTitle, serviceReceiver])

  if (serviceReceiver) {
    return (
      <React.Fragment>
        {canEdit && (
          <NavButton
            path={`/profile/${serviceReceiver.id}/edit`}
            label={Common_Edit}
            state={{
              from: location.pathname,
            }}
          />
        )}
        <PageContainer>
          <ProfileHeader user={serviceReceiver}>
            {profileStatus === 'pending' ? (
              <MessageLoader />
            ) : (
              <React.Fragment>
                {canEdit && (
                  <LargeScreenEditButton>
                    <Button
                      ariaLabel={intl.formatMessage(Common_Edit)}
                      label={intl.formatMessage(Common_Edit)}
                      variant="primary"
                      isSmall={true}
                      onClick={() => history.push(`/profile/${id}/edit`)}
                    />
                  </LargeScreenEditButton>
                )}

                <ProfileDescription>{profile?.description}</ProfileDescription>
              </React.Fragment>
            )}
          </ProfileHeader>

          <SubPageTabs
            tabs={tabs}
            activeIndex={tab}
            onTabChanged={(newIndex) => setTab(newIndex)}
          />

          {tab === 0 && (
            <Container>
              <Column>
                <Permissions
                  name={serviceReceiver.first_name}
                  relations={relations}
                />
              </Column>
              <Column>
                <Moderator
                  serviceReceiver={serviceReceiver}
                  updateModerator={(moderator) => updateUser({ moderator })}
                />

                <div style={{ marginTop: spacing.lg }}>
                  <JodabookSetup
                    serviceReceiver={serviceReceiver}
                    updateJodabook={updateUser}
                  />
                </div>
              </Column>
            </Container>
          )}

          {tab === 1 && (
            <CalendarProvider>
              <Calendar ownerId={id} />
            </CalendarProvider>
          )}

          {tab === 2 && (
            <PostProvider>
              <PostFeed ownerId={id} canCreate={canCreatePost} />
            </PostProvider>
          )}

          {tab === 3 && (
            <MessageProvider>
              <MessageFeed ownerId={id} canCreate={canCreateMessage} />
            </MessageProvider>
          )}

          {tab === 4 && <Relations ownerId={id} />}
        </PageContainer>
      </React.Fragment>
    )
  }

  return (
    <PageContainer>
      <MessageLoader />
    </PageContainer>
  )
}

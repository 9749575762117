import React, { useContext, useEffect, useState } from 'react'
import { PageContainer } from '../../shared/layout/Layout'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { NavBarContext } from '../../../contexts/NavBarContext'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { CommentList } from '../../shared/comments/CommentList'
import { MessageCard } from '../../shared/messageFeed/MessageCard'
import { useMessages } from '../../../contexts/MessageProvider'
import { MessageLoader } from '../../shared/Loading'
import { AuthContext } from '../../../contexts/AuthContext'
import { ConfirmDialog } from '../../shared/modal/ConfirmDialog'
import {
  Messages_MarkAsRead,
  Messages_Message,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'

const Container = styled(PageContainer)`
  margin-left: auto;
  margin-right: auto;
  max-width: 37.5rem;
`

const hasReadMessage = (message: any, authUser: any) => {
  return message
    ? message.read.findIndex((user: any) => user.id === authUser!.id) !== -1
    : false
}

export const MessageDetailPage = () => {
  const intl = useIntl()
  const { id } = useParams<{ id: string }>()
  const { authUser } = useContext(AuthContext)
  const { updateNavBarTitle } = useContext(NavBarContext)
  const { allMessages, updateMessageState } = useMessages()
  const message = (allMessages && allMessages.find((m) => m.id === id)) || null
  const [showConfirmRead, setShowConfirmRead] = useState<boolean>(false)

  const isCreator = message?.created_by.id === authUser?.id

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Messages_Message))
  }, [intl, updateNavBarTitle])

  useEffect(() => {
    let timeout: number

    if (message && !hasReadMessage(message, authUser) && !isCreator) {
      timeout = setTimeout(() => {
        setShowConfirmRead(true)
      }, 1000)
    }

    return () => clearTimeout(timeout)
  }, [id, message, authUser, isCreator])

  const readMessage = () => {
    setShowConfirmRead(false)

    authenticatedFetch(`${environment.API_URL}/messages/${id}/read`, {
      method: 'POST',
    }).then((response) => {
      updateMessageState(response)
    })
  }

  useEffect(() => {
    if (message === null) {
      authenticatedFetch(`${environment.API_URL}/messages/${id}`)
        .then((response) => {
          updateMessageState(response)
        })
        .catch(() => {})
    }
  }, [id, message, isCreator, updateMessageState])

  return (
    <Container>
      {message ? (
        <React.Fragment>
          <MessageCard data={message} />
          <CommentList parentId={message.id} />
        </React.Fragment>
      ) : (
        <MessageLoader />
      )}

      <ConfirmDialog
        show={showConfirmRead}
        title={Messages_MarkAsRead}
        onClose={() => setShowConfirmRead(false)}
        onConfirm={readMessage}
        onCancel={() => setShowConfirmRead(false)}
      />
    </Container>
  )
}

import React, { useContext, useEffect } from 'react'
import { PageContainer } from '../../shared/layout/Layout'
import { AuthContext } from '../../../contexts/AuthContext'
import {
  Language_English,
  Language_Norwegian,
  Settings_Account,
  Settings_AuthExpire,
  Settings_Hour,
  Settings_Language,
  Settings_Notifications,
  Settings_NotifyCancelEvents,
  Settings_NotifyNewComments,
  Settings_NotifyNewEvents,
  Settings_NotifyNewMessages,
  Settings_NotifyNewPosts,
  Settings_NotifyNewReactions,
  Settings_Settings,
  Settings_Week,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'
import { NavBarContext } from '../../../contexts/NavBarContext'
import { MessageLoader } from '../../shared/Loading'
import { Card } from '../../shared/card/Card'
import { Select } from '../../shared/form/Select'
import styled from 'styled-components'
import { breakpoints, spacing } from '../../../styles/styles'
import { FadeIn } from '../../shared/animations/FadeIn'
import { PageHeader } from '../../shared/layout/PageHeader'
import { Switch } from '../../shared/form/Switch'
import { LanguageContext } from '../../../contexts/LanguageContext'

const Container = styled(PageContainer)`
  margin-right: auto;
  margin-left: auto;
  max-width: ${breakpoints.SMALL};
`

const SettingsCard = styled(Card)`
  margin-bottom: ${spacing.md};
  padding: ${spacing.md};
  display: flex;
  align-items: center;

  label {
    flex: 2;
  }

  select {
    flex: 1;
  }
`

const SettingGroupTitle = styled.h3`
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.sm};
`

export const SettingsPage = () => {
  const intl = useIntl()
  const { selectLanguage } = useContext(LanguageContext)
  const { updateNavBarTitle } = useContext(NavBarContext)
  const { reqStatus, settings, updateSettings } = useContext(AuthContext)

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Settings_Settings))
  }, [updateNavBarTitle, intl])

  const authExpireOptions = [
    {
      key: intl.formatMessage(Settings_Hour, { hours: 2 }),
      value: 7200,
    },
    {
      key: intl.formatMessage(Settings_Hour, { hours: 8 }),
      value: 28800,
    },
    {
      key: intl.formatMessage(Settings_Hour, { hours: 24 }),
      value: 86400,
    },
    {
      key: intl.formatMessage(Settings_Week, { weeks: 1 }),
      value: 604800,
    },
  ]

  const languageOptions = [
    {
      key: intl.formatMessage(Language_Norwegian),
      value: 'nb',
    },
    {
      key: intl.formatMessage(Language_English),
      value: 'en',
    },
  ]

  return (
    <FadeIn showOn={true}>
      <Container>
        <PageHeader title={intl.formatMessage(Settings_Settings)} />

        {settings ? (
          <React.Fragment>
            <SettingGroupTitle>
              {intl.formatMessage(Settings_Account)}
            </SettingGroupTitle>
            <SettingsCard>
              <label htmlFor="authExpire">
                {intl.formatMessage(Settings_AuthExpire)}
              </label>

              <Select
                id="authExpire"
                disabled={reqStatus === 'pending'}
                ariaLabel={intl.formatMessage(Settings_AuthExpire)}
                value={settings.auth_expire}
                options={authExpireOptions}
                onChange={(value) => updateSettings({ auth_expire: value })}
              />
            </SettingsCard>

            <SettingsCard>
              <label htmlFor="language">
                {intl.formatMessage(Settings_Language)}
              </label>

              <Select
                id="language"
                disabled={reqStatus === 'pending'}
                ariaLabel={intl.formatMessage(Settings_Language)}
                value={settings.language}
                options={languageOptions}
                onChange={(value) => {
                  selectLanguage(value)
                  updateSettings({ language: value })
                }}
              />
            </SettingsCard>

            <SettingGroupTitle>
              {intl.formatMessage(Settings_Notifications)}
            </SettingGroupTitle>
            <SettingsCard>
              <label htmlFor="notifyCancelEvents">
                {intl.formatMessage(Settings_NotifyCancelEvents)}
              </label>

              <div>
                <Switch
                  checked={settings.notify_cancel_events}
                  onChange={(value) =>
                    updateSettings({ notify_cancel_events: value })
                  }
                />
              </div>
            </SettingsCard>

            <SettingsCard>
              <label htmlFor="notifyNewEvents">
                {intl.formatMessage(Settings_NotifyNewEvents)}
              </label>

              <div>
                <Switch
                  checked={settings.notify_new_events}
                  onChange={(value) =>
                    updateSettings({ notify_new_events: value })
                  }
                />
              </div>
            </SettingsCard>

            <SettingsCard>
              <label htmlFor="notifyNewComments">
                {intl.formatMessage(Settings_NotifyNewComments)}
              </label>

              <div>
                <Switch
                  checked={settings.notify_new_comments}
                  onChange={(value) =>
                    updateSettings({ notify_new_comments: value })
                  }
                />
              </div>
            </SettingsCard>

            <SettingsCard>
              <label htmlFor="notifyNewMessages">
                {intl.formatMessage(Settings_NotifyNewMessages)}
              </label>

              <div>
                <Switch
                  checked={settings.notify_new_messages}
                  onChange={(value) =>
                    updateSettings({ notify_new_messages: value })
                  }
                />
              </div>
            </SettingsCard>

            <SettingsCard>
              <label htmlFor="notifyNewPosts">
                {intl.formatMessage(Settings_NotifyNewPosts)}
              </label>

              <div>
                <Switch
                  checked={settings.notify_new_posts}
                  onChange={(value) =>
                    updateSettings({ notify_new_posts: value })
                  }
                />
              </div>
            </SettingsCard>

            <SettingsCard>
              <label htmlFor="notifyNewReactions">
                {intl.formatMessage(Settings_NotifyNewReactions)}
              </label>

              <div>
                <Switch
                  checked={settings.notify_reactions}
                  onChange={(value) =>
                    updateSettings({ notify_reactions: value })
                  }
                />
              </div>
            </SettingsCard>
          </React.Fragment>
        ) : (
          <MessageLoader />
        )}
      </Container>
    </FadeIn>
  )
}

import React from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../styles/styles'
import {
  Login_Login,
  Common_Register,
  Common_ContactUs,
} from '../../translations/messages'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'

type MenuProps = {
  open: any
}
const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: #effffa;
  transform: ${(props: MenuProps) =>
    props.open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  a {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: center;

    &:link,
    &:visited {
      color: ${colors.joda};
      text-decoration: none;
      font-weight: bold;
      border-bottom: 1px solid white;
      transition: all 0.25s ease-out;
    }

    &.active,
    &:hover {
      border-color: ${colors.jodaDark};
    }
    &:hover {
      color: #343078;
    }
  }
`
const StyledBurger = styled.button`
  position: absolute;
  top: 17%;
  left: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${(props: MenuProps) =>
      props.open ? '#0D0C1D' : colors.black};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${(props: MenuProps) =>
        props.open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${(props: MenuProps) => (props.open ? '0' : '1')};
      transform: ${(props: MenuProps) =>
        props.open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${(props: MenuProps) =>
        props.open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`
const NavBarItem = styled(NavLink)`
  position: relative;
  text-transform: uppercase;
  margin-left: ${spacing.lg};

  &:link,
  &:visited {
    color: ${colors.joda};
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid white;
    transition: all 0.25s ease-out;
  }

  &.active,
  &:hover {
    border-color: ${colors.jodaDark};
  }
`
export const Menu = ({ open, setOpen }) => {
  const intl = useIntl()

  return (
    <React.Fragment>
      <StyledMenu open={open}>
        <NavBarItem
          to="/login"
          activeClassName="active"
          onClick={() => setOpen(!open)}
        >
          {intl.formatMessage(Login_Login)}
        </NavBarItem>

        <NavBarItem
          to="/register"
          activeClassName="active"
          onClick={() => setOpen(!open)}
        >
          {intl.formatMessage(Common_Register)}
        </NavBarItem>

        <NavBarItem
          to="/contact"
          activeClassName="active"
          onClick={() => setOpen(!open)}
        >
          {intl.formatMessage(Common_ContactUs)}
        </NavBarItem>
      </StyledMenu>
    </React.Fragment>
  )
}

export const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

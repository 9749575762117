import React, { FunctionComponent } from 'react'
import { Calendar } from '../../shared/calendar/Calendar'
import { PageContainer } from '../../shared/layout/Layout'
import { CalendarProvider } from '../../../hooks/useCalendar'

type Props = {
  ownerId: string
}

export const CalendarPage: FunctionComponent<Props> = ({ ownerId }) => {
  return (
    <PageContainer>
      <CalendarProvider>
        <Calendar ownerId={ownerId} />
      </CalendarProvider>
    </PageContainer>
  )
}

import { Card } from '../../shared/card/Card'
import React, { useEffect, useState, useContext } from 'react'
import { H1 } from '../../shared/Typography'
import { spacing } from '../../../styles/styles'
import { environment } from '../../../environments'
import { PageContainer } from '../../shared/layout/Layout'
import styled from 'styled-components'
import illustration from '../../../images/illustrations/young_and_old_2.svg'
import { useHistory, useParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { unauthenticatedFetch } from '../../../lib/service'
import { ErrorMessage } from '../../shared/Messages'
import { useIntl } from 'react-intl'
import {
  Common_PleaseWait,
  Login_Login,
  Login_TokenExpired,
} from '../../../translations/messages'
import { WizardContainer } from './common/WizardContainer'
import { AuthOptions } from './AuthOptions'
import { AuthContext } from '../../../contexts/AuthContext'

const LoginContainer = styled(PageContainer)`
  height: 100%;
`

export const LoginPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const { token } = useParams<{ token?: string }>()
  const [tempToken, setTempToken] = useState<string | undefined>(token)
  const [error, setError] = useState<string>('')
  const { handleStoreToken } = useContext(AuthContext)

  useEffect(() => {
    if (tempToken) {
      const decoded: any = jwt_decode(tempToken)

      // Trade temp token for an auth token
      if (decoded.typ === 'temp' && decoded.id !== null) {
        unauthenticatedFetch(`${environment.API_URL}/auth`, {
          method: 'POST',
          headers: { Authorization: `Bearer ${tempToken}` },
        })
          .then(({ token }) => {
            setTempToken(undefined)
            localStorage.setItem('jodacare-token', token)
            handleStoreToken(token)
            // @ts-ignore
            const isNative = window?.ReactNativeWebView
            if (isNative) {
              // @ts-ignore
              window?.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'AUTH_SUCCESS',
                  payload: token,
                })
              )
            }
          })
          .catch((error) => {
            if (error.message === 'Token expired') {
              setTempToken(undefined)
              setError(intl.formatMessage(Login_TokenExpired))
            }
          })
      } else {
        history.push(`/verify/${tempToken}`)
      }
    }
  }, [tempToken, history, intl, handleStoreToken])

  return (
    <LoginContainer>
      <Card>
        <WizardContainer illustration={illustration}>
          <div style={{ textAlign: 'center' }}>
            <H1>{intl.formatMessage(Login_Login)}</H1>

            {error && (
              <div style={{ marginBottom: spacing.md }}>
                <ErrorMessage>{error}</ErrorMessage>
              </div>
            )}

            {tempToken ? (
              <div style={{ marginTop: spacing.lg }}>
                {intl.formatMessage(Common_PleaseWait)}
              </div>
            ) : (
              <AuthOptions />
            )}
          </div>
        </WizardContainer>
      </Card>
    </LoginContainer>
  )
}

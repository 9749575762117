import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { pathnameMap } from '../routes/routes'
import { useIntl } from 'react-intl'

type NavCtx = {
  title: string
  updateNavBarTitle: (title: string) => void
}

export const NavBarContext = createContext<NavCtx>({
  title: '',
  updateNavBarTitle: (title: string) => {},
})

export const NavBarProvider: FunctionComponent = ({ children }) => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const [title, setTitle] = useState<string>('')

  const pathTitle = pathnameMap[pathname]
    ? intl.formatMessage(pathnameMap[pathname])
    : ''

  useEffect(() => {
    updateNavBarTitle(pathTitle)
  }, [pathTitle])

  const updateNavBarTitle = (title: string) => {
    setTitle(title)
  }

  return (
    <NavBarContext.Provider
      value={{
        title,
        updateNavBarTitle,
      }}
    >
      {children}
    </NavBarContext.Provider>
  )
}

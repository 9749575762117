import React, { FunctionComponent, useEffect, useState } from 'react'
import { Card } from '../../../shared/card/Card'
import { spacing } from '../../../../styles/styles'
import { Institution } from '../../../../types'
import { Permissions } from '../../../shared/permissions/Permissions'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { FlexColumn, FlexContainer } from '../../../shared/layout/Flex'
import styled from 'styled-components'
import {
  Common_DataController,
  Common_NoDataController,
  Institutions_ChildOf,
  Institutions_Departments,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'

const H3 = styled.h3`
  margin: 0 0 ${spacing.sm} 0;
`

const Section = styled.div`
  margin-bottom: ${spacing.lg};
`

type Props = {
  institution: Institution
}

export const InstitutionInfo: FunctionComponent<Props> = ({ institution }) => {
  const intl = useIntl()
  const [relations, setRelations] = useState<any[]>([])

  useEffect(() => {
    authenticatedFetch(
      `${environment.API_URL}/permissions/${institution.id}`
    ).then((relations) => {
      setRelations(relations)
    })
  }, [institution])

  return (
    <FlexContainer>
      <FlexColumn>
        <Permissions name={institution.name} relations={relations} />
      </FlexColumn>

      <FlexColumn>
        <Section>
          <H3>{intl.formatMessage(Common_DataController)}</H3>
          <Card style={{ padding: spacing.md }}>
            {institution.data_controller ? (
              <span>
                {institution.data_controller?.first_name}{' '}
                {institution.data_controller?.last_name}
              </span>
            ) : (
              intl.formatMessage(Common_NoDataController)
            )}
          </Card>
        </Section>

        {institution.parents && (
          <Section>
            <H3>{intl.formatMessage(Institutions_ChildOf)}</H3>
            {institution.parents.map((parent: any, i: number) => (
              <Card key={`child-${i}`} style={{ padding: spacing.md }}>
                {parent.name}
              </Card>
            ))}
          </Section>
        )}

        <H3>{intl.formatMessage(Institutions_Departments)}</H3>
        {institution.children.map((child: any, i: number) => (
          <Card key={`child-${i}`} style={{ padding: spacing.md }}>
            {child.name}
          </Card>
        ))}
      </FlexColumn>
    </FlexContainer>
  )
}

import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { authenticatedFetch } from '../lib/service'
import { environment } from '../environments'
import { RequestStatus } from '../types'

type PermissionCtxt = {
  status: RequestStatus
  setStatus: Dispatch<SetStateAction<RequestStatus>>
  hasProvider: boolean
  relationsByTargetId: { [key: string]: any[] }
  setRelationsByTargetId: Dispatch<SetStateAction<{ [key: string]: any[] }>>
}

export const PermissionContext = React.createContext<PermissionCtxt>({
  status: 'idle',
  setStatus: () => {},
  hasProvider: false,
  relationsByTargetId: {},
  setRelationsByTargetId: (prev) => prev,
})

export const PermissionProvider: FunctionComponent = ({ children }) => {
  const hasProvider = true
  const [status, setStatus] = useState<RequestStatus>('idle')
  const [relationsByTargetId, setRelationsByTargetId] = useState<{
    [key: string]: any[]
  }>({})

  return (
    <PermissionContext.Provider
      value={{
        status,
        setStatus,
        hasProvider,
        relationsByTargetId,
        setRelationsByTargetId,
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export const usePermissions = (id: string) => {
  const context = useContext(PermissionContext)

  if (!context.hasProvider) {
    throw new Error('usePermissions must be used within a PermissionProvider')
  }

  const {
    relationsByTargetId,
    setRelationsByTargetId,
    status,
    setStatus,
  } = context

  useEffect(() => {
    setStatus('pending')

    authenticatedFetch(`${environment.API_URL}/permissions/${id}`)
      .then((response) => {
        setStatus('idle')

        setRelationsByTargetId((prevState) => ({
          ...prevState,
          [id]: response,
        }))
      })
      .catch(() => setStatus('failure'))
  }, [id, setRelationsByTargetId, setStatus])

  return {
    status,
    relationsByTargetId,
  }
}

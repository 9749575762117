import React, { useContext, useEffect } from 'react'
import { colors } from './styles/styles'
import styled from 'styled-components'
import { UnAuthenticatedApp } from './UnAuthenticatedApp'
import { AuthContext } from './contexts/AuthContext'
import { AuthenticatedApp } from './AuthenticatedApp'
import { useClearCache } from 'react-clear-cache'
import { useHistory } from 'react-router-dom'

type Props = {
  isAuthenticated: boolean
}

const Background = styled.div<Props>`
  flex: 1;
  background-color: ${({ isAuthenticated }) =>
    isAuthenticated ? colors.hope : colors.trustLight};
`

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache()
  const { authUser, authToken } = useContext(AuthContext)

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage()

      // @ts-ignore
      if (window?.ReactNativeWebView) {
        // @ts-ignore
        window?.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'RELOAD_WEBVIEW',
            payload: {},
          })
        )
      } else {
        window.location.reload()
      }
    }
  }, [isLatestVersion, emptyCacheStorage])

  const history = useHistory()
  useEffect(() => {
    // @ts-ignore
    const isNative = window?.ReactNativeWebView
    if (isNative) {
      // @ts-ignore
      const notificationType = window._notificationType
      // @ts-ignore
      const notificationId = window._notificationId

      if (notificationType === 'POST' || notificationType === 'VIDEO') {
        history.push(`/posts/${notificationId}`)
      } else if (
        notificationType === 'EVENT' ||
        notificationType === 'OCCURRENCE'
      ) {
        history.push(`/calendar/${notificationId}/edit`)
      } else if (
        notificationType === 'MESSAGE' ||
        notificationType === 'DIALOG_MESSAGE'
      ) {
        history.push(`/messages/${notificationId}`)
      } else if (notificationType === 'MODERATION') {
        history.push('/moderator')
      } else if (notificationType === 'INVITATION') {
        history.push('/invitations')
      }

      // @ts-ignore
      if (window._forAuth === 'YES' && window._authToken) {
        // @ts-ignore
        localStorage.setItem('jodacare-token', window._authToken)
      }
    }
  }, [history])
  const isAuthenticated = Boolean(authToken)
  const hasAuthUser = Boolean(authUser)

  if (isAuthenticated && !hasAuthUser) {
    return (
      <Background isAuthenticated={isAuthenticated}>Et øyeblikk...</Background>
    )
  }

  return (
    <Background isAuthenticated={isAuthenticated}>
      {hasAuthUser ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
    </Background>
  )
}

export default App

import React, { FunctionComponent } from 'react'
import { PostProvider } from './PostProvider'
import { NavBarProvider } from './NavBarContext'
import { MessageProvider } from './MessageProvider'
import { PermissionProvider } from './PermissionProvider'
import { InvitationsProvider } from './InvitationContext'
import { ModeratorsProvider } from './ModeratorContext'

export const DataProvider: FunctionComponent = ({ children }) => {
  return (
    <NavBarProvider>
      <PermissionProvider>
        <InvitationsProvider>
          <ModeratorsProvider>
            <PostProvider>
              <MessageProvider>{children}</MessageProvider>
            </PostProvider>
          </ModeratorsProvider>
        </InvitationsProvider>
      </PermissionProvider>
    </NavBarProvider>
  )
}

import React, { useState, useCallback, FunctionComponent } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from './canvasUtils'
import { Dialog } from '../../shared/modal/Dialog'
import { Button } from '../../shared/Buttons'
import styled from 'styled-components'
import { Common_Save, Common_Cancel } from '../../../translations/messages'
import { useIntl } from 'react-intl'

const CropperContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
`
const Buttons = styled.div`
  position: absolute;
  bottom: 0;
  left: 1px;
  right: 1px;
  height: 80px;
  display: flex;
  justify-content: center;
  margin-right: 15px;
`
type Props = {
  userId?: string
  image: any
  closeModal: () => void
  croppedImageAction: (image: any) => void
  handleSaveImage: (image: any, fileName: string) => void
}

export const EditProfileImage: FunctionComponent<Props> = ({
  image,
  closeModal,
  croppedImageAction,
  handleSaveImage,
}) => {
  const imageSrc = URL.createObjectURL(image)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const intl = useIntl()

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        image.name
      )
      handleSave(croppedImage, image.name)
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc, croppedAreaPixels, rotation, image.name])

  const handleSave = async (croppedImage: any, fileName: string) => {
    croppedImageAction(croppedImage)
    await handleSaveImage(croppedImage, fileName)
  }

  return (
    <Dialog onClose={closeModal}>
      <CropperContainer>
        <Cropper
          image={URL.createObjectURL(image)}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </CropperContainer>
      <Buttons>
        <div style={{ marginRight: ' 10px' }}>
          <Button
            label={intl.formatMessage(Common_Cancel)}
            variant="danger"
            ariaLabel={intl.formatMessage(Common_Cancel)}
            onClick={() => closeModal()}
            isSmall={true}
          />
        </div>
        <Button
          label={intl.formatMessage(Common_Save)}
          variant="primary"
          ariaLabel={intl.formatMessage(Common_Save)}
          onClick={() => showCroppedImage()}
          isSmall={true}
        />
      </Buttons>
    </Dialog>
  )
}

import React, { FunctionComponent } from 'react'
import { Dialog } from '../modal/Dialog'
import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'
type Props = {
  onClose: () => void
  media: any[]
}

export const MediaDialog: FunctionComponent<Props> = ({ onClose, media }) => {
  return (
    <Dialog onClose={onClose}>
      <Lightbox
        images={
          media && media.length > 1
            ? media.map((x: any) => !x.status && { url: x.original, title: '' })
            : null
        }
        image={media && media.length === 1 ? media[0].original : null}
        onClose={onClose}
      />
    </Dialog>
  )
}

import React, { FunctionComponent, useState } from 'react'
import { Card, Li, Ul } from '../card/Card'
import { entityMap, permissionsMap, relationsMap } from '../../../relationMap'
import styled from 'styled-components'
import { spacing } from '../../../styles/styles'
import { Button } from '../Buttons'
import { AnimateHeight } from '../animations/AnimateHeight'
import { Relation } from '../../../types'
import { useIntl } from 'react-intl'
import {
  Common_Hide,
  Common_NumPermissions,
  Common_NumRelations,
  Common_PermissionsTo,
  Common_RelationsTo,
  Common_Show,
} from '../../../translations/messages'

const H3 = styled.h3`
  margin: 0 0 ${spacing.sm} 0;
`

const Padding = styled.div`
  padding: ${spacing.md};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

type Props = {
  name: string
  relations: Relation[]
}

const combinePermissionsFromRelations = (relations: any[]) => {
  let permissions: { [key: string]: string[] } = {}

  if (relations && relations.length > 0) {
    for (let relation of relations) {
      for (let key in relation.permissions) {
        if (relation.permissions.hasOwnProperty(key)) {
          if (!permissions[key]) {
            permissions[key] = []
          }

          const perms = [
            // @ts-ignore
            ...new Set([...relation.permissions[key], ...permissions[key]]),
          ]

          permissions = {
            ...permissions,
            [key]: perms,
          }
        }
      }
    }
  }

  return permissions
}

export const Permissions: FunctionComponent<Props> = ({ relations, name }) => {
  const intl = useIntl()
  const [permissions] = useState<{ [key: string]: string[] }>(
    combinePermissionsFromRelations(relations)
  )
  const [showRelations, setShowRelations] = useState<boolean>(false)
  const [showPermissions, setShowPermissions] = useState<boolean>(false)

  return (
    <React.Fragment>
      <H3 id="relationsTitle">
        {intl.formatMessage(Common_RelationsTo, { name })}
      </H3>
      <Card style={{ marginBottom: spacing.lg }}>
        <Padding>
          {intl.formatMessage(Common_NumRelations, {
            numRelations: relations?.length,
          })}
          <Button
            label={intl.formatMessage(
              showRelations ? Common_Hide : Common_Show
            )}
            ariaLabel={intl.formatMessage(
              showRelations ? Common_Hide : Common_Show
            )}
            variant="secondary"
            isSmall={true}
            onClick={() =>
              showRelations ? setShowRelations(false) : setShowRelations(true)
            }
          />
        </Padding>

        {showRelations && (
          <AnimateHeight animateOn={true}>
            <Ul aria-labelledby="relationsTitle">
              {relations.map((relation: any, i) => (
                <Li key={`r-${i}`}>
                  {relationsMap[relation.relation]
                    ? intl.formatMessage(relationsMap[relation.relation])
                    : relation.relation}
                  <div>
                    <small>{relation?.hub?.name}</small>
                  </div>
                </Li>
              ))}
            </Ul>
          </AnimateHeight>
        )}
      </Card>

      <H3 id="permissionsTitle">
        {intl.formatMessage(Common_PermissionsTo, { name })}
      </H3>
      <Card>
        <Padding>
          {intl.formatMessage(Common_NumPermissions, {
            numPermissions: Object.keys(permissions).length,
          })}
          <Button
            label={intl.formatMessage(
              showPermissions ? Common_Hide : Common_Show
            )}
            ariaLabel={intl.formatMessage(
              showPermissions ? Common_Hide : Common_Show
            )}
            variant="secondary"
            isSmall={true}
            onClick={() =>
              showPermissions
                ? setShowPermissions(false)
                : setShowPermissions(true)
            }
          />
        </Padding>
        {showPermissions && (
          <Ul aria-labelledby="permissionsTitle">
            <AnimateHeight animateOn={true}>
              {Object.keys(permissions).map(
                (key, i) =>
                  permissions[key].length > 0 && (
                    <Li key={`p-${i}`}>
                      <React.Fragment>
                        <h4>
                          {entityMap[key]
                            ? intl.formatMessage(entityMap[key])
                            : key}
                        </h4>
                        <div>
                          {permissions[key].map((p, i) => (
                            <span key={`pm-${i}`}>
                              {permissionsMap[p]
                                ? intl.formatMessage(permissionsMap[p])
                                : p}
                              {i !== permissions[key].length - 1 && ', '}
                            </span>
                          ))}
                        </div>
                      </React.Fragment>
                    </Li>
                  )
              )}
            </AnimateHeight>
          </Ul>
        )}
      </Card>
    </React.Fragment>
  )
}

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../../styles/styles'
import { CardImage } from '../card/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { Common_PublishedBy } from '../../../translations/messages'
import { useIntl } from 'react-intl'
import { parseStartEnd } from './calendar-helpers'

const EventCardStyled = styled.div`
  margin-bottom: ${spacing.md};
  border-radius: ${borderRadius};
  background: ${colors.trustLight};
`

const EventCardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.sm} ${spacing.sm} 0 ${spacing.sm};
  justify-content: space-between;
`

const EventCardBody = styled.div`
  padding: 0 ${spacing.sm} 0 ${spacing.sm};
`

const EventCardFooter = styled.small`
  display: block;
  padding: 0 ${spacing.sm} ${spacing.sm} ${spacing.sm};
`

type Props = {
  event: any
  onEventClicked: (event: any) => void
}

export const EventCard: FunctionComponent<Props> = ({
  event,
  onEventClicked,
}) => {
  const intl = useIntl()

  const isRecurring = Boolean(event.event_id)
  const startEnd = parseStartEnd(event, intl)

  return (
    <React.Fragment>
      <EventCardStyled onClick={() => onEventClicked(event)}>
        {event?.images?.length > 0 && (
          <CardImage src={event.images[0].medium} />
        )}
        <EventCardHeader>
          <strong>{startEnd}</strong>
          {isRecurring && <FontAwesomeIcon icon={faRedoAlt} />}
        </EventCardHeader>
        <EventCardBody>
          <div
            style={event.is_cancelled ? { textDecoration: 'line-through' } : {}}
          >
            {event.title}
          </div>
          {event.description && <p>{event.description}</p>}
        </EventCardBody>
        <EventCardFooter>
          {intl.formatMessage(Common_PublishedBy)} {event.creator?.first_name}{' '}
          {event.creator?.last_name}
        </EventCardFooter>
      </EventCardStyled>
    </React.Fragment>
  )
}

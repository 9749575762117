import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { ListItemLoader } from '../Loading'
import { Button } from '../Buttons'
import { ConfirmDialog } from '../modal/ConfirmDialog'
import { Card } from '../card/Card'
import {
  spacing,
  breakpoints,
  smallScreenBreakpoint,
} from '../../../styles/styles'
import { SearchAndAddToList } from '../SearchAndAddToList'
import { hasPermission } from '../../../lib/permissionHelpers'
import { AnimateHeight } from '../animations/AnimateHeight'
import { MemberListItem } from '../list/MemberListItem'
import { NoAccess } from '../NoAccess'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Common_Add,
  Common_Remove,
  Relations_AddRelationType,
  Relations_ConfirmRemove,
  Users_NoUsers,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'
import { usePermissions } from '../../../contexts/PermissionProvider'
// import { VideoCallButton } from '../VideoCallButton'

const UserList = styled(Card)`
  @media only screen and (min-width: ${breakpoints.LARGE}) {
    padding: 0 ${spacing.md} ${spacing.md} ${spacing.md};
  }
`

const ButtonGroup = styled.div`
  button:not(:last-child) {
    margin-right: ${spacing.md};
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListGroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

type RelationType = 'members' | 'admins' | 'employees' | 'service-receivers'

type Props = {
  id: string
  heading: string
  relationType: RelationType
  path: 'groups' | 'teams' | 'institutions'
  entity: 'Group' | 'Team' | 'Institution'
  requiredLimit?: number
  dataKey: string
  searchFilter?: (user: any) => any
}

export const HandleRelations: FunctionComponent<Props> = ({
  id,
  heading,
  relationType,
  path,
  entity,
  requiredLimit,
  dataKey,
  children,
  searchFilter,
}) => {
  const intl = useIntl()
  const { relationsByTargetId } = usePermissions(id)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [users, setUsers] = useState<any[]>([])
  const [confirmRemoveUser, setConfirmRemoveUser] = useState<any | null>(null)
  const [activeId, setActiveId] = useState<string | null>(null)
  const [showAddUser, setShowAddUser] = useState<boolean>(false)

  const relations = relationsByTargetId[id]
  const canEdit = hasPermission(relations, entity, 'UPDATE')

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < smallScreenBreakpoint
  )

  const onResize = useCallback(() => {
    setIsSmallScreen(window.innerWidth < smallScreenBreakpoint)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  useEffect(() => {
    setStatus('pending')

    authenticatedFetch(`${environment.API_URL}/${path}/${id}/${relationType}`)
      .then((response) => {
        setStatus('idle')
        // const uniqueList = response.filter((item, index) => {
        //   return (
        //     response.findIndex((element) => element.id === item.id) === index
        //   )
        // })
        setUsers(response.results ?? response)
      })
      .catch(() => setStatus('failure'))
  }, [id, path, entity, relationType])

  const addUsers = (newUsers: any) => {
    // TODO: The 'dataKey' should be replaced with user_ids once backend is updated

    setShowAddUser(false)
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/${path}/${id}/${relationType}`,
      {
        method: 'POST',
      },
      {
        [dataKey]: newUsers.map((u: any) => u.id),
      }
    )
      .then((response) => {
        setStatus('idle')
        setUsers(response.results ?? response)
      })
      .catch(() => setStatus('failure'))
  }

  const onlyOne = requiredLimit !== undefined && users?.length === 1

  const remove = (userId: string) => {
    setActiveId(userId)
    setConfirmRemoveUser(false)

    authenticatedFetch(
      `${environment.API_URL}/${path}/${id}/${relationType}/${userId}`,
      { method: 'DELETE' }
    )
      .then(() => {
        setActiveId(null)
        setUsers((prevState) => prevState!.filter((u) => u.id !== userId))
      })
      .catch(() => {
        setActiveId(null)
        setStatus('failure')
      })
  }

  if (status === 'failure') {
    return <NoAccess />
  }

  return (
    <UserList>
      <ListGroupHeader>
        <h2 id={`${heading.toLowerCase()}Heading`}>{heading}</h2>

        {canEdit && (
          <div
            style={{
              display: 'flex',
              gap: spacing.md,
              justifyContent: 'flex-end',
            }}
          >
            {children}

            <Button
              label={intl.formatMessage(Common_Add)}
              ariaLabel={intl.formatMessage(Common_Add)}
              variant="secondary"
              isXSmall={isSmallScreen}
              isSmall={!isSmallScreen}
              smallScreenIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setShowAddUser(true)}
            />
          </div>
        )}
      </ListGroupHeader>

      {status === 'pending' ? (
        <ListItemLoader />
      ) : (
        <AnimateHeight animateOn={users}>
          <List aria-labelledby={`${heading.toLowerCase()}Heading`}>
            {users.map((user) =>
              activeId === user.id ? (
                <ListItemLoader />
              ) : (
                <MemberListItem
                  key={user.id}
                  onClick={() => {}}
                  data={user}
                  trailingItem={
                    <ButtonGroup>
                      {/* {showVideoCallButton && !isNative && (
                        <VideoCallButton user={user} />
                      )} */}
                      {canEdit && !onlyOne ? (
                        <Button
                          label={intl.formatMessage(Common_Remove)}
                          ariaLabel={intl.formatMessage(Common_Remove)}
                          smallScreenIcon={<FontAwesomeIcon icon={faTrash} />}
                          variant="dangerOutline"
                          isXSmall={isSmallScreen}
                          isSmall={!isSmallScreen}
                          onClick={() => setConfirmRemoveUser(user)}
                        />
                      ) : null}
                    </ButtonGroup>
                  }
                />
              )
            )}

            {users.length === 0 && (
              <div>{intl.formatMessage(Users_NoUsers)}</div>
            )}
          </List>
        </AnimateHeight>
      )}

      <ConfirmDialog
        show={confirmRemoveUser}
        title={Relations_ConfirmRemove}
        onClose={() => setConfirmRemoveUser(false)}
        onConfirm={() => remove(confirmRemoveUser.id)}
        onCancel={() => setConfirmRemoveUser(false)}
      />

      <SearchAndAddToList
        title={intl.formatMessage(Relations_AddRelationType)}
        show={showAddUser}
        onChange={addUsers}
        filter={searchFilter}
        onDismiss={() => setShowAddUser(false)}
      />
    </UserList>
  )
}

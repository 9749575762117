import React, { FunctionComponent, useContext, useState } from 'react'
import { Card } from '../card/Card'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'
import { Avatar } from '../Avatar'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../contexts/AuthContext'
import { MessageLoader } from '../Loading'
import { ConfirmDialog } from '../modal/ConfirmDialog'
import { CommentContext } from './CommentList'
import { Button } from '../Buttons'
import { TextField } from '../form/TextField'
import { CardMenu } from '../card/CardMenu'
import {
  Comment_ConfirmDelete,
  Common_Cancel,
  Common_Delete,
  Common_Save,
  Common_Text,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'

const CommentCard = styled(Card)`
  display: flex;
  padding: ${spacing.md};
`

const CommentBody = styled.div`
  flex: 1;
  margin-left: ${spacing.md};
`

const Creator = styled(Link)`
  display: block;
  color: ${colors.joda};
  font-weight: bold;

  &:link,
  &:visited {
    text-decoration: none;
  }
`

const DateTime = styled.time`
  display: block;
  font-size: 0.8rem;
`

const Form = styled.form`
  margin: ${spacing.md} 0;
`

const InlineButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: ${spacing.md};
  }
`

type Props = {
  comment: any
}

export const Comment: FunctionComponent<Props> = ({ comment }) => {
  const intl = useIntl()
  const { updatingComment, deleteComment, updateComment } = useContext(
    CommentContext
  )
  const { authUser } = useContext(AuthContext)

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [updatedText, setUpdatedText] = useState<string>(comment.text)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  const { id, created_by, text, created } = comment

  if (updatingComment === id) {
    return <MessageLoader />
  }

  return (
    <React.Fragment>
      <ConfirmDialog
        show={showConfirmDelete}
        title={Comment_ConfirmDelete}
        confirmButtonText={Common_Delete}
        cancelButtonText={Common_Cancel}
        onClose={() => setShowConfirmDelete(true)}
        onConfirm={() => {
          setShowConfirmDelete(false)
          deleteComment(id)
        }}
        onCancel={() => setShowConfirmDelete(false)}
      />

      <CommentCard>
        <Avatar
          size="small"
          image={created_by.image}
          alt={`Bilde av ${created_by.first_name}`}
        />
        <CommentBody>
          <Creator to={`/profile/${created_by.id}`}>
            {created_by.first_name} {created_by.last_name}
          </Creator>
          <React.Fragment>
            {isEditMode ? (
              <Form>
                <TextField
                  id="text"
                  type="text"
                  label={Common_Text}
                  value={updatedText}
                  onChange={setUpdatedText}
                />
                <InlineButtonGroup>
                  <Button
                    label={intl.formatMessage(Common_Cancel)}
                    ariaLabel={intl.formatMessage(Common_Cancel)}
                    onClick={() => setIsEditMode(false)}
                    isSmall={true}
                    variant="secondary"
                  />
                  <Button
                    label={intl.formatMessage(Common_Save)}
                    ariaLabel={intl.formatMessage(Common_Save)}
                    onClick={() => {
                      setIsEditMode(false)
                      updateComment(id, updatedText)
                    }}
                    isSmall={true}
                    variant="primary"
                  />
                </InlineButtonGroup>
              </Form>
            ) : (
              text
            )}
          </React.Fragment>
          <DateTime>{new Date(created).toLocaleString()}</DateTime>
        </CommentBody>

        {authUser && authUser.id === created_by.id && (
          <CardMenu
            onEdit={() => setIsEditMode(true)}
            onDelete={() => setShowConfirmDelete(true)}
          />
        )}
      </CommentCard>
    </React.Fragment>
  )
}

import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  useContext,
  // useEffect,
  useState,
} from 'react'
import { authenticatedFetch } from '../lib/service'
import { environment } from '../environments'
import { RequestStatus } from '../types'
// import { AuthContext } from './AuthContext'

type VideoCall = {
  hasProvider: boolean
  videoCall: any
  setVideoCall: Dispatch<any>
  status: RequestStatus
  setStatus: Dispatch<RequestStatus>
}

const VideoCallContext = createContext<VideoCall>({
  hasProvider: false,
  videoCall: undefined,
  setVideoCall: () => {},
  status: 'idle',
  setStatus: () => {},
})

export const VideoCallProvider: FunctionComponent = ({ children }) => {
  // const { authUser } = useContext(AuthContext)
  const [hasProvider] = useState<boolean>(true)
  const [videoCall, setVideoCall] = useState<any>(undefined)
  const [status, setStatus] = useState<RequestStatus>('idle')

  // const fetchVideoCalls = async () => {
  //   authenticatedFetch(`${environment.API_URL}/video-calls`)
  //     .then((response) => {
  //       if (response.results.length > 0) {
  //         setVideoCall(response.results[0])
  //       } else {
  //         setVideoCall(undefined)
  //       }
  //     })
  //     .catch((error) => {})
  // }

  // useEffect(() => {
  //   if (authUser) {
  //     // @ts-ignore
  //     const isNative = window?.ReactNativeWebView
  //     if (isNative) {
  //       return
  //     }
  //     const id = setInterval(fetchVideoCalls, 5000)
  //     return () => clearInterval(id)
  //   }
  // }, [videoCall, authUser])

  return (
    <VideoCallContext.Provider
      value={{
        hasProvider,
        videoCall,
        setVideoCall,
        status,
        setStatus,
      }}
    >
      {children}
    </VideoCallContext.Provider>
  )
}

export const useVideoCalls = () => {
  const context = useContext(VideoCallContext)

  if (!context.hasProvider) {
    throw new Error('useVideoCalls must be used within a VideoCallProvider')
  }

  const { videoCall, setVideoCall, status, setStatus } = context

  const onVideoCallDeclined = () => setVideoCall(undefined)

  const onVideoCallAccepted = (call: any) => setVideoCall(call)

  const onVideoCallInitiated = (call: any) => setVideoCall(call)

  const initiateCall = (userId: string) => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/video-calls`,
      { method: 'POST' },
      {
        user_id: userId,
      }
    )
      .then((response) => {
        setStatus('idle')
        onVideoCallInitiated(response)
      })
      .catch(() => {
        setStatus('failure')
      })
  }

  const clearStatus = () => setStatus('idle')

  return {
    videoCall,
    initiateCall,
    status,
    onVideoCallDeclined,
    onVideoCallAccepted,
    onVideoCallInitiated,
    clearStatus,
  }
}

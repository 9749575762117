import React, { useContext } from 'react'
import { PageContainer } from '../../shared/layout/Layout'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import {
  borderRadius,
  breakpoints,
  colors,
  spacing,
} from '../../../styles/styles'
import { ProfileHeader } from '../../shared/ProfileHeader'
import { AuthContext } from '../../../contexts/AuthContext'
import { useIntl } from 'react-intl'
import {
  Common_Groups,
  Common_Institutions,
  Common_Moderator,
  Common_New,
  Common_ServiceReceivers,
  Common_Teams,
  EULA_Terms,
  Invitations_Invitations,
  More_AllGroups,
  More_AllInstitutions,
  More_AllTeams,
  Common_Logout,
  Common_MyNetwork,
  More_ReportBug,
  More_Support,
  Profile_MyProfile,
  Settings_Settings,
  Users_Title,
} from '../../../translations/messages'
import { useInvitations } from '../../../contexts/InvitationContext'
import { useContentToModerate } from '../../../contexts/ModeratorContext'

const MoreContainer = styled(PageContainer)`
  max-width: ${breakpoints.SMALL};
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: ${spacing.md};
  list-style: none;
  border-radius: ${borderRadius};
  background: white;

  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.black10};
  }
`

const ListGroupHeader = styled.h2`
  margin: 0 0 ${spacing.sm} 0;
  font-size: 1.3rem;

  &:not(:first-of-type) {
    margin-top: ${spacing.lg};
  }
`

const Badge = styled.small`
  color: white;
  border-radius: ${borderRadius};
  padding: 0.1rem 0.3rem;
  background: ${colors.closeLight};
`

export const MorePage = () => {
  const intl = useIntl()
  const { authUser, permissions } = useContext(AuthContext)
  const { invitations } = useInvitations()
  const { contentToModerate } = useContentToModerate()

  const canCreateTeam = permissions.PRIMARY_CONTACT
  // @ts-ignore
  const isNative = window?.ReactNativeWebView
  return (
    <MoreContainer>
      <ProfileHeader user={authUser}>
        <List style={{ marginTop: spacing.lg }}>
          <ListItem to={`/profile/${authUser?.id}`}>
            {intl.formatMessage(Profile_MyProfile)}
          </ListItem>

          <ListItem to={`/settings`}>
            {intl.formatMessage(Settings_Settings)}
          </ListItem>

          <ListItem to={`/invitations`}>
            {intl.formatMessage(Invitations_Invitations)}
            {invitations.length > 0 && <Badge>{invitations.length}</Badge>}
          </ListItem>

          <ListItem to={`/moderator`}>
            {intl.formatMessage(Common_Moderator)}
            {contentToModerate.length > 0 && (
              <Badge>{contentToModerate.length}</Badge>
            )}
          </ListItem>

          <ListItem to={`/eula`}>{intl.formatMessage(EULA_Terms)}</ListItem>

          <ListItem to="/logout">{intl.formatMessage(Common_Logout)}</ListItem>
        </List>
      </ProfileHeader>

      <List>
        <ListGroupHeader>{intl.formatMessage(Users_Title)}</ListGroupHeader>
        <ListItem to={`/my-network`}>
          {intl.formatMessage(Common_MyNetwork)}
        </ListItem>
        <ListItem to={`/service-receivers`}>
          {intl.formatMessage(Common_ServiceReceivers)}
        </ListItem>

        <ListGroupHeader>{intl.formatMessage(Common_Groups)}</ListGroupHeader>
        <ListItem to={`/groups/create`}>
          {intl.formatMessage(Common_New)}
        </ListItem>
        <ListItem to={`/groups`}>{intl.formatMessage(More_AllGroups)}</ListItem>

        <ListGroupHeader>{intl.formatMessage(Common_Teams)}</ListGroupHeader>
        {canCreateTeam && (
          <ListItem to={`/teams/create`}>
            {intl.formatMessage(Common_New)}
          </ListItem>
        )}
        <ListItem to={`/teams`}>{intl.formatMessage(More_AllTeams)}</ListItem>
        <>
          <ListGroupHeader>
            {intl.formatMessage(Common_Institutions)}
          </ListGroupHeader>
          <ListItem to={`/institutions`}>
            {intl.formatMessage(More_AllInstitutions)}
          </ListItem>
        </>

        <ListGroupHeader>{intl.formatMessage(More_Support)}</ListGroupHeader>
        {!isNative ? (
          <ListItem
            to={{
              pathname:
                'https://jodacare.atlassian.net/servicedesk/customer/portal/1/group/1/create/6',
            }}
            target="_blank"
          >
            {intl.formatMessage(More_ReportBug)}
          </ListItem>
        ) : (
          <ListItem
            to=""
            onClick={() =>
              // @ts-ignore
              window?.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'REDIRECT_URL',
                  payload:
                    'https://jodacare.atlassian.net/servicedesk/customer/portal/1/group/1/create/6',
                })
              )
            }
          >
            {intl.formatMessage(More_ReportBug)}
          </ListItem>
        )}
      </List>
    </MoreContainer>
  )
}

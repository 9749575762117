import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Card } from './card/Card'
import { borderRadius, breakpoints, colors, spacing } from '../../styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TranslatedMessage } from '../../types'
import { useIntl } from 'react-intl'

const TabsStyled = styled(Card)`
  margin-bottom: ${spacing.md};
  display: flex;
  border-radius: ${borderRadius};
  overflow: hidden;
`

const Tab = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  flex: 1;
  font-size: inherit;
  border: none;
  text-decoration: none;
  background: white;
  text-align: center;

  &.active {
    color: white;
    background: ${colors.joda};
  }
`

const TabLabel = styled.div`
  display: none;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    display: inline-block;
    margin-left: ${spacing.md};
  }
`

type Props = {
  tabs: { label: TranslatedMessage; icon: any }[]
  activeIndex: number
  onTabChanged: (newIndex: number) => void
}

export const SubPageTabs: FunctionComponent<Props> = ({
  onTabChanged,
  activeIndex,
  tabs,
}) => {
  const intl = useIntl()

  return (
    <TabsStyled>
      {tabs.map(({ label, icon }, i) => (
        <Tab
          key={`tab-${i}`}
          aria-label={label.defaultMessage.toLowerCase()}
          onClick={() => onTabChanged(i)}
          className={activeIndex === i ? 'active' : ''}
        >
          <FontAwesomeIcon icon={icon} />

          <TabLabel>{intl.formatMessage(label)}</TabLabel>
        </Tab>
      ))}
    </TabsStyled>
  )
}

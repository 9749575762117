import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../styles/styles'
import { SearchLoader } from './Loading'
import { AnimateHeight } from './animations/AnimateHeight'
import { TranslatedMessage } from '../../types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Common_NoResults } from '../../translations/messages'

const SearchInputWrapper = styled.div`
  position: relative;
  border: 1px solid ${colors.black20};
  border-radius: 24px;
`

const SearchInput = styled.input`
  width: 100%;
  padding: ${spacing.md};
  font-size: inherit;
  border: none;
  border-radius: 24px;
`

const SearchResults = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const SearchResultItem = styled.li`
  padding: ${spacing.md};
  transition: background 0.25s ease-out;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.black20};
  }

  &:hover {
    cursor: pointer;
    background: ${colors.black5};
  }
`

const ScrollWrapper = styled.div`
  position: relative;
  max-height: 50vh;
  overflow-x: scroll;
`

type Props = {
  searching: boolean
  placeholder: TranslatedMessage
  onChange: (term: string) => void
  results: any[]
  itemSelected: (item: any) => void
  ListItemComponent?: FunctionComponent<{
    data: any
    onClick?: (item: any) => void
  }>
}

export const SearchField: FunctionComponent<Props> = ({
  searching,
  placeholder,
  onChange,
  results,
  itemSelected,
  ListItemComponent,
}) => {
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState<any>('')

  const noResults = !searching && searchTerm && results.length === 0

  return (
    <SearchInputWrapper>
      <SearchInput
        type="search"
        placeholder={intl.formatMessage(placeholder)}
        value={searchTerm}
        autoFocus
        onChange={({ target }) => {
          setSearchTerm(target.value)
          onChange(target.value)
        }}
      />

      <ScrollWrapper>
        <AnimateHeight animateOn={true}>
          {searching && <SearchLoader />}

          {results.length > 0 ? (
            <SearchResults aria-label="searchResults">
              {results.map((result: any, i) =>
                ListItemComponent ? (
                  <ListItemComponent
                    key={`result-${i}`}
                    data={result}
                    onClick={(item) => {
                      setSearchTerm('')
                      itemSelected(item)
                    }}
                  />
                ) : (
                  <SearchResultItem
                    key={`result-${i}`}
                    onClick={() => itemSelected(result)}
                  >
                    {result.name}
                  </SearchResultItem>
                )
              )}
            </SearchResults>
          ) : noResults ? (
            <div
              style={{ padding: `0 ${spacing.md} ${spacing.md} ${spacing.md}` }}
            >
              <FormattedMessage
                id={Common_NoResults.id}
                defaultMessage={Common_NoResults.defaultMessage}
              />
            </div>
          ) : null}
        </AnimateHeight>
      </ScrollWrapper>
    </SearchInputWrapper>
  )
}

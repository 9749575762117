import { useCallback, useEffect } from 'react'

export const useScrolledToBottom = (callback: () => void) => {
  const onScroll = useCallback(() => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      callback()
    }
  }, [callback])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])
}

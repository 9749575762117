import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import Moment from 'moment'
import 'moment/locale/nb'
import { extendMoment } from 'moment-range'
import {
  borderRadius,
  colors,
  smallScreenBreakpoint,
  spacing,
} from '../../../styles/styles'
import { Card } from '../card/Card'
import { EventCard } from './EventCard'
import { MessageLoader } from '../Loading'
import { useHistory } from 'react-router-dom'
import { usePermissions } from '../../../contexts/PermissionProvider'
import { hasPermission } from '../../../lib/permissionHelpers'
import { FadeIn } from '../animations/FadeIn'
import { NoAccess } from '../NoAccess'
import { CalendarOccurrence } from '../../../types'
import {
  Common_New,
  Common_Next,
  Common_Prev,
  Common_Today,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'
import { CalendarEventModal } from './modal/CalendarEventModal'
import { useCalendar } from '../../../hooks/useCalendar'
import { AuthContext } from '../../../contexts/AuthContext'
// @ts-ignore
const moment = extendMoment(Moment)

const CalendarWrapper = styled(Card)`
  height: 100%;
`

const CalendarHeader = styled.div`
  position: relative;
  padding: ${spacing.md};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.black20};

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    justify-content: center;
  }
`

const CalendarButtons = styled.div`
  border: 1px solid ${colors.black20};
  border-radius: ${borderRadius};
  overflow: hidden;
`

const CalendarButton = styled.button`
  padding: ${spacing.sm} ${spacing.md};
  font-size: inherit;
  background: white;
  border: none;
`

const DateRange = styled.h3`
  margin: 0 ${spacing.md};
  padding: ${spacing.sm} ${spacing.md};
  text-align: center;
`

const NewEventButton = styled.button`
  position: absolute;
  right: ${spacing.md};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.lg};
  height: 35px;
  color: white;
  font-size: inherit;
  background: ${colors.safeDark};
  border: none;
  border-radius: ${borderRadius};
  text-transform: uppercase;
`

const CalendarColumns = styled.div`
  display: flex;
  flex-flow: row wrap;

  h4 {
    margin: 0;
  }
`

const CalendarColumn = styled.div`
  padding: ${spacing.md} ${spacing.sm};
  width: 100%;
  overflow: hidden;

  &.today {
    background: ${colors.black5};
  }

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    width: auto;
    flex: 1;
    min-height: 10rem;

    &:not(:last-child) {
      border-right: 1px solid ${colors.black10};
    }

    h4 {
      text-align: center;
    }
  }
`

type Props = {
  ownerId: string
}

const sortByStartTime = (a: any, b: any) => (a.start > b.start ? 1 : -1)

const createDateMapFromDateRange = (dateRange: any[]) => {
  let dateMap: { [key: string]: any[] } = {}
  dateRange.forEach(
    (date: any) => (dateMap = { ...dateMap, [date.format('YYYY-MM-DD')]: [] })
  )

  return dateMap
}

const sortEventsByDate = (dateRange: any[], events: any[]) => {
  let dateMap = createDateMapFromDateRange(dateRange)

  // Iterate events date range and sort events by date
  events.forEach((event) => {
    const isRecurring = Boolean(event.occurrences)

    if (isRecurring) {
      event.occurrences.forEach((occurrence: CalendarOccurrence) => {
        const formattedDate = moment(occurrence.start).format('YYYY-MM-DD')

        const data = {
          ...occurrence,
          description: event.description,
          images: event.images,
          creator: event.creator,
        }

        dateMap = {
          ...dateMap,
          [formattedDate]: dateMap[formattedDate]
            ? [...dateMap[formattedDate], data]
            : [data],
        }
      })
    } else {
      event.date_range.forEach((date: string) => {
        const formattedDate = moment(date).format('YYYY-MM-DD')

        dateMap = {
          ...dateMap,
          [formattedDate]: dateMap[formattedDate]
            ? [...dateMap[formattedDate], event]
            : [event],
        }
      })
    }
  })

  return dateMap
}

export const Calendar: FunctionComponent<Props> = ({ ownerId }) => {
  const { settings } = useContext(AuthContext)
  moment.locale(settings.language)

  const [view] = useState<'day' | 'week' | 'month'>('week')
  const [dateRange, setDateRange] = useState<any>(
    Array.from(
      moment
        .range(
          moment().startOf(view).toDate(),
          moment().endOf(view).endOf('day').toDate()
        )
        .by('day')
    )
  )
  const { fetchEvents, events, deleteEvent, updateOccurrence } = useCalendar(
    ownerId,
    dateRange
  )

  const intl = useIntl()
  const history = useHistory()
  const { relationsByTargetId } = usePermissions(ownerId)
  const today = moment().format('YYYY-MM-DD')
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [updateStatus, setUpdateStatus] = useState<
    'idle' | 'pending' | 'failure'
  >('idle')

  const [showEvent, setShowEvent] = useState<any>()

  const relations = relationsByTargetId[ownerId]
  const canRead = hasPermission(relations, 'Event', 'READ')
  const canCreate = hasPermission(relations, 'Event', 'CREATE')
  const canUpdate = hasPermission(relations, 'Event', 'CREATE')
  const startDate = dateRange[0]
  const endDate = dateRange[dateRange.length - 1]
  const goToPrev = () => {
    setDateRange(
      Array.from(
        moment
          .range(
            startDate.subtract(1, view).toDate(),
            endDate.subtract(1, view).toDate()
          )
          .by('day')
      )
    )
  }

  const goToToday = () =>
    setDateRange(
      Array.from(
        moment
          .range(moment().startOf(view).toDate(), moment().endOf(view).toDate())
          .by('day')
      )
    )

  const goToNext = () => {
    setDateRange(
      Array.from(
        moment
          .range(startDate.add(1, view).toDate(), endDate.add(1, view).toDate())
          .by('day')
      )
    )
  }

  useEffect(() => {
    setStatus('pending')

    fetchEvents()
      .then(() => setStatus('idle'))
      .catch(() => setStatus('failure'))
  }, [ownerId, dateRange, fetchEvents])

  const onDeleteEvent = async (eventId: string) => {
    setUpdateStatus('pending')

    deleteEvent(eventId)
      .then(() => {
        setUpdateStatus('idle')
        setShowEvent(null)
      })
      .catch(() => setUpdateStatus('failure'))
  }

  const onUpdateOccurrence = (data: any) => {
    setUpdateStatus('pending')

    updateOccurrence(data)
      .then(() => {
        setUpdateStatus('idle')
        setShowEvent(null)
      })
      .catch(() => setUpdateStatus('failure'))
  }

  const sortedEvents = sortEventsByDate(dateRange, events)

  return (
    <React.Fragment>
      {canRead ? (
        <FadeIn showOn={true}>
          <CalendarWrapper>
            <CalendarHeader>
              <CalendarButtons>
                <CalendarButton onClick={goToPrev}>
                  {intl.formatMessage(Common_Prev)}
                </CalendarButton>
                <CalendarButton onClick={goToToday}>
                  {intl.formatMessage(Common_Today)}
                </CalendarButton>
                <CalendarButton onClick={goToNext}>
                  {intl.formatMessage(Common_Next)}
                </CalendarButton>
              </CalendarButtons>

              {canCreate && (
                <NewEventButton
                  onClick={() => history.push(`/calendar/${ownerId}/create`)}
                >
                  {intl.formatMessage(Common_New)}
                </NewEventButton>
              )}
            </CalendarHeader>

            <DateRange>
              {moment(startDate).format('D. MMMM')} -{' '}
              {moment(endDate).format('D. MMMM')}{' '}
              {moment(endDate).format('YYYY')}
            </DateRange>

            <CalendarColumns>
              {dateRange.map((date: any, i: number) => {
                const formattedDate = date.format('YYYY-MM-DD')
                const events =
                  sortedEvents && sortedEvents[formattedDate]
                    ? sortedEvents[formattedDate]
                        .sort(sortByStartTime)
                        .map((event: any) => (
                          <EventCard
                            key={`${event.id ?? event.event_id}-i`}
                            event={event}
                            onEventClicked={(event) => setShowEvent(event)}
                          />
                        ))
                    : []

                return (
                  <CalendarColumn
                    key={`d-${i}`}
                    className={
                      date.format('YYYY-MM-DD') === today ? 'today' : ''
                    }
                  >
                    <h4>{date.format('dddd D/M')}</h4>
                    {status === 'pending' ? (
                      <div style={{ marginTop: spacing.md }}>
                        <MessageLoader />
                      </div>
                    ) : (
                      events
                    )}
                  </CalendarColumn>
                )
              })}
            </CalendarColumns>
          </CalendarWrapper>
        </FadeIn>
      ) : !relations ? (
        <MessageLoader />
      ) : (
        <NoAccess />
      )}

      {showEvent && (
        <CalendarEventModal
          updateStatus={updateStatus}
          event={showEvent}
          onUpdateOccurrence={onUpdateOccurrence}
          onClose={() => setShowEvent(null)}
          onDelete={onDeleteEvent}
          canUpdate={canUpdate}
        />
      )}
    </React.Fragment>
  )
}

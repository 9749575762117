import React, { FunctionComponent } from 'react'
import { Dialog } from './Dialog'
import styled from 'styled-components'
import { breakpoints, spacing } from '../../../styles/styles'
import { Card } from '../card/Card'
import { Button } from '../Buttons'
import { FormattedMessage, useIntl } from 'react-intl'
import { TranslatedMessage } from '../../../types'
import { Common_No, Common_Yes } from '../../../translations/messages'

export const DialogCard = styled(Card)`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: ${spacing.lg};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media only screen and (min-width: ${breakpoints.LARGE}) {
    position: relative;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    min-width: ${breakpoints.SMALL};
    max-width: ${breakpoints.MEDIUM};
  }
`

const DialogTitle = styled.h1`
  margin: 0;

  @media only screen and (max-width: ${breakpoints.MEDIUM}) {
    margin-bottom: ${spacing.md};
    font-size: 1.2rem;
  }
`

const DialogText = styled.p`
  margin: ${spacing.lg} 0 0 0;
`

const DialogActions = styled.div`
  display: flex;
  flex-direction: column;

  button {
    height: 3rem;

    &:last-of-type {
      margin: ${spacing.md} 0 0 0;
    }
  }

  @media only screen and (min-width: ${breakpoints.LARGE}) {
    margin-top: ${spacing.lg};
    flex-direction: row;
    justify-content: flex-end;

    button {
      height: 2.5rem;

      &:last-of-type {
        margin: 0 0 0 ${spacing.md};
      }
    }
  }
`

type Props = {
  show: boolean
  title: TranslatedMessage
  description?: TranslatedMessage
  confirmButtonText?: TranslatedMessage
  cancelButtonText?: TranslatedMessage
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmDialog: FunctionComponent<Props> = ({
  show,
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  onClose,
  onConfirm,
  onCancel,
}) => {
  const intl = useIntl()

  return show ? (
    <Dialog onClose={onClose}>
      <DialogCard>
        <DialogTitle>
          <FormattedMessage
            id={title.id}
            defaultMessage={title.defaultMessage}
            values={{ ...title.values }}
          />
        </DialogTitle>
        {description && <DialogText>{description}</DialogText>}
        <DialogActions>
          <Button
            ariaLabel={intl.formatMessage(cancelButtonText ?? Common_No)}
            label={intl.formatMessage(cancelButtonText ?? Common_No)}
            onClick={onCancel}
            isSmall={true}
            variant="secondary"
          />
          <Button
            ariaLabel={intl.formatMessage(confirmButtonText ?? Common_Yes)}
            label={intl.formatMessage(confirmButtonText ?? Common_Yes)}
            onClick={onConfirm}
            isSmall={true}
            variant="primary"
          />
        </DialogActions>
      </DialogCard>
    </Dialog>
  ) : null
}

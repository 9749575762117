import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { CardAction } from './card/Card'
import React, { FunctionComponent, useState } from 'react'
import { ConfirmDialog } from './modal/ConfirmDialog'
import { usePosts } from '../../contexts/PostProvider'
import { useMessages } from '../../contexts/MessageProvider'
import { colors } from '../../styles/styles'
import {
  Common_Cancel,
  Common_Notify,
  Moderator_NotifyTitle,
} from '../../translations/messages'
import { FormattedMessage } from 'react-intl'

type Props = {
  id: string
  ownerId: string
  contentType: 'Post' | 'Message'
}

export const NotifyButton: FunctionComponent<Props> = ({
  id,
  ownerId,
  contentType,
}) => {
  const { notifyModerator: notifyPost } = usePosts()
  const { notifyModerator: notifyMessage } = useMessages()
  const [showConfirmNotify, setShowConfirmNotify] = useState<boolean>(false)

  const onNotify = () => {
    setShowConfirmNotify(false)

    if (contentType === 'Post') {
      notifyPost(id, ownerId)
    } else if (contentType === 'Message') {
      notifyMessage(id, ownerId)
    }
  }

  return (
    <React.Fragment>
      <CardAction
        style={{ color: colors.warmthDark }}
        onClick={() => setShowConfirmNotify(true)}
      >
        <FontAwesomeIcon
          color={colors.warmthDark}
          icon={faExclamationTriangle}
        />
        {contentType === 'Message' && (
          <FormattedMessage
            id={Common_Notify.id}
            defaultMessage={Common_Notify.defaultMessage}
          />
        )}
      </CardAction>

      <ConfirmDialog
        show={showConfirmNotify}
        title={Moderator_NotifyTitle}
        onClose={() => setShowConfirmNotify(false)}
        onConfirm={onNotify}
        confirmButtonText={Common_Notify}
        cancelButtonText={Common_Cancel}
        onCancel={() => setShowConfirmNotify(false)}
      />
    </React.Fragment>
  )
}

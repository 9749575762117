import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/styles'
import { TranslatedMessage } from '../../types'
import { FormattedMessage } from 'react-intl'

const EmptyStateWrapper = styled.div`
  text-align: center;

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
    color: ${colors.black80};
  }
`

type Props = {
  title: TranslatedMessage
  text: TranslatedMessage
}

export const EmptyState: FunctionComponent<Props> = ({ title, text }) => {
  return (
    <EmptyStateWrapper>
      <h1>
        <FormattedMessage id={title.id} defaultMessage={title.defaultMessage} />
      </h1>
      <p>
        <FormattedMessage id={text.id} defaultMessage={text.defaultMessage} />
      </p>
    </EmptyStateWrapper>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { Card } from './card/Card'
import { spacing } from '../../styles/styles'
import styled from 'styled-components'
import { FadeIn } from './animations/FadeIn'

const LoadingCard = styled(Card)`
  padding-left: ${spacing.md};
  overflow: hidden;
`

export const ListItemLoader = () => {
  return (
    <LoadingCard>
      <ContentLoader
        speed={2}
        width={300}
        height={70}
        viewBox={`0 0 300 70`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <circle cx="29" cy="39" r="27" />
        <rect x="67" y="21" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="50" r="8" />
        <rect x="69" y="47" rx="5" ry="5" width="139" height="10" />
      </ContentLoader>
    </LoadingCard>
  )
}

export const MessageLoader = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>(window.screen.width - 32)

  useEffect(() => {
    if (ref && ref.current) {
      const parent = ref.current
      setWidth(parent.offsetWidth - 32)
    }
  }, [ref])

  return (
    <FadeIn showOn={true}>
      <LoadingCard ref={ref} data-testid="loader">
        <ContentLoader
          speed={2}
          width={width}
          height={80}
          viewBox={`0 0 ${width} 80`}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="20" rx="5" ry="5" width="100" height="10" />
          <rect x="0" y="40" rx="5" ry="5" width={width} height="10" />
          <rect x="0" y="60" rx="5" ry="5" width="250" height="10" />
        </ContentLoader>
      </LoadingCard>
    </FadeIn>
  )
}

export const SearchLoader = () => {
  return (
    <LoadingCard>
      <ContentLoader
        speed={2}
        width="250"
        height={50}
        viewBox={`0 0 250 50`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="23" rx="5" ry="5" width="250" height="10" />
      </ContentLoader>
    </LoadingCard>
  )
}

export const PostLoader = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>(window.screen.width - 32)

  useEffect(() => {
    if (ref && ref.current) {
      const parent = ref.current
      setWidth(parent.offsetWidth - 32)
    }
  }, [ref])

  return (
    <LoadingCard ref={ref}>
      <ContentLoader
        speed={2}
        width={width}
        height={350}
        viewBox={`0 0 ${width} 350`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="16" rx="2" ry="2" width="140" height="10" />
        <rect x="0" y="42" rx="2" ry="2" width={width} height="300" />
      </ContentLoader>
    </LoadingCard>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { PageContainer } from '../../shared/layout/Layout'
import { NavBarContext } from '../../../contexts/NavBarContext'
import { breakpoints } from '../../../styles/styles'
import { AnimateHeight } from '../../shared/animations/AnimateHeight'
import { MessageLoader } from '../../shared/Loading'
import { ErrorMessage } from '../../shared/Messages'
import moment from 'moment'
import styled from 'styled-components'
import { authenticatedFetch, uploadImages } from '../../../lib/service'
import { environment } from '../../../environments'
import { Event_Create } from '../../../translations/messages'
import { useIntl } from 'react-intl'
import { EventForm } from './common/EventForm'

const CreateContainer = styled(PageContainer)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${breakpoints.SMALL};
`

const roundToClosestHalfHour = (date: string) => {
  const start = moment(date)
  const remainder = 30 - (start.minute() % 30)
  return moment(start).add(remainder, 'minutes').toISOString()
}

const initialStart = roundToClosestHalfHour(moment().toISOString())
const initialEnd = moment(initialStart).add(30, 'minutes').toISOString()

const initialEvent = {
  title: '',
  description: '',
  start: initialStart,
  end: initialEnd,
  options: null,
  images: null,
}

const removeProp = (obj: any, prop: any) => {
  let { [prop]: omit, ...res } = obj
  return res
}

export const CreateEventPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const { ownerId } = useParams<{ ownerId: string }>()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [event, setEvent] = useState<any>({
    ...initialEvent,
    owner_id: ownerId,
  })

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Event_Create))
  }, [updateNavBarTitle, intl])

  const createEvent = async () => {
    setStatus('pending')
    let newEvent =
      event.options === undefined || event.options === null
        ? removeProp(event, 'options')
        : event

    newEvent = removeProp(event, 'is_all_day')
    if (event.images && event.images.length > 0) {
      try {
        const imageIds = await uploadImages(event.images)
        if (imageIds && imageIds.length > 0) {
          newEvent.image_id = imageIds[0]
        }
      } catch (error) {
        setStatus('failure')
        return
      }
    }
    delete newEvent.images
    authenticatedFetch(
      `${environment.API_URL}/events`,
      { method: 'POST' },
      newEvent
    )
      .then(() => {
        setStatus('idle')
        history.goBack()
      })
      .catch(() => {
        setStatus('failure')
      })
  }

  return (
    <CreateContainer>
      <AnimateHeight animateOn={true}>
        {status === 'pending' ? (
          <MessageLoader />
        ) : (
          <React.Fragment>
            {status === 'failure' && (
              <ErrorMessage>Det oppstod dessverre en feil</ErrorMessage>
            )}

            <EventForm
              event={event}
              onChange={setEvent}
              submitForm={createEvent}
            />
          </React.Fragment>
        )}
      </AnimateHeight>
    </CreateContainer>
  )
}

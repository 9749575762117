import { AnimateHeight } from '../../animations/AnimateHeight'
import { Button } from '../../Buttons'
import {
  Common_Close,
  Common_Delete,
  Common_Edit,
  Common_ErrorOccurred,
  Event_Cancel,
  Event_Cancelled,
  Event_ConfirmCancelOccurrence,
  Event_ConfirmDeleteEvent,
  Event_ConfirmUnCancelOccurrence,
  Event_UnCancel,
} from '../../../../translations/messages'
import { Card, CardImage } from '../../card/Card'
import { Dialog } from '../../modal/Dialog'
import React, { FunctionComponent, useContext, useState } from 'react'
import styled from 'styled-components'
import { breakpoints, colors, spacing } from '../../../../styles/styles'
import { useIntl } from 'react-intl'
import { parseStartEnd } from '../calendar-helpers'
import { AuthContext } from '../../../../contexts/AuthContext'
import { ConfirmDialog } from '../../modal/ConfirmDialog'
import { useHistory } from 'react-router-dom'

const CalendarEventDialogCard = styled(Card)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: ${spacing.md} ${spacing.md};
  width: 100%;
  height: 100%;

  h1,
  h2 {
    margin: 0;
    text-align: center;
  }

  p {
    text-align: center;
  }

  @media only screen and (min-width: ${breakpoints.LARGE}) {
    margin-top: ${spacing.xl};
    max-width: ${breakpoints.LARGE};
    height: auto;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.md};
  gap: ${spacing.md};
`

const ErrorMsg = styled.div`
  color: ${colors.error};
  margin-top: ${spacing.md};
  text-align: right;
`

type Props = {
  event: any
  updateStatus: 'idle' | 'pending' | 'failure'
  onClose: () => void
  onUpdateOccurrence: (data: any) => void
  onDelete: (eventId: string) => void
  canUpdate: boolean
}

export const CalendarEventModal: FunctionComponent<Props> = ({
  event,
  updateStatus,
  onClose,
  onUpdateOccurrence,
  onDelete,
  canUpdate,
}) => {
  const { authUser } = useContext(AuthContext)
  const history = useHistory()
  const [confirmEvent, setConfirmEvent] = useState<
    'none' | 'cancel_occurrence' | 'delete'
  >('none')

  const intl = useIntl()
  const isRecurring = Boolean(event.event_id)
  const eventStartEnd = parseStartEnd(event, intl)
  const isCreator = authUser?.id === event.creator.id

  const toggleCancelOccurrence = () => {
    setConfirmEvent('none')

    const data = {
      id: event.id,
      event_id: event.event_id,
      title: event.title,
      start: event.start,
      end: event.end,
      org_start: event.org_start,
      org_end: event.org_end,
      is_cancelled: !event.is_cancelled,
    }

    onUpdateOccurrence(data)
  }

  const isLoading = updateStatus === 'pending'

  return (
    <Dialog onClose={onClose}>
      <CalendarEventDialogCard>
        <AnimateHeight animateOn={true}>
          <div
            style={{ position: 'absolute', top: spacing.md, right: spacing.md }}
          >
            <Button
              ariaLabel={intl.formatMessage(Common_Close)}
              variant="secondary"
              isSmall={true}
              label={intl.formatMessage(Common_Close)}
              onClick={onClose}
            />
          </div>

          {event.images?.length > 0 && (
            <CardImage src={event.images[0].medium} />
          )}

          <h1
            style={event.is_cancelled ? { textDecoration: 'line-through' } : {}}
          >
            {event.title}
          </h1>
          <h2>{eventStartEnd}</h2>

          {event.is_cancelled && <p>{intl.formatMessage(Event_Cancelled)}</p>}

          {event.description && <p>{event.description}</p>}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {event.creator.first_name} {event.creator.last_name}
            </div>

            {(isCreator || canUpdate) && (
              <ButtonGroup>
                <Button
                  label={intl.formatMessage(Common_Edit)}
                  ariaLabel={intl.formatMessage(Common_Edit)}
                  variant="secondary"
                  isSmall
                  disabled={isLoading}
                  onClick={() =>
                    history.push(`/calendar/${event.id ?? event.event_id}/edit`)
                  }
                />

                {isRecurring && (
                  <Button
                    label={intl.formatMessage(
                      event.is_cancelled ? Event_UnCancel : Event_Cancel
                    )}
                    ariaLabel={intl.formatMessage(
                      event.is_cancelled ? Event_UnCancel : Event_Cancel
                    )}
                    variant="danger"
                    isSmall
                    disabled={isLoading}
                    onClick={() => setConfirmEvent('cancel_occurrence')}
                  />
                )}

                <Button
                  label={intl.formatMessage(Common_Delete)}
                  ariaLabel={intl.formatMessage(Common_Delete)}
                  variant="danger"
                  isSmall
                  disabled={isLoading}
                  onClick={() => setConfirmEvent('delete')}
                />
              </ButtonGroup>
            )}
          </div>

          {confirmEvent === 'cancel_occurrence' && (
            <ConfirmDialog
              show={true}
              title={
                event.is_cancelled
                  ? Event_ConfirmUnCancelOccurrence
                  : Event_ConfirmCancelOccurrence
              }
              onClose={() => setConfirmEvent('none')}
              onConfirm={toggleCancelOccurrence}
              onCancel={() => setConfirmEvent('none')}
            />
          )}

          {confirmEvent === 'delete' && (
            <ConfirmDialog
              show={true}
              title={Event_ConfirmDeleteEvent}
              onClose={() => setConfirmEvent('none')}
              onConfirm={() => {
                setConfirmEvent('none')

                onDelete(event.id ?? event.event_id)
              }}
              onCancel={() => setConfirmEvent('none')}
            />
          )}

          {updateStatus === 'failure' && (
            <ErrorMsg>{intl.formatMessage(Common_ErrorOccurred)}</ErrorMsg>
          )}
        </AnimateHeight>
      </CalendarEventDialogCard>
    </Dialog>
  )
}

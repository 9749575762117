import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../shared/layout/Layout'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { Card } from '../../../shared/card/Card'
import { MessageLoader } from '../../../shared/Loading'
import styled from 'styled-components'
import { smallScreenBreakpoint, spacing } from '../../../../styles/styles'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import { SubPageTabs } from '../../../shared/SubPageTabs'
import {
  faBookOpen,
  faClock,
  faInfoCircle,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { PostFeed } from '../../../shared/post/PostFeed'
import { InviteButton } from '../../../shared/invitations/InviteButton'
import { InstitutionMembers } from './InstitutionMembers'
import { Calendar } from '../../../shared/calendar/Calendar'
import { UserListItem } from '../../../shared/list'
import { InstitutionInfo } from './InstitutionInfo'
import {
  Common_Book,
  Common_Calendar,
  Common_EmployedAt,
  Common_Info,
  Common_Member,
  Common_Members,
  Institutions_NoManager,
  Invitations_Invitations,
  Relation_Manager,
} from '../../../../translations/messages'
import { usePermissions } from '../../../../contexts/PermissionProvider'
import { hasPermission } from '../../../../lib/permissionHelpers'
import { CalendarProvider } from '../../../../hooks/useCalendar'
import { InvitationList } from '../../../shared/invitations/InvitationList'
import { useIntl } from 'react-intl'

const InstitutionCard = styled(Card)`
  margin-bottom: ${spacing.md};
  padding: ${spacing.lg};

  h1 {
    display: none;
    text-align: center;

    @media only screen and (min-width: ${smallScreenBreakpoint}px) {
      display: block;
      margin: 0 0 ${spacing.lg};
    }
  }

  p {
    margin: 0 auto;
    max-width: 75ch;
    text-align: center;
  }
`

const tabs = [
  { label: Common_Info, icon: faInfoCircle },
  { label: Common_Calendar, icon: faClock },
  { label: Common_Book, icon: faBookOpen },
  { label: Common_Members, icon: faUsers },
]

export const InstitutionDetailPage = () => {
  const intl = useIntl()
  const { id } = useParams<{ id: string }>()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const { relationsByTargetId } = usePermissions(id)

  const [activeTab, setActiveTab] = useState<number>(2)
  const [institution, setInstitution] = useState<any>()

  const relations = relationsByTargetId[id]
  const canCreatePost = hasPermission(relations, 'Institution', 'CREATE')
  const canUpdateRelations = hasPermission(relations, 'Institution', 'UPDATE')
  const canViewEmployees = hasPermission(relations, 'EmployedAt', 'READ')
  const canViewMembers = hasPermission(relations, 'Member', 'READ')

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/institutions/${id}`)
      .then((response) => {
        setInstitution(response)
        updateNavBarTitle(response.name)
      })
      .catch((error) => {})
  }, [id, updateNavBarTitle])

  return (
    <PageContainer>
      {institution ? (
        <React.Fragment>
          <InstitutionCard>
            <h1>{institution.name}</h1>
            <p>{institution.description}</p>

            <h3 style={{ margin: 0 }}>
              {intl.formatMessage(Relation_Manager)}
            </h3>

            {institution.manager ? (
              <UserListItem data={institution.manager} />
            ) : (
              intl.formatMessage(Institutions_NoManager)
            )}
          </InstitutionCard>

          <SubPageTabs
            tabs={tabs}
            activeIndex={activeTab}
            onTabChanged={setActiveTab}
          />

          {activeTab === 0 && <InstitutionInfo institution={institution} />}

          {activeTab === 1 && (
            <CalendarProvider>
              <Calendar ownerId={id} />
            </CalendarProvider>
          )}

          {activeTab === 2 && (
            <PostFeed ownerId={id} canCreate={canCreatePost} />
          )}

          {activeTab === 3 && (
            <React.Fragment>
              {canUpdateRelations && (
                <React.Fragment>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <h3 style={{ margin: 0 }}>
                      {intl.formatMessage(Invitations_Invitations)}
                    </h3>

                    <InviteButton
                      id={id}
                      entityType="Institution"
                      relations={[
                        { key: 'MEMBER', value: Common_Member },
                        { key: 'EMPLOYED_AT', value: Common_EmployedAt },
                      ]}
                    />
                  </div>

                  <InvitationList id={id} />
                </React.Fragment>
              )}

              <InstitutionMembers
                canViewEmployees={canViewEmployees}
                canViewMembers={canViewMembers}
                id={id}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <MessageLoader />
      )}
    </PageContainer>
  )
}

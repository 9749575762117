import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Button } from '../Buttons'
import { spacing } from '../../../styles/styles'
import { Common_Delete } from '../../../translations/messages'
import { useIntl } from 'react-intl'

const Wrapper = styled.div`
  position: relative;

  button {
    position: absolute;
    top: ${spacing.md};
    right: ${spacing.md};
    z-index: 1;
  }
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
`

type Props = {
  onClick: () => void
  disabled: boolean
}

export const DeleteWrapper: FunctionComponent<Props> = ({
  onClick,
  disabled,
  children,
}) => {
  const intl = useIntl()

  return (
    <Wrapper>
      {disabled && <Overlay />}

      <Button
        isSmall={true}
        onClick={() => onClick()}
        label={intl.formatMessage(Common_Delete) + ' media'}
        ariaLabel={intl.formatMessage(Common_Delete)}
        variant="danger"
        disabled={disabled}
      />
      {children}
    </Wrapper>
  )
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../styles/styles'
import { useIntl } from 'react-intl'
import { Common_AddMedia } from '../../translations/messages'
import VideoThumbnail from 'react-video-thumbnail'
import { b64toBlob } from '../../lib/file.service'

const AddMediaButton = styled.label`
  display: block;
  padding: ${spacing.lg};
  border: 1px solid ${colors.black20};
  background: ${colors.black5};
  text-align: center;

  svg {
    margin-bottom: ${spacing.md};
    font-size: 1.5rem;
  }

  input {
    visibility: hidden;
  }

  &:active {
    background: white;
  }
`

type Props = {
  onChange: (medias: any[], mediaType: string) => void
}

export const AddMedia: FunctionComponent<Props> = ({ onChange }) => {
  const intl = useIntl()
  const [preview, setPreview] = useState<any[]>([])
  const [images, setImages] = useState<any[]>([])
  const [videos, setVideos] = useState<any[]>([])

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement

    // @ts-ignore
    if (target.files.length > 0) {
      // @ts-ignore
      const file = target.files[0]

      // const preview = await createPreview(file)
      if (file.type.includes('video')) {
        setVideos([...videos, { file, thumbnail: null }])
        onChange([...videos, { file, thumbnail: null }], 'video')
        setPreview([...preview, { type: 'video', media: preview }])
      } else if (file.type.includes('image')) {
        // await getImageOrientation(file)
        setImages([...images, file])
        onChange([...images, file], 'image')
        setPreview([...preview, { type: 'image', media: preview }])
      }
    }
  }
  const handleCreateThumbnail = (thumbnail, index) => {
    var blob: any = b64toBlob(thumbnail)
    if (blob) {
      const videoFilename = videos[index].file.name
      const ext = videoFilename.split('.')[1]
      blob.name = videos[index].file.name.replace(`.${ext}`, '_thumb') + '.jpeg'
      videos[index] = {
        ...videos[index],
        thumbnail: blob,
      }
      setVideos(videos)
      onChange(videos, 'video')
    }
  }
  return (
    <div key={videos && videos.length}>
      <div
        style={{
          display: 'flex',
          flexFlow: 'wrap',
        }}
      >
        {images && images.length > 0 && (
          <>
            {images.map((image, index) => {
              return (
                <div style={{ marginRight: '10px' }} key={image.name}>
                  <img
                    width="200"
                    height="200"
                    src={URL.createObjectURL(image)}
                    alt="uploaded"
                    id={`image-${index}`}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => {
                      setImages(images.filter((img) => img !== image))
                      onChange(
                        images.filter((img) => img !== image),
                        'image'
                      )
                    }}
                    color="red"
                  />
                </div>
              )
            })}
          </>
        )}
        {videos && videos.length > 0 && (
          <>
            {videos.map((video, index) => {
              return (
                <div key={video.file.name}>
                  <video width="300" controls id={`video-${index}`}>
                    <source src={URL.createObjectURL(video.file)} />
                  </video>
                  <div style={{ display: 'none' }}>
                    <VideoThumbnail
                      videoUrl={URL.createObjectURL(video.file)}
                      thumbnailHandler={(thumbnail: any) =>
                        handleCreateThumbnail(thumbnail, index)
                      }
                    />
                  </div>
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => {
                      setVideos(videos.filter((v) => v !== video))
                      onChange(
                        videos.filter((v) => v !== video),
                        'video'
                      )
                    }}
                    color="red"
                  />
                </div>
              )
            })}
          </>
        )}
      </div>
      <AddMediaButton htmlFor="file" onClick={() => {}}>
        <FontAwesomeIcon icon={faPlus} />
        <div>{intl.formatMessage(Common_AddMedia)}</div>
        <input
          id="file"
          type="file"
          onChange={onFileChange}
          accept="video/mp4,video/mov,video/quicktime,image/x-png,image/jpg,image/jpeg"
        />
      </AddMediaButton>
    </div>
  )
}

import { Button } from '../Buttons'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { hasPermission } from '../../../lib/permissionHelpers'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { Dialog } from '../modal/Dialog'
import { Card } from '../card/Card'
import { TextField } from '../form/TextField'
import styled from 'styled-components'
import {
  breakpoints,
  colors,
  smallScreenBreakpoint,
  spacing,
} from '../../../styles/styles'
import { MessageLoader } from '../Loading'
import { InvitationItem } from './InvitationItem'
import { AnimateHeight } from '../animations/AnimateHeight'
import {
  Common_Add,
  Common_CellPhoneNumber,
  Common_Close,
  Common_Email,
  Common_ErrorOccurred,
  Common_FirstName,
  Common_LastName,
  Common_Remove,
  Invitations_Invitations,
  Invitations_InviteUsers,
  Invitations_NoInvitations,
  Invitations_SendInvitations,
  Relations_ChooseRelation,
  Relations_Relation,
} from '../../../translations/messages'
import { FormattedMessage, useIntl } from 'react-intl'
import { Invitation, RelationType } from '../../../types'

const DialogHeader = styled.div`
  margin-bottom: ${spacing.lg};
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin: 0;
  }
`

const StyledSelect = styled.div`
  margin-bottom: ${spacing.md};

  label {
    text-transform: uppercase;
  }

  select {
    padding: ${spacing.sm};
    width: 100%;
    font-size: inherit;
    font-family: inherit;
  }

  option {
    padding: ${spacing.sm};
  }
`

const FlexEnd = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    justify-content: flex-end;
  }
`

const DialogCard = styled(Card)`
  position: fixed;
  padding: ${spacing.lg};
  top: 0;
  width: 100%;
  max-height: 100%;
  overflow: scroll;
  bottom: 0;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    width: ${breakpoints.LARGE};
    top: 10%;
    max-height: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
`

type Props = {
  id: string
  entityType: 'Group' | 'Institution' | 'User' | 'Team'
  relations?: RelationType[]
  relation?: string
}

export const InviteButton: FunctionComponent<Props> = ({
  id,
  entityType,
  relations,
  relation,
}) => {
  const initialInvitation = {
    email: '',
    first_name: '',
    last_name: '',
    entity_id: id,
    entity_type: entityType,
    relation: relation ?? undefined,
  }

  const intl = useIntl()
  const [entityRelations, setEntityRelations] = useState<any[]>([])
  const [invitation, setInvitation] = useState<Invitation>(initialInvitation)
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [status, setStatus] = useState<
    'idle' | 'pending' | 'success' | 'failure'
  >('idle')

  const addInvitationToList = () => {
    setInvitations([...invitations, invitation])
    setInvitation(initialInvitation)
  }

  const canInvite = hasPermission(entityRelations, entityType, 'UPDATE')

  const invitationValid = Boolean(
    invitation.email &&
      invitation.first_name &&
      invitation.last_name &&
      invitation.relation
  )

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/permissions/${id}`).then(
      (response) => {
        setEntityRelations(response)
      }
    )
  }, [id])

  const sendInvitations = () => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/invitations`,
      { method: 'POST' },
      { invitations }
    )
      .then(() => {
        setStatus('success')
        setInvitations([])
      })
      .catch(() => {
        setStatus('failure')
      })
  }

  return canInvite ? (
    <React.Fragment>
      <AnimateHeight animateOn={true}>
        <Button
          label={intl.formatMessage(Invitations_InviteUsers)}
          ariaLabel={intl.formatMessage(Invitations_InviteUsers)}
          variant="primary"
          isSmall={true}
          onClick={() => setShowDialog(true)}
        />
      </AnimateHeight>

      {showDialog && (
        <Dialog onClose={() => setShowDialog(false)}>
          <DialogCard>
            <DialogHeader>
              <h1>
                <FormattedMessage
                  id={Invitations_InviteUsers.id}
                  defaultMessage={Invitations_InviteUsers.defaultMessage}
                />
              </h1>
              <Button
                label={intl.formatMessage(Common_Close)}
                ariaLabel={intl.formatMessage(Common_Close)}
                onClick={() => {
                  setShowDialog(false)
                  setStatus('idle')
                  setInvitations([])
                }}
                isSmall={true}
                variant="secondary"
              />
            </DialogHeader>

            {status === 'success' ? (
              <div>Invitasjonen ble sent!</div>
            ) : status === 'pending' ? (
              <MessageLoader />
            ) : (
              <React.Fragment>
                <form>
                  <TextField
                    id="firstName"
                    type="text"
                    label={Common_FirstName}
                    value={invitation.first_name}
                    onChange={(value) =>
                      setInvitation({ ...invitation, first_name: value })
                    }
                  />
                  <TextField
                    id="lastName"
                    type="text"
                    label={Common_LastName}
                    value={invitation.last_name}
                    onChange={(value) =>
                      setInvitation({ ...invitation, last_name: value })
                    }
                  />
                  <TextField
                    id="phoneNumber"
                    type="text"
                    label={Common_CellPhoneNumber}
                    value={invitation.phone_number ?? ''}
                    onChange={(value) =>
                      setInvitation({
                        ...invitation,
                        phone_number: value.trim(),
                      })
                    }
                  />
                  <TextField
                    id="email"
                    type="text"
                    label={Common_Email}
                    value={invitation.email}
                    onChange={(value) =>
                      setInvitation({
                        ...invitation,
                        email: value.toLowerCase().trim(),
                      })
                    }
                  />
                  {relations && (
                    <StyledSelect>
                      <label htmlFor="relation">
                        <FormattedMessage
                          id={Relations_Relation.id}
                          defaultMessage={Relations_Relation.defaultMessage}
                        />
                      </label>

                      <select
                        id="relation"
                        value={invitation.relation}
                        onChange={({ target: { value } }) =>
                          setInvitation({ ...invitation, relation: value })
                        }
                      >
                        <FormattedMessage
                          id={Relations_ChooseRelation.id}
                          defaultMessage={
                            Relations_ChooseRelation.defaultMessage
                          }
                        >
                          {(message) => (
                            <option value={undefined}>{message}</option>
                          )}
                        </FormattedMessage>

                        {relations.map((relation, i) => (
                          <option key={`r-${i}`} value={relation.key}>
                            {intl.formatMessage(relation.value)}
                          </option>
                        ))}
                      </select>
                    </StyledSelect>
                  )}
                  <FlexEnd style={{ marginTop: spacing.lg }}>
                    <Button
                      label={intl.formatMessage(Common_Add)}
                      ariaLabel={intl.formatMessage(Common_Add)}
                      variant="primary"
                      disabled={!invitationValid}
                      onClick={addInvitationToList}
                    />
                  </FlexEnd>
                </form>

                <h3>
                  <FormattedMessage
                    id={Invitations_Invitations.id}
                    defaultMessage={Invitations_Invitations.defaultMessage}
                  />
                </h3>
                <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                  {invitations.map((inv) => (
                    <li>
                      <InvitationItem
                        invitation={inv}
                        trailingElement={
                          <Button
                            label={intl.formatMessage(Common_Remove)}
                            ariaLabel={intl.formatMessage(Common_Remove)}
                            variant="dangerOutline"
                            isSmall={true}
                            onClick={() =>
                              setInvitations(
                                invitations.filter((i) => i.email !== inv.email)
                              )
                            }
                          />
                        }
                      />
                    </li>
                  ))}
                </ul>

                {invitations.length === 0 && (
                  <FormattedMessage
                    id={Invitations_NoInvitations.id}
                    defaultMessage={Invitations_NoInvitations.defaultMessage}
                  />
                )}

                {status === 'failure' && (
                  <div style={{ color: colors.error, marginTop: spacing.lg }}>
                    {intl.formatMessage(Common_ErrorOccurred)}
                  </div>
                )}

                <FlexEnd style={{ marginTop: spacing.lg }}>
                  <Button
                    label={intl.formatMessage(Invitations_SendInvitations, {
                      numInvitations: invitations.length,
                    })}
                    ariaLabel={intl.formatMessage(Invitations_SendInvitations, {
                      numInvitations: invitations.length,
                    })}
                    variant="primary"
                    disabled={invitations.length === 0}
                    onClick={sendInvitations}
                  />
                </FlexEnd>
              </React.Fragment>
            )}
          </DialogCard>
        </Dialog>
      )}
    </React.Fragment>
  ) : null
}

import React, { useState } from 'react'
import { Card } from '../../../shared/card/Card'
import { PageContainer } from '../../../shared/layout/Layout'
import {
  Common_CellPhoneNumber,
  Common_Email,
  Common_ErrorOccurred,
  Common_FirstName,
  Common_LastName,
  Common_Next,
  Common_PleaseWait,
  Register_CreateAccountTitle,
  Register_EmailInUse,
  Register_PhoneNumberInUse,
  Register_WithCountryCode,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { WizardContainer } from '../common/WizardContainer'
import illustration from '../../../../images/illustrations/young_and_old_2.svg'
import { colors, spacing } from '../../../../styles/styles'
import { useHistory, useParams } from 'react-router-dom'
import { TextField } from '../../../shared/form/TextField'
import { unauthenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { RequestStatus, TranslatedMessage } from '../../../../types'
import { Button } from '../../../shared/Buttons'

export const CreateAccountPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const [status, setStatus] = useState<RequestStatus>('idle')
  const [error, setError] = useState<TranslatedMessage | null>(null)
  const { role } = useParams<{ role: string }>()

  const [user, setUser] = useState<{
    first_name: string
    last_name: string
    cell_phone_number: string
    email: string | any
    is_relative: boolean
    is_employee: boolean
  }>({
    first_name: '',
    last_name: '',
    cell_phone_number: localStorage.getItem('register-phone')
      ? `+${localStorage.getItem('register-phone')}`
      : '+47',
    email: localStorage.getItem('register-email') ?? '',
    is_relative: role === 'relative',
    is_employee: role === 'employee',
  })

  const createAccount = () => {
    setStatus('pending')
    setError(null)

    unauthenticatedFetch(
      `${environment.API_URL}/users/register`,
      {
        method: 'POST',
      },
      user
    )
      .then(() => {
        setStatus('idle')
        history.push('/register/verify-info')
        localStorage.removeItem('register-email')
      })
      .catch((error: Error) => {
        // TODO: Temporary until error messages are improved on backend
        error.message === 'email already exists.'
          ? setError(Register_EmailInUse)
          : error.message === 'Phonenumber already in use'
          ? setError(Register_PhoneNumberInUse)
          : setError(Common_ErrorOccurred)

        setStatus('failure')
      })
  }

  const formIsValid =
    Boolean(user.first_name) &&
    Boolean(user.last_name) &&
    Boolean(user.cell_phone_number) &&
    Boolean(user.email)

  return (
    <PageContainer>
      <Card>
        <WizardContainer
          numSteps={6}
          currentStep={2}
          title={intl.formatMessage(Register_CreateAccountTitle)}
          illustration={illustration}
        >
          <form>
            <TextField
              id={Common_FirstName.defaultMessage}
              type="text"
              label={Common_FirstName}
              value={user.first_name}
              onChange={(value) => setUser({ ...user, first_name: value })}
            />

            <TextField
              id={Common_LastName.defaultMessage}
              type="text"
              label={Common_LastName}
              value={user.last_name}
              onChange={(value) => setUser({ ...user, last_name: value })}
            />

            <TextField
              id={Common_CellPhoneNumber.defaultMessage}
              type="text"
              label={Common_CellPhoneNumber}
              value={user.cell_phone_number}
              onChange={(value) =>
                setUser({ ...user, cell_phone_number: value })
              }
              helpText={Register_WithCountryCode}
            />

            <TextField
              id={Common_Email.defaultMessage}
              type="text"
              label={Common_Email}
              value={user.email}
              onChange={(value) =>
                setUser({ ...user, email: value.toLowerCase().trim() })
              }
            />

            {error && (
              <div style={{ color: colors.error }}>
                {intl.formatMessage(error)}
              </div>
            )}

            <div style={{ marginTop: spacing.lg, textAlign: 'center' }}>
              <Button
                variant="primary"
                label={
                  status === 'pending'
                    ? intl.formatMessage(Common_PleaseWait)
                    : intl.formatMessage(Common_Next)
                }
                ariaLabel={intl.formatMessage(Common_Next)}
                disabled={!formIsValid}
                onClick={createAccount}
              />
            </div>
          </form>
        </WizardContainer>
      </Card>
    </PageContainer>
  )
}

import { TranslatedMessage } from './types'
import {
  Common_Admin,
  Common_Book,
  Common_Event,
  Common_Groups,
  Common_Institutions,
  Common_Member,
  Common_Messages,
  Common_Profile,
  Common_Relations,
  Common_UserAccount,
  Common_VideoCalls,
  Permission_Create,
  Permission_Delete,
  Permission_Read,
  Permission_Update,
  Relation_ClosestRelative,
  Relation_ClosestRelativeWhereEmployed,
  Relation_ClosestRelativeWhereManager,
  Relation_ClosestRelativeWherePrimaryContact,
  Relation_Created,
  Relation_DataController,
  Relation_DataControllerWhereMember,
  Relation_EmployedAtEmployedAt,
  Relation_EmployedAtMember,
  Relation_EmployedAtMemberFamily,
  Relation_EmployedAtMemberPrimaryContact,
  Relation_EmployedAtParent,
  Relation_Employee,
  Relation_Family,
  Relation_Invited,
  Relation_Manager,
  Relation_ManagerAtParent,
  Relation_ManagerEmployedAt,
  Relation_ManagerMember,
  Relation_ManagerMemberFamily,
  Relation_MemberEmployedAt,
  Relation_MemberManager,
  Relation_Moderator,
  Relation_PrimaryContact,
  Relation_TeamMember,
  Relation_You,
} from './translations/messages'

export const relationsMap: { [key: string]: TranslatedMessage } = {
  CLOSEST_RELATIVE: Relation_ClosestRelative,
  FAMILY_CLOSEST_RELATIVE: Relation_ClosestRelative,
  CLOSEST_RELATIVE_FAMILY: Relation_ClosestRelative,
  MODERATOR: Relation_Moderator,
  TEAM_MEMBER_OWNER: Relation_TeamMember,
  MANAGER_MEMBER: Relation_ManagerMember,
  EMPLOYED_AT_MEMBER: Relation_EmployedAtMember,
  CREATED: Relation_Created,
  PRIMARY_CONTACT: Relation_PrimaryContact,
  FAMILY: Relation_Family,
  MEMBER_EMPLOYED_AT: Relation_MemberEmployedAt,
  MEMBER_MANAGER: Relation_MemberManager,
  EMPLOYEE: Relation_Employee,
  MANAGER_EMPLOYED_AT: Relation_ManagerEmployedAt,
  EMPLOYED_AT_EMPLOYED_AT: Relation_EmployedAtEmployedAt,
  MANAGER_MEMBER_FAMILY: Relation_ManagerMemberFamily,
  EMPLOYED_AT_MEMBER_FAMILY: Relation_EmployedAtMemberFamily,
  EMPLOYED_AT_MEMBER_PRIMARY_CONTACT: Relation_EmployedAtMemberPrimaryContact,
  SELF: Relation_You,
  DATA_CONTROLLER_MEMBER: Relation_DataControllerWhereMember,
  CLOSEST_RELATIVE_PRIMARY_CONTACT: Relation_PrimaryContact,
  CLOSEST_RELATIVE_MEMBER_EMPLOYED_AT: Relation_Employee,
  CLOSEST_RELATIVE_MEMBER_MANAGER: Relation_Manager,
  INVITED: Relation_Invited,
  MANAGER_MEMBER_CLOSEST_RELATIVE: Relation_ClosestRelativeWhereManager,
  PRIMARY_CONTACT_CLOSEST_RELATIVE: Relation_ClosestRelativeWherePrimaryContact,
  EMPLOYED_AT_MEMBER_CLOSEST_RELATIVE: Relation_ClosestRelativeWhereEmployed,
  MEMBER: Common_Member,
  ADMIN: Common_Admin,
  ADMIN_TEAM_MEMBER: Common_Admin,
  EMPLOYED_AT: Relation_Employee,
  CLOSEST_RELATIVE_MEMBER: Relation_ClosestRelative,
  FAMILY_MEMBER: Relation_Family,
  FAMILY_MEMBER_EMPLOYED_AT: Relation_Employee,
  FAMILY_MEMBER_MANAGER: Relation_Manager,
  TEAM_MEMBER: Relation_TeamMember,
  MANAGER_AT_PARENT: Relation_ManagerAtParent,
  EMPLOYED_AT_PARENT: Relation_EmployedAtParent,
  DATA_CONTROLLER: Relation_DataController,
  DATA_CONTROLLER_EMPLOYED_AT: Relation_DataController,
}

export const entityMap: { [key: string]: TranslatedMessage } = {
  Event: Common_Event,
  Post: Common_Book,
  Message: Common_Messages,
  User: Common_UserAccount,
  UserProfile: Common_Profile,
  Relation: Common_Relations,
  Group: Common_Groups,
  Institution: Common_Institutions,
  VideoCall: Common_VideoCalls,
}

export const permissionsMap: { [key: string]: TranslatedMessage } = {
  READ: Permission_Read,
  CREATE: Permission_Create,
  UPDATE: Permission_Update,
  DELETE: Permission_Delete,
}

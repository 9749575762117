import { TabItem, Tabs } from './Tabs'
import {
  faBars,
  faBookOpen,
  faEnvelope,
  faUserFriends,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useMessages } from '../contexts/MessageProvider'
import { useIntl } from 'react-intl'
import {
  Common_Book,
  Common_Groups,
  Common_Messages,
  Common_More,
  Common_ServiceReceivers,
} from '../translations/messages'
import { useContentToModerate } from '../contexts/ModeratorContext'

export const MultipleServiceReceiversTabs = () => {
  const intl = useIntl()
  const { unreadMessages } = useMessages()
  const { contentToModerate } = useContentToModerate()
  return (
    <Tabs>
      <TabItem
        ariaLabel={intl.formatMessage(Common_Book)}
        to={`/feed`}
        icon={faBookOpen}
      />
      <TabItem
        ariaLabel={intl.formatMessage(Common_ServiceReceivers)}
        to={`/service-receivers`}
        icon={faUserFriends}
      />
      <TabItem
        ariaLabel={intl.formatMessage(Common_Groups)}
        to={`/groups`}
        icon={faUsers}
      />
      <TabItem
        ariaLabel={intl.formatMessage(Common_Messages)}
        to={`/messages`}
        icon={faEnvelope}
        badge={unreadMessages}
      />
      <TabItem
        ariaLabel={intl.formatMessage(Common_More)}
        to={`/more`}
        icon={faBars}
        badge={contentToModerate.length}
      />
    </Tabs>
  )
}

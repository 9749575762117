import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Card } from '../card/Card'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { InvitationItem } from './InvitationItem'
import { Button } from '../Buttons'
import { smallScreenBreakpoint, spacing } from '../../../styles/styles'
import { ConfirmDialog } from '../modal/ConfirmDialog'
import { MessageLoader } from '../Loading'
import { ErrorMessage } from '../Messages'
import { AuthContext } from '../../../contexts/AuthContext'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons'
import {
  Common_Accept,
  Common_Delete,
  Common_ErrorOccurred,
  Invitations_ConfirmDelete,
  Invitations_NoInvitations,
} from '../../../translations/messages'
import { Invitation } from '../../../types'
import { useIntl } from 'react-intl'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacing.md};

  button:not(:last-child) {
    margin-right: ${spacing.md};
  }

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    margin-top: 0;
    margin-left: ${spacing.md};
  }
`

type Props = {
  id?: string
}

export const InvitationList: FunctionComponent<Props> = ({ id }) => {
  const intl = useIntl()
  const { authUser } = useContext(AuthContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const [activeInvitation, setActiveInvitation] = useState<Invitation | null>(
    null
  )
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<
    boolean
  >(false)

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < smallScreenBreakpoint
  )

  const onResize = useCallback(() => {
    setIsSmallScreen(window.innerWidth < smallScreenBreakpoint)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  useEffect(() => {
    const url = id
      ? `${environment.API_URL}/invitations/${id}`
      : `${environment.API_URL}/invitations`

    authenticatedFetch(url)
      .then((response) => {
        setStatus('idle')
        setInvitations(response)
      })
      .catch(() => setStatus('failure'))
  }, [id])

  const acceptInvitation = (invitation: Invitation) => {
    setActiveInvitation(invitation)

    authenticatedFetch(
      `${environment.API_URL}/invitations/accept`,
      {
        method: 'POST',
      },
      { invitation_id: invitation.id }
    )
      .then(() => {
        setInvitations(invitations.filter((i) => i.id !== invitation.id))
        setActiveInvitation(null)
        window.location.reload()
      })
      .catch(() => {
        setActiveInvitation(null)
        setStatus('failure')
      })
  }

  const deleteInvitation = () => {
    setShowConfirmDeleteDialog(false)

    authenticatedFetch(
      `${environment.API_URL}/invitations/${activeInvitation?.id}`,
      { method: 'DELETE' }
    )
      .then(() => {
        setInvitations(invitations.filter((i) => i.id !== activeInvitation?.id))
        setActiveInvitation(null)
      })
      .catch(() => {
        setStatus('failure')
        setActiveInvitation(null)
      })
  }

  const cancel = () => {
    setShowConfirmDeleteDialog(false)
    setActiveInvitation(null)
  }

  return (
    <Card style={{ padding: spacing.md, marginTop: spacing.sm }}>
      <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
        {invitations.map((invitation, i) =>
          invitation.id === activeInvitation?.id ? (
            <MessageLoader />
          ) : (
            <li key={`inv-${i}`}>
              <InvitationItem
                invitation={invitation}
                trailingElement={
                  <ButtonGroup>
                    {authUser?.email === invitation.email && (
                      <Button
                        label={intl.formatMessage(Common_Accept)}
                        ariaLabel={intl.formatMessage(Common_Accept)}
                        variant="primary"
                        isSmall={true}
                        onClick={() => acceptInvitation(invitation)}
                        smallScreenIcon={<FontAwesomeIcon icon={faCheck} />}
                      />
                    )}
                    <Button
                      label={intl.formatMessage(Common_Delete)}
                      ariaLabel={intl.formatMessage(Common_Delete)}
                      smallScreenIcon={<FontAwesomeIcon icon={faTrash} />}
                      variant="danger"
                      isXSmall={isSmallScreen}
                      isSmall={!isSmallScreen}
                      onClick={() => {
                        setActiveInvitation(invitation)
                        setShowConfirmDeleteDialog(true)
                      }}
                    />
                  </ButtonGroup>
                }
              />
            </li>
          )
        )}

        {invitations.length === 0 && (
          <div>{intl.formatMessage(Invitations_NoInvitations)}</div>
        )}

        {status === 'failure' && (
          <ErrorMessage>
            {intl.formatMessage(Common_ErrorOccurred)}
          </ErrorMessage>
        )}
      </ul>

      <ConfirmDialog
        show={showConfirmDeleteDialog}
        title={Invitations_ConfirmDelete}
        onClose={cancel}
        onConfirm={deleteInvitation}
        onCancel={cancel}
      />
    </Card>
  )
}

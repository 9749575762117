import React from 'react'
import { Card } from '../../../shared/card/Card'
import { PageContainer } from '../../../shared/layout/Layout'
import {
  Common_ContactUs,
  Common_Or,
  ContactUs_CallUsAt,
  ContactUs_Description,
  ContactUs_SendEmailTo,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { WizardContainer } from '../common/WizardContainer'
import illustration from '../../../../images/illustrations/young_and_old_2.svg'
import { H1 } from '../../../shared/Typography'

export const ContactUsPage = () => {
  const intl = useIntl()

  return (
    <PageContainer>
      <Card>
        <WizardContainer illustration={illustration}>
          <div style={{ textAlign: 'center' }}>
            <H1>{intl.formatMessage(Common_ContactUs)}</H1>
            <p>{intl.formatMessage(ContactUs_Description)}</p>

            <p>
              {intl.formatMessage(ContactUs_SendEmailTo, {
                email: 'post@jodacare.com',
              })}
            </p>

            <p>{intl.formatMessage(Common_Or)}</p>

            <p>
              {intl.formatMessage(ContactUs_CallUsAt, {
                phone: '90606433',
              })}
            </p>
          </div>
        </WizardContainer>
      </Card>
    </PageContainer>
  )
}

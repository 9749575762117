import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../shared/layout/Layout'
import { NavBarContext } from '../../../contexts/NavBarContext'
import { usePosts } from '../../../contexts/PostProvider'
import { PostCard } from '../../shared/post/PostCard'
import { PostLoader } from '../../shared/Loading'
import { CommentList } from '../../shared/comments/CommentList'
import styled from 'styled-components'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'

const Container = styled(PageContainer)`
  margin-left: auto;
  margin-right: auto;
  max-width: 37.5rem;
`

export const PostDetailPage = () => {
  const { id } = useParams<{ id: string }>()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const { allPosts, updatePostState } = usePosts()
  const post = (allPosts && allPosts.find((p) => p.id === id)) || null

  updateNavBarTitle('Innlegg')

  useEffect(() => {
    if (post === null) {
      authenticatedFetch(`${environment.API_URL}/posts/${id}`)
        .then((post) => {
          updatePostState(post)
        })
        .catch(() => {})
    }
  }, [id, post, updatePostState])

  return (
    <Container>
      {post ? (
        <React.Fragment>
          <PostCard data={post} />
          <CommentList parentId={post.id} />
        </React.Fragment>
      ) : (
        <PostLoader />
      )}
    </Container>
  )
}

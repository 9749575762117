import React, { useState } from 'react'
import {
  breakpoints,
  colors,
  smallScreenBreakpoint,
  spacing,
} from '../../../../styles/styles'
import styled from 'styled-components'
import { JodacareLogo } from '../../../shared/images/JodacareLogo'
import { NavLink } from 'react-router-dom'
import {
  Common_Register,
  Common_ContactUs,
  Login_Login,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { Burger, Menu } from '../../../shared/BurgerMenu'

const Container = styled.div`
  position: relative;
  padding: 0 ${spacing.md};
  margin: 0 auto;
  max-width: ${breakpoints.XLARGE};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: ${smallScreenBreakpoint}) {
    padding: 0;
  }
`

const NavBar = styled.div`
  position: fixed;
  width: 100%;
  height: 64px;
  background: white;
  z-index: 2;
  border-bottom: 1px solid ${colors.black5};
`

const NavBarItem = styled(NavLink)`
  position: relative;
  text-transform: uppercase;
  margin-left: ${spacing.lg};

  &:link,
  &:visited {
    color: ${colors.joda};
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid white;
    transition: all 0.25s ease-out;
  }

  &.active,
  &:hover {
    border-color: ${colors.jodaDark};
  }
`

export const LoginNavBar = () => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  return (
    <NavBar>
      <Container>
        <JodacareLogo style={{ height: '35px' }} />
        <div>
          {window.screen.width > parseInt(breakpoints.SMALL) ? (
            <React.Fragment>
              <NavBarItem to="/login" activeClassName="active">
                {intl.formatMessage(Login_Login)}
              </NavBarItem>

              <NavBarItem to="/register" activeClassName="active">
                {intl.formatMessage(Common_Register)}
              </NavBarItem>

              <NavBarItem to="/contact" activeClassName="active">
                {intl.formatMessage(Common_ContactUs)}
              </NavBarItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Burger open={open} setOpen={setOpen} />
              <Menu open={open} setOpen={setOpen} />
            </React.Fragment>
          )}
        </div>
      </Container>
    </NavBar>
  )
}

import { TabItem, Tabs } from './Tabs'
import {
  faBars,
  faBookOpen,
  faCalendar,
  faEnvelope,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import React, { FunctionComponent } from 'react'
import { useMessages } from '../contexts/MessageProvider'
import {
  Common_Book,
  Common_Calendar,
  Common_Messages,
  Common_More,
  Common_Profile,
} from '../translations/messages'
import { useIntl } from 'react-intl'

type Props = {
  serviceReceiverId: string
}

export const SingleServiceReceiverTabs: FunctionComponent<Props> = ({
  serviceReceiverId,
}) => {
  const intl = useIntl()
  const { unreadMessages } = useMessages()

  return (
    <Tabs>
      <TabItem
        ariaLabel={intl.formatMessage(Common_Book)}
        to="/feed"
        icon={faBookOpen}
      />
      <TabItem
        ariaLabel={intl.formatMessage(Common_Calendar)}
        to="/calendar"
        icon={faCalendar}
      />
      <TabItem
        ariaLabel={intl.formatMessage(Common_Profile)}
        to={`/profile/${serviceReceiverId}`}
        icon={faUser}
      />
      <TabItem
        ariaLabel={intl.formatMessage(Common_Messages)}
        to="/messages"
        icon={faEnvelope}
        badge={unreadMessages}
      />
      <TabItem
        ariaLabel={intl.formatMessage(Common_More)}
        to="/more"
        icon={faBars}
      />
    </Tabs>
  )
}

import React, { FunctionComponent } from 'react'
import { Card } from '../../../shared/card/Card'
import styled from 'styled-components'
import { colors, spacing } from '../../../../styles/styles'
import { useHistory } from 'react-router-dom'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { ListAvatarIcon } from '../../../shared/list/ListAvatarIcon'
import { Common_NumMembers } from '../../../../translations/messages'
import { useIntl } from 'react-intl'

const ListItem = styled(Card)`
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  border-bottom: 1px solid ${colors.black5};
`

type Props = {
  data: any
}

export const GroupListItem: FunctionComponent<Props> = ({ data }) => {
  const history = useHistory()
  const intl = useIntl()

  const { id, name, num_members } = data

  return (
    <li>
      <ListItem onClick={() => history.push(`/groups/${id}`)}>
        <ListAvatarIcon icon={faUsers} />
        <div>
          <div>{name}</div>
          <small>
            {intl.formatMessage(Common_NumMembers, { numMembers: num_members })}
          </small>
        </div>
      </ListItem>
    </li>
  )
}

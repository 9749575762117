import React, { FunctionComponent, useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { Card } from './card/Card'
import { Avatar } from './Avatar'
import { colors, spacing } from '../../styles/styles'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { Common_Greeting } from '../../translations/messages'
import { TranslatedMessage } from '../../types'

const FeedItemCard = styled(Card)`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.md};
  padding: ${spacing.md};

  &:hover {
    cursor: pointer;
  }
`

const CardBody = styled.div`
  width: 100%;
  margin-left: ${spacing.md};
`

const ShareText = styled.div`
  border-bottom: 1px solid ${colors.black80};
`

type Props = {
  label: TranslatedMessage
  onClick: () => void
}

export const FeedHeaderItem: FunctionComponent<Props> = ({
  label,
  onClick,
}) => {
  const intl = useIntl()
  const { authUser } = useContext(AuthContext)

  return (
    <FeedItemCard onClick={onClick}>
      <Avatar
        image={authUser?.image}
        size="small"
        alt={`${authUser?.first_name} ${authUser?.last_name}`}
      />
      <CardBody>
        {intl.formatMessage(Common_Greeting, {
          name: authUser?.first_name,
        })}
        <ShareText style={{ fontSize: '14px', color: '#FFC0CB' }}>
          {intl.formatMessage(label)}
        </ShareText>
      </CardBody>
    </FeedItemCard>
  )
}

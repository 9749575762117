import React from 'react'
import { PostFeed } from '../shared/post/PostFeed'
import { PageContainer } from '../shared/layout/Layout'
import { NavButton } from '../shared/NavButton'
import { Common_New } from '../../translations/messages'

export const MainFeed = () => {
  return (
    <React.Fragment>
      <NavButton
        path="/posts/create"
        label={Common_New}
        state={{
          ownerId: null,
        }}
      />
      <PageContainer>
        <PostFeed canCreate={true} />
      </PageContainer>
    </React.Fragment>
  )
}

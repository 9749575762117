import React, { FunctionComponent, ReactNode, useState } from 'react'
import { Button } from './Buttons'
import { SearchField } from './SearchField'
import { UserListItem } from './list'
import { authenticatedFetch } from '../../lib/service'
import { environment } from '../../environments'
import styled from 'styled-components'
import {
  breakpoints,
  smallScreenBreakpoint,
  spacing,
} from '../../styles/styles'
import { Dialog } from './modal/Dialog'
import { Card } from './card/Card'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Common_Cancel,
  Common_Save,
  Common_SelectedUsers,
  Users_SearchPlaceholder,
} from '../../translations/messages'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DialogCard = styled(Card)`
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: ${spacing.lg};
  width: auto;
  height: auto;
  max-height: 80%;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Selected = styled.div`
  margin: ${spacing.lg} 0;
`

const SheetWrapper = styled.div`
  padding: ${spacing.lg};

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    margin-left: auto;
    margin-right: auto;
    max-width: ${breakpoints.SMALL};
  }

  ul {
    li {
      padding-left: ${spacing.sm};
    }
  }
`

const ButtonGroup = styled.div`
  margin-top: ${spacing.lg};
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    margin-left: auto;
    margin-right: auto;
    max-width: ${breakpoints.SMALL};
  }

  button {
    width: auto;
  }

  button:not(:last-child) {
    margin-right: ${spacing.sm};
  }

  button:last-child {
    margin-left: ${spacing.sm};
  }
`

const noOp = () => {}

type Props = {
  title: ReactNode
  show: boolean
  onChange: (users: any[]) => void
  onDismiss: () => void
  filter?: (user: any) => any
}

export const SearchAndAddToList: FunctionComponent<Props> = ({
  title,
  show,
  onChange,
  onDismiss,
  filter,
}) => {
  const intl = useIntl()
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])
  const [searchStatus, setSearchStatus] = useState<
    'idle' | 'pending' | 'failure'
  >('idle')
  const [searchResults, setSearchResults] = useState<any[]>([])

  const searchUsers = (term: string) => {
    if (term) {
      setSearchStatus('pending')
      authenticatedFetch(`${environment.API_URL}/users/search?term=${term}`)
        .then(({ results }) => {
          const filtered = filter ? results.filter(filter) : results
          setSearchResults(filtered)
          setSearchStatus('idle')
        })
        .catch(() => {
          setSearchStatus('failure')
        })
    } else {
      setSearchResults([])
      setSearchStatus('idle')
    }
  }

  const cancel = () => {
    setSelectedUsers([])
    onDismiss()
  }

  return show ? (
    <Dialog onClose={onDismiss}>
      <DialogCard>
        <SheetWrapper>
          <h1>{title}</h1>
          <SearchField
            searching={searchStatus === 'pending'}
            placeholder={Users_SearchPlaceholder}
            onChange={(term) => searchUsers(term)}
            results={searchResults}
            itemSelected={(item) => {
              if (selectedUsers.findIndex((u) => u.id === item.id) === -1) {
                setSelectedUsers([...selectedUsers, item])
              }
              setSearchResults([])
            }}
            ListItemComponent={UserListItem}
          />

          {selectedUsers.length > 0 && (
            <Selected>
              <h3>
                <FormattedMessage
                  id={Common_SelectedUsers.id}
                  defaultMessage={Common_SelectedUsers.defaultMessage}
                />
                :
              </h3>
              <List aria-label="selectedUsers">
                {selectedUsers.map((user) => (
                  <UserListItem
                    data={user}
                    onClick={noOp}
                    trailingElement={
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        onClick={() => {
                          setSelectedUsers(
                            selectedUsers.filter((u) => u.id !== user.id)
                          )
                        }}
                      />
                    }
                  />
                ))}
              </List>
            </Selected>
          )}

          <ButtonGroup>
            <Button
              label={intl.formatMessage(Common_Cancel)}
              ariaLabel={intl.formatMessage(Common_Cancel)}
              variant="secondary"
              onClick={cancel}
              isSmall={true}
            />
            <Button
              label={intl.formatMessage(Common_Save)}
              ariaLabel={intl.formatMessage(Common_Save)}
              disabled={selectedUsers.length === 0}
              variant="primary"
              isSmall={true}
              onClick={() => {
                onChange(selectedUsers)
                setSelectedUsers([])
              }}
            />
          </ButtonGroup>
        </SheetWrapper>
      </DialogCard>
    </Dialog>
  ) : null
}

function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180
}
export async function getCroppedImg(
  imageSrc: any,
  pixelCrop: any,
  rotation = 0,
  imageFileName?: string
) {
  const image: any = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx: any = canvas.getContext('2d')
  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
  canvas.width = safeArea
  canvas.height = safeArea
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  )
  return new Promise((resolve) => {
    canvas.toBlob((file: any) => {
      const ext = imageFileName ? imageFileName.split('.')[1] : ''
      file.name = imageFileName
        ? imageFileName.replace(`.${ext}`, '_cropped') + '.jpeg'
        : ''
      resolve(file)
    }, 'image/jpeg')
  })
}
const createImage = (url: any) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = url
  })

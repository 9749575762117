import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../../styles/styles'
import { Avatar } from '../Avatar'

const ListItem = styled.li`
  border-radius: ${borderRadius};
  background: ${colors.white};
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  border: none;
  width: 100%;
  font-size: inherit;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.black5};
  }
`

const ListItemContent = styled.div`
  flex: 1;
  text-align: left;
`

type Props = {
  data: any
  onClick: (item: any) => void
  trailingItem?: ReactNode
}

export const MemberListItem: FunctionComponent<Props> = ({
  data,
  onClick,
  trailingItem,
}) => {
  return (
    <ListItem onClick={() => onClick(data)}>
      <Avatar
        size="small"
        image={data.image}
        alt={`Bilde av ${data.first_name}`}
        style={{ marginRight: spacing.md }}
      />
      <ListItemContent>
        <div>
          {data.name ? data.name : `${data.first_name} ${data.last_name}`}
        </div>
        <small>{data.email}</small>
      </ListItemContent>
      {trailingItem && trailingItem}
    </ListItem>
  )
}

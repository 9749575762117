import React, { FunctionComponent, useEffect, useState } from 'react'
import { Button } from '../../../../shared/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { Card } from '../../../../shared/card/Card'
import { spacing } from '../../../../../styles/styles'
import { useHistory } from 'react-router-dom'
import { Common_Show } from '../../../../../translations/messages'
import { useIntl } from 'react-intl'
import { authenticatedFetch } from '../../../../../lib/service'
import { environment } from '../../../../../environments'
import { RequestStatus } from '../../../../../types'
import { MessageLoader } from '../../../../shared/Loading'

const InstitutionCard = styled(Card)`
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  gap: ${spacing.md};

  span {
    flex: 1;
  }
`

type HierarchyInstitution = {
  id: string
  name: string
  total_child_count: number
  users: any[]
}

type Props = {
  institution: HierarchyInstitution
}

export const InstitutionHierarchyListItem: FunctionComponent<Props> = ({
  institution,
}) => {
  const intl = useIntl()
  const history = useHistory()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [status, setStatus] = useState<RequestStatus>('idle')
  const [children, setChildren] = useState<HierarchyInstitution[]>([])

  useEffect(() => {
    if (isOpen) {
      setStatus('pending')

      authenticatedFetch(
        `${environment.API_URL}/institutions/hierarchy/${institution.id}`
      )
        .then(({ children }) => {
          setStatus('idle')
          setChildren(children)
        })
        .catch(() => {
          setStatus('failure')
        })
    }
  }, [isOpen, institution.id])

  return (
    <React.Fragment>
      <InstitutionCard>
        <Button
          ariaLabel="Toggle show child institutions"
          label={
            isOpen ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )
          }
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          isSmall
          variant="secondary"
          disabled={institution.total_child_count === 0}
        />

        <span>{institution.name}</span>

        <Button
          ariaLabel={intl.formatMessage(Common_Show)}
          label={intl.formatMessage(Common_Show)}
          onClick={() => history.push(`/institutions/${institution.id}`)}
          isSmall
          variant="secondary"
        />
      </InstitutionCard>

      {institution.total_child_count > 0 &&
        isOpen &&
        (status === 'pending' ? (
          <MessageLoader />
        ) : (
          <ul>
            {children.map((child: any) => (
              <InstitutionHierarchyListItem
                key={`c-${child.id}`}
                institution={child}
              />
            ))}
          </ul>
        ))}
    </React.Fragment>
  )
}

import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react'
import { CalendarEvent } from '../types'
import { authenticatedFetch } from '../lib/service'
import { environment } from '../environments'

type CalendarCtx = {
  events: CalendarEvent[] | undefined
  setEvents: Dispatch<CalendarEvent[]>
}

const CalendarContext = createContext<CalendarCtx>({
  events: undefined,
  setEvents: () => {},
})

export const CalendarProvider: FunctionComponent = ({ children }) => {
  const [events, setEvents] = useState<CalendarEvent[]>([])

  return (
    <CalendarContext.Provider value={{ events, setEvents }}>
      {children}
    </CalendarContext.Provider>
  )
}

export const useCalendar = (ownerId: string, dateRange: any[]) => {
  const { events, setEvents } = useContext(CalendarContext)

  if (events === undefined) {
    throw new Error('useCalendar must be wrapped in a CalendarProvider')
  }

  const fetchEvents = useCallback(() => {
    const start = dateRange[0].toString()
    const end = dateRange[dateRange.length - 1].toString()

    return authenticatedFetch(
      `${environment.API_URL}/events/owner/${ownerId}?start=${start}&end=${end}`
    ).then(({ results }) => setEvents(results))
  }, [ownerId, dateRange, setEvents])

  const deleteEvent = (eventId: string) => {
    return authenticatedFetch(`${environment.API_URL}/events/${eventId}`, {
      method: 'DELETE',
    }).then(() => {
      const newState = events.filter((e) => e.id !== eventId)
      setEvents(newState)
    })
  }

  const updateOccurrence = (data: any) => {
    const url = data.id
      ? `${environment.API_URL}/events/${data.event_id}/occurrences/${data.id}`
      : `${environment.API_URL}/events/${data.event_id}/occurrences`

    const method = data.id ? 'PATCH' : 'POST'

    return authenticatedFetch(url, { method }, data).then(fetchEvents)
  }

  return { fetchEvents, events, deleteEvent, updateOccurrence }
}

type Entity =
  | 'Event'
  | 'Group'
  | 'Institution'
  | 'Message'
  | 'Post'
  | 'Relation'
  | 'Team'
  | 'User'
  | 'UserProfile'
  | 'VideoCall'
  | 'Member'
  | 'EmployedAt'

type Permission = 'READ' | 'CREATE' | 'UPDATE' | 'DELETE'

export const hasPermission = (
  relations: any,
  entity: Entity,
  permission: Permission
) => {
  if (relations) {
    for (let i = 0; i < relations.length; i++) {
      const relation = relations[i]
      const relationIncludesPermission =
        relation.permissions[entity] &&
        relation.permissions[entity].indexOf(permission) !== -1

      if (relationIncludesPermission) {
        return true
      }
    }
  }

  return false
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { SearchField } from '../../../shared/SearchField'
import { unauthenticatedFetch } from '../../../../lib/service'
import { Box } from '../../../shared/Box'
import { LinkButton } from '../../../shared/Buttons'
import { spacing } from '../../../../styles/styles'
import styled from 'styled-components'
import { H2 } from '../../../shared/Typography'
import { environment } from '../../../../environments'
import { ErrorMessage } from '../../../shared/Messages'
import { FadeIn } from '../../../shared/animations/FadeIn'
import {
  Common_ErrorOccurred,
  Login_SearchMunicipality,
  Login_ChangeMunicipality,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'

const SelectedBox = styled(Box)`
  margin-bottom: ${spacing.lg};
  padding: ${spacing.lg};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

type Props = {
  onChange: (municipality: any) => void
}

export const SelectMunicipality: FunctionComponent<Props> = ({ onChange }) => {
  const intl = useIntl()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [selectedMunicipality, setSelectedMunicipality] = useState<any>(
    undefined
  )
  const [municipalities, setMunicipalities] = useState<any[]>([])
  const [searchTerm, setSearchTerm] = useState<any>('')

  const onSelect = (municipality: any) => {
    setSelectedMunicipality(municipality)
    onChange(municipality)
  }

  useEffect(() => {
    if (searchTerm.length > 2) {
      setStatus('pending')

      unauthenticatedFetch(
        `${environment.API_URL}/municipalities/search?name=${searchTerm}`
      )
        .then((data) => {
          setStatus('idle')
          setMunicipalities(data)
        })
        .catch(() => setStatus('failure'))
    }
  }, [searchTerm])

  if (selectedMunicipality) {
    return (
      <SelectedBox>
        <FadeIn showOn={true}>
          <H2 style={{ marginBottom: spacing.md }}>
            {selectedMunicipality.name}
          </H2>
          <LinkButton
            onClick={() => {
              setSelectedMunicipality(undefined)
              setMunicipalities([])
              onChange(undefined)
            }}
          >
            {intl.formatMessage(Login_ChangeMunicipality)}
          </LinkButton>
        </FadeIn>
      </SelectedBox>
    )
  }

  return (
    <React.Fragment>
      <SearchField
        searching={status === 'pending'}
        placeholder={Login_SearchMunicipality}
        onChange={(term) => setSearchTerm(term)}
        results={municipalities}
        itemSelected={(municipality) => onSelect(municipality)}
      />
      {status === 'failure' && (
        <ErrorMessage>{intl.formatMessage(Common_ErrorOccurred)}</ErrorMessage>
      )}
    </React.Fragment>
  )
}

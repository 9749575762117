import React from 'react'
import { Card } from './card/Card'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../styles/styles'
import { Common_ModeratedContent } from '../../translations/messages'
import { useIntl } from 'react-intl'

const StyledCard = styled(Card)`
  margin-bottom: ${spacing.md};
  padding: ${spacing.md};
`

const InnerContainer = styled.div`
  padding: ${spacing.md};
  border-radius: ${borderRadius};
  background: ${colors.trustLight};
`

export const ModeratedContendCard = () => {
  const intl = useIntl()

  return (
    <StyledCard>
      <InnerContainer>
        {intl.formatMessage(Common_ModeratedContent)}
      </InnerContainer>
    </StyledCard>
  )
}

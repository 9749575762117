import React, { FunctionComponent } from 'react'
import { Card } from '../card/Card'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'

const ListItem = styled(Card)`
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  border-bottom: 1px solid ${colors.black5};
`

type Props = {
  data: any
}

export const GeneralListItem: FunctionComponent<Props> = ({ data }) => {
  return (
    <ListItem>
      <div>
        <div>{data.name}</div>
      </div>
    </ListItem>
  )
}

import React from 'react'
import { Card } from '../../../shared/card/Card'
import { PageContainer } from '../../../shared/layout/Layout'
import {
  Common_ReadMore,
  Common_Register,
  Register_Description,
  Register_Title,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { WizardContainer } from '../common/WizardContainer'
import illustration from '../../../../images/illustrations/woman_cell_phone_blue.svg'
import { H1 } from '../../../shared/Typography'
import { NextButton } from '../common/NextButton'
import styled from 'styled-components'
import { colors, spacing } from '../../../../styles/styles'
import { NavLink, useHistory } from 'react-router-dom'

const Link = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid ${colors.close};
`

export const RegisterWelcomePage = () => {
  const intl = useIntl()
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search)
  const email = queryParams.get('email')
  const phoneNumber = queryParams.get('phone-number')
  localStorage.removeItem('register-email')
  localStorage.removeItem('register-phone')
  if (email && email !== undefined) {
    localStorage.setItem('register-email', email)
  }
  if (phoneNumber && phoneNumber !== undefined) {
    localStorage.setItem('register-phone', phoneNumber)
  }
  return (
    <PageContainer>
      <Card>
        <WizardContainer illustration={illustration}>
          <div style={{ textAlign: 'center' }}>
            <H1>{intl.formatMessage(Register_Title)}</H1>
            <p>{intl.formatMessage(Register_Description)}</p>

            <Link to="/eula">{intl.formatMessage(Common_ReadMore)}</Link>

            <div style={{ marginTop: spacing.lg }}>
              <NextButton
                label={intl.formatMessage(Common_Register)}
                onClick={() => history.push('/register/select-role')}
              />
            </div>
          </div>
        </WizardContainer>
      </Card>
    </PageContainer>
  )
}

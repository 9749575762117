import React, { FormEvent, FunctionComponent, useState } from 'react'
import { CardItem } from '../../../shared/card/Card'
import { Button } from '../../../shared/Buttons'
import { ErrorMessage } from '../../../shared/Messages'
import { ConfirmDialog } from '../../../shared/modal/ConfirmDialog'
import { TextField } from '../../../shared/form/TextField'
import styled from 'styled-components'
import { smallScreenBreakpoint, spacing } from '../../../../styles/styles'
import { MessageLoader } from '../../../shared/Loading'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import {
  Common_Activate,
  Common_Active,
  Common_Cancel,
  Common_Deactivate,
  Common_Edit,
  Common_Email,
  Common_ErrorOccurred,
  Common_Inactive,
  Common_Password,
  Common_Save,
  Jodabook_ConfirmUpdate,
  Jodabook_TurnOff,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ButtonGroup = styled.div`
  margin-top: ${spacing.md};

  button {
    width: 100%;
  }

  button:last-child {
    margin-top: ${spacing.md};
  }

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    display: flex;
    justify-content: flex-end;

    button {
      width: auto;
    }

    button:last-child {
      margin-top: 0;
      margin-left: ${spacing.md};
    }
  }
`

const InlineButtonGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  span {
    flex: 1;
  }

  button:last-child {
    margin-left: ${spacing.md};
  }
`

type Props = {
  serviceReceiver: any
  updateJodabook: (data: {
    email?: string
    using_jodabook: boolean
  }) => Promise<void>
}

export const JodabookSetup: FunctionComponent<Props> = ({
  serviceReceiver,
  updateJodabook,
}) => {
  const intl = useIntl()
  const [showJodabookForm, setShowJodabookForm] = useState<boolean>(false)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [email, setEmail] = useState<any>(serviceReceiver.email)
  const [newPassword, setNewPassword] = useState<any>('')
  const [showConfirmSetup, setShowConfirmSetup] = useState<boolean>(false)
  const [showConfirmDisable, setShowConfirmDisable] = useState<boolean>(false)

  const canUpdate = true

  const onUpdateJodabook = (event: FormEvent) => {
    event.preventDefault()
    setShowConfirmSetup(true)
    setShowJodabookForm(false)
  }

  const disableJodabook = () => {
    setStatus('pending')
    setShowConfirmDisable(false)

    updateJodabook({ using_jodabook: false })
      .then(() => setStatus('idle'))
      .catch(() => setStatus('failure'))
  }

  const updatePassword = (password: string) =>
    authenticatedFetch(
      `${environment.API_URL}/users/${serviceReceiver.id}/password`,
      { method: 'PATCH' },
      { password }
    )

  const confirmSetUpJodabookAccount = () => {
    setStatus('pending')
    setShowConfirmSetup(false)

    let promises = [updateJodabook({ email, using_jodabook: true })]

    // Only update password if changed
    if (newPassword !== '') {
      promises = [...promises, updatePassword(newPassword)]
    }

    Promise.all(promises)
      .then(() => setStatus('idle'))
      .catch(() => setStatus('failure'))
  }

  return (
    <React.Fragment>
      <h3 id="jodabookTitle" style={{ marginBottom: spacing.sm }}>
        Jodabook
      </h3>
      <CardItem aria-labelledby="jodabookTitle">
        {status === 'pending' ? (
          <MessageLoader />
        ) : showJodabookForm ? (
          <Form onSubmit={onUpdateJodabook}>
            <TextField
              id="email"
              type="email"
              label={Common_Email}
              value={email}
              onChange={(newValue) => setEmail(newValue)}
            />
            <TextField
              id="password"
              type="text"
              label={Common_Password}
              value={newPassword}
              onChange={(newValue) => setNewPassword(newValue)}
            />
            <ButtonGroup>
              <Button
                label={intl.formatMessage(Common_Cancel)}
                ariaLabel={intl.formatMessage(Common_Cancel)}
                onClick={() => setShowJodabookForm(false)}
                isSmall={true}
                variant="secondary"
              />
              <Button
                type="submit"
                label={intl.formatMessage(Common_Save)}
                ariaLabel={intl.formatMessage(Common_Save)}
                isSmall={true}
                variant="primary"
                disabled={!email}
              />
            </ButtonGroup>
          </Form>
        ) : (
          <InlineButtonGroup>
            {serviceReceiver.using_jodabook ? (
              <React.Fragment>
                <span>{intl.formatMessage(Common_Active)}</span>
                <Button
                  label={intl.formatMessage(Common_Deactivate)}
                  ariaLabel={intl.formatMessage(Common_Deactivate)}
                  variant="danger"
                  isSmall={true}
                  onClick={() => setShowConfirmDisable(true)}
                />
              </React.Fragment>
            ) : (
              <span>{intl.formatMessage(Common_Inactive)}</span>
            )}
            {canUpdate && (
              <Button
                label={intl.formatMessage(
                  serviceReceiver.using_jodabook ? Common_Edit : Common_Activate
                )}
                ariaLabel={intl.formatMessage(
                  serviceReceiver.using_jodabook ? Common_Edit : Common_Activate
                )}
                onClick={() => setShowJodabookForm(true)}
                isSmall={true}
                variant="primary"
              />
            )}
          </InlineButtonGroup>
        )}
      </CardItem>
      {status === 'failure' && (
        <ErrorMessage>{intl.formatMessage(Common_ErrorOccurred)}</ErrorMessage>
      )}

      <ConfirmDialog
        show={showConfirmSetup}
        title={Jodabook_ConfirmUpdate}
        onClose={() => setShowConfirmSetup(false)}
        onConfirm={confirmSetUpJodabookAccount}
        onCancel={() => setShowConfirmSetup(false)}
      />

      <ConfirmDialog
        show={showConfirmDisable}
        title={Jodabook_TurnOff}
        onClose={() => setShowConfirmDisable(false)}
        onConfirm={disableJodabook}
        onCancel={() => setShowConfirmDisable(false)}
      />
    </React.Fragment>
  )
}

import SwipeableViews from 'react-swipeable-views'
import React, { FunctionComponent, useState } from 'react'
import { CardImage } from '../card/Card'
import { Video } from '../media/Video'
import styled from 'styled-components'
import { spacing, colors } from '../../../styles/styles'
import { MediaDialog } from './MediaDialog'
import {
  faSearchPlus,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const OuterWrapper = styled.div`
  position: relative;
`

const IndicatorOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // position: absolute;
  width: 100%;
  padding: ${spacing.sm};
  bottom: -1.6rem;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`
const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  // background: white;

  &:not(:last-child) {
    margin-right: ${spacing.md};
  }
`
const ZoomIndexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    margin-left: ${spacing.md};
  }
`

type Props = {
  media: any[]
}

export const SwipeableMedia: FunctionComponent<Props> = ({ media }) => {
  const [swipeIndex, setSwipeIndex] = useState<number>(0)
  const [showLargeMedia, setShowLargeMedia] = useState<boolean>(false)

  const dots = Array(media.length).fill('')
  const onGalleryImageClick = (e) => {
    e.stopPropagation()
    if (
      e.nativeEvent.offsetX >= e.target.clientWidth / 2 &&
      swipeIndex + 1 < media.length
    )
      setSwipeIndex(swipeIndex + 1)
    else if (e.nativeEvent.offsetX < e.target.clientWidth / 2 && swipeIndex > 0)
      setSwipeIndex(swipeIndex - 1)
  }
  if (media.length > 0) {
    return (
      <OuterWrapper>
        <ZoomIndexWrapper>
          <span>
            {swipeIndex + 1}/{media.length}
          </span>
          {!media[swipeIndex].status && (
            <FontAwesomeIcon
              icon={faSearchPlus}
              onClick={() => setShowLargeMedia(!showLargeMedia)}
              style={{ marginRight: spacing.md, cursor: 'pointer' }}
            />
          )}
        </ZoomIndexWrapper>
        {media.length > 1 && (
          <IndicatorOverlay>
            <FontAwesomeIcon
              icon={faChevronLeft}
              color={swipeIndex > 0 ? 'gray' : 'white'}
              onClick={() => swipeIndex > 0 && setSwipeIndex(swipeIndex - 1)}
              style={{ marginRight: spacing.md, cursor: 'pointer' }}
            />
            {dots.map((dot, i) => (
              <Dot
                key={`dot-${i}`}
                style={{
                  background: i === swipeIndex ? colors.jodaDark : 'gray',
                }}
              />
            ))}
            <FontAwesomeIcon
              icon={faChevronRight}
              color={swipeIndex + 1 < media.length ? 'gray' : 'white'}
              onClick={() =>
                swipeIndex + 1 < media.length && setSwipeIndex(swipeIndex + 1)
              }
              style={{ cursor: 'pointer' }}
            />
          </IndicatorOverlay>
        )}
        <SwipeableViews
          animateHeight={true}
          onChangeIndex={(newIndex) => setSwipeIndex(newIndex)}
          index={swipeIndex}
          enableMouseEvents={true}
          ignoreNativeScroll={true}
          resistance={true}
        >
          {media.map((m: any, i) =>
            m.status ? (
              <div onClick={(e) => media.length > 1 && onGalleryImageClick(e)}>
                <Video key={`vid-${i}`} video={m} />
              </div>
            ) : (
              <CardImage
                key={`img-${i}`}
                // src={`https://azffdejopq.cloudimg.io/${m.medium}?width=600&height=336&func=fit`}
                src={m.medium}
                onClick={(e) => media.length > 1 && onGalleryImageClick(e)}
              />
            )
          )}
        </SwipeableViews>

        {showLargeMedia && (
          <MediaDialog
            onClose={() => setShowLargeMedia(!showLargeMedia)}
            media={media.filter((m) => !m.status)}
          />
        )}
      </OuterWrapper>
    )
  }
  return null
}

import React, { useState } from 'react'
import { Card } from '../../../shared/card/Card'
import { PageContainer } from '../../../shared/layout/Layout'
import {
  Common_Next,
  Register_EmployeeRole,
  Register_ManagerRole,
  Register_BPARole,
  Register_RelativeRole,
  Register_SelectRoleTitle,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { WizardContainer } from '../common/WizardContainer'
import illustration from '../../../../images/illustrations/young_and_old.svg'
import { NextButton } from '../common/NextButton'
import { spacing } from '../../../../styles/styles'
import { useHistory } from 'react-router-dom'
import { RadioButtonGroup } from '../common/RadioButtonGroup'

export const SelectRolePage = () => {
  const intl = useIntl()
  const history = useHistory()

  const [role, setRole] = useState<string>('')

  const options = [
    { key: Register_ManagerRole, value: 'manager' },
    { key: Register_BPARole, value: 'bpa' },
    { key: Register_EmployeeRole, value: 'employee' },
    { key: Register_RelativeRole, value: 'relative' },
  ]

  const onNext = () => {
    const canRegister = ['employee', 'relative'].indexOf(role) !== -1

    if (canRegister) {
      history.push(`/register/${role}/create-account`)
    } else {
      history.push('/contact-us')
    }
  }

  return (
    <PageContainer>
      <Card>
        <WizardContainer
          numSteps={6}
          currentStep={1}
          title={intl.formatMessage(Register_SelectRoleTitle)}
          illustration={illustration}
        >
          <div>
            <RadioButtonGroup
              options={options}
              selected={role}
              onSelected={(role) => setRole(role)}
            />

            <div style={{ marginTop: spacing.lg, textAlign: 'center' }}>
              <NextButton
                label={intl.formatMessage(Common_Next)}
                disabled={!role}
                onClick={onNext}
              />
            </div>
          </div>
        </WizardContainer>
      </Card>
    </PageContainer>
  )
}

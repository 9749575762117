import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { MainFeed } from './components/pages/MainFeed'
import { LogoutPage } from './components/pages/authentication/LogoutPage'
import { GroupDetailPage } from './components/pages/group/detail/GroupDetailPage'
import { GroupPage } from './components/pages/group/list/GroupPage'
import { MessageRouter } from './components/pages/messages/MessageRouter'
import { InstitutionDetailPage } from './components/pages/institution/detail/InstitutionDetailPage'
import { InstitutionPage } from './components/pages/institution/list/InstitutionPage'
import { TeamDetailPage } from './components/pages/team/detail/TeamDetailPage'
import { TeamPage } from './components/pages/team/list/TeamPage'
import { MorePage } from './components/pages/more/MorePage'
import { SettingsPage } from './components/pages/settings/SettingsPage'
import { ModeratorPage } from './components/pages/moderator/ModeratorPage'
import { PostRouter } from './components/pages/posts/PostRouter'
import { MyInvitationsPage } from './components/pages/invitations/MyInvitationsPage'
import { EditEventPage } from './components/pages/calendar/EditEventPage'
import { CreateEventPage } from './components/pages/calendar/CreateEventPage'
import { UserProvider } from './contexts/UserProvider'
import { ProfilePage } from './components/pages/profile/ProfilePage'
import { EditProfilePage } from './components/pages/profile/EditProfilePage'
import { EulaPage } from './components/pages/eula/EulaPage'
import { CreateGroupPage } from './components/pages/group/create/CreateGroupPage'
import { CreateTeamPage } from './components/pages/team/create/CreateTeamPage'
import { MyInstitutionPage } from './components/pages/institution/list/MyInstitutionPage'
import { MyNetworkPage } from './components/pages/my-network/MyNetworkPage'

export const CommonRoutes = () => {
  return (
    <Switch>
      <Route path="/logout" exact>
        <LogoutPage />
      </Route>

      <Route path="/feed">
        <MainFeed />
      </Route>

      <Route path="/calendar/:eventId/edit">
        <EditEventPage />
      </Route>

      <Route path="/calendar/:ownerId/create">
        <CreateEventPage />
      </Route>

      <Route path="/groups/create">
        <CreateGroupPage />
      </Route>

      <Route path="/groups/:id">
        <GroupDetailPage />
      </Route>

      <Route path="/groups">
        <GroupPage />
      </Route>

      <Route path="/messages" component={MessageRouter} />

      <Route path="/institutions/:id">
        <InstitutionDetailPage />
      </Route>

      <Route path="/institutions">
        <InstitutionPage />
      </Route>

      <Route path="/my-institutions">
        <MyInstitutionPage />
      </Route>

      <Route path="/teams/create">
        <CreateTeamPage />
      </Route>

      <Route path="/teams/:id">
        <TeamDetailPage />
      </Route>

      <Route path="/teams">
        <TeamPage />
      </Route>

      <Route path="/more">
        <MorePage />
      </Route>

      <Route path="/settings">
        <SettingsPage />
      </Route>

      <Route path="/moderator">
        <ModeratorPage />
      </Route>

      <Route path="/posts" component={PostRouter} />

      <Route path="/invitations">
        <MyInvitationsPage />
      </Route>

      <Route path="/profile/:id" exact>
        <UserProvider>
          <ProfilePage />
        </UserProvider>
      </Route>

      <Route path="/profile/:id/edit" exact>
        <UserProvider>
          <EditProfilePage />
        </UserProvider>
      </Route>

      <Route path="/eula">
        <EulaPage />
      </Route>

      <Route path="/my-network">
        <MyNetworkPage />
      </Route>

      <Redirect to="/feed" />
    </Switch>
  )
}

export const environment =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? {
        API_URL: `https://api.jodacare.com/api/v3`,
        IDPORTEN_LOGIN_URL: `https://api.jodacare.com/api/v3/auth/login-oidc`,
        IDPORTEN_LOGOUT_URL: 'https://api.jodacare.com/api/v3/auth/endsession',
        IDPORTEN_VERIFY_URL: 'https://api.jodacare.com/api/v3/auth/verify-oidc',
        BIOFY_LOGIN_URL: 'https://api.jodacare.com/api/v3/biofy/login',
        BIOFY_VERIFY_URL: 'https://api.jodacare.com/api/v3/biofy/verify',
        BIOFY_LOGOUT_URL: 'https://api.jodacare.com/api/v3/biofy/endsession',
        WEBEX_LOGIN_URL:
          'https://webexapis.com/v1/authorize?client_id=Cc151965f10c0b3ab40dbf429428e596c5c1d5717e3e91b620b476001455f71ae&response_type=code&redirect_uri=https://staging.jodacare.com/service-receivers&scope=spark%3Acalls_write%20spark%3Aall%20spark%3Akms%20identity%3Atokens_write%20identity%3Atokens_read&state=0703997',
      }
    : process.env.REACT_APP_ENVIRONMENT === 'local'
    ? {
        API_URL: `http://localhost:21021/api/v3`,
        IDPORTEN_LOGIN_URL: `http://localhost:21021/api/v3/auth/login-oidc`,
        IDPORTEN_LOGOUT_URL: 'http://localhost:21021/api/v3/auth/endsession',
        IDPORTEN_VERIFY_URL: 'http://localhost:21021/api/v3/auth/verify-oidc',
        BIOFY_LOGIN_URL: 'http://localhost:21021/api/v3/biofy/login',
        BIOFY_VERIFY_URL: 'http://localhost:21021/api/v3/biofy/verify',
        BIOFY_LOGOUT_URL: 'http://localhost:21021/api/v3/biofy/endsession',
        WEBEX_LOGIN_URL:
          'https://webexapis.com/v1/authorize?client_id=Cc151965f10c0b3ab40dbf429428e596c5c1d5717e3e91b620b476001455f71ae&response_type=code&redirect_uri=https://staging.jodacare.com/service-receivers&scope=spark%3Acalls_write%20spark%3Aall%20spark%3Akms%20identity%3Atokens_write%20identity%3Atokens_read&state=0703997',
      }
    : {
        API_URL: `https://jodacare-backend-staging.herokuapp.com/api/v3`,
        IDPORTEN_LOGIN_URL: `https://jodacare-backend-staging.herokuapp.com/api/v3/auth/login-oidc`,
        IDPORTEN_LOGOUT_URL:
          'https://jodacare-backend-staging.herokuapp.com/api/v3/auth/endsession',
        IDPORTEN_VERIFY_URL:
          'https://jodacare-backend-staging.herokuapp.com/api/v3/auth/verify-oidc',
        BIOFY_LOGIN_URL:
          'https://jodacare-backend-staging.herokuapp.com/api/v3/biofy/login',
        BIOFY_VERIFY_URL:
          'https://jodacare-backend-staging.herokuapp.com/api/v3/biofy/verify',
        BIOFY_LOGOUT_URL:
          'https://jodacare-backend-staging.herokuapp.com/api/v3/biofy/endsession',
        WEBEX_LOGIN_URL:
          'https://webexapis.com/v1/authorize?client_id=Cc151965f10c0b3ab40dbf429428e596c5c1d5717e3e91b620b476001455f71ae&response_type=code&redirect_uri=https://staging.jodacare.com/service-receivers&scope=spark%3Acalls_write%20spark%3Aall%20spark%3Akms%20identity%3Atokens_write%20identity%3Atokens_read&state=0703997',
      }

import styled from 'styled-components'
import { smallScreenBreakpoint, spacing } from '../../../styles/styles'

export const FormButtons = styled.div`
  display: flex;
  gap: ${spacing.md};
  flex-direction: column;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    flex-direction: row;
  }
`

import React, { FunctionComponent } from 'react'
import { FlexColumn, FlexContainer } from '../layout/Flex'
import { TextField } from './TextField'
import moment from 'moment'
import { TranslatedMessage } from '../../../types'
import { Common_At } from '../../../translations/messages'

type Props = {
  id: string
  label: TranslatedMessage
  value: string
  hideTime?: boolean
  onChange: (value: string) => void
  error?: string
}

export const DateTimePicker: FunctionComponent<Props> = ({
  id,
  label,
  value,
  hideTime,
  onChange,
  error,
}) => {
  const date = moment(value).format('YYYY-MM-DD')
  const time = moment(value).format('HH:mm')

  return (
    <FlexContainer>
      <FlexColumn>
        <TextField
          id={`${id}Date`}
          type="date"
          label={label}
          value={date}
          onChange={(value) =>
            onChange(moment(`${value}T${time}`).toISOString())
          }
          error={error}
        />
      </FlexColumn>
      {!hideTime && (
        <FlexColumn>
          <TextField
            id={`${id}Time`}
            type="time"
            label={Common_At}
            value={time}
            onChange={(value) =>
              onChange(moment(`${date}T${value}`).toISOString())
            }
          />
        </FlexColumn>
      )}
    </FlexContainer>
  )
}

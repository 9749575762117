import React, { FunctionComponent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../../styles/styles'
import { FadeIn } from '../animations/FadeIn'
import { Common_Delete, Common_Edit } from '../../../translations/messages'
import { useIntl } from 'react-intl'

const MenuWrapper = styled.div`
  position: relative;
`

const MenuButton = styled.button`
  padding: ${spacing.xs} ${spacing.sm};
  align-self: flex-start;
  justify-self: flex-end;
  border: 1px solid ${colors.black80};
  border-radius: ${borderRadius};
  background: white;
`

const Menu = styled.div`
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  background: white;
  z-index: 2;
  right: 0;
  border: 1px solid ${colors.black10};
`

const MenuItem = styled.button`
  padding: ${spacing.md} ${spacing.xl};
  text-align: center;
  border: none;
  background: white;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.black10};
  }
`

type Props = {
  onEdit: () => void
  onDelete: () => void
}

export const CardMenu: FunctionComponent<Props> = ({ onEdit, onDelete }) => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <MenuWrapper>
      <MenuButton onClick={() => setIsOpen((prevState) => !prevState)}>
        <FontAwesomeIcon icon={faCog} />
      </MenuButton>
      {isOpen && (
        <FadeIn showOn={true}>
          <Menu>
            <MenuItem
              onClick={() => {
                setIsOpen(false)
                onEdit()
              }}
            >
              {intl.formatMessage(Common_Edit)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setIsOpen(false)
                onDelete()
              }}
            >
              {intl.formatMessage(Common_Delete)}
            </MenuItem>
          </Menu>
        </FadeIn>
      )}
    </MenuWrapper>
  )
}

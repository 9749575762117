import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { smallScreenBreakpoint, spacing } from '../../styles/styles'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const NavButtonStyled = styled.div`
  position: fixed;
  top: 14px;
  right: ${spacing.md};
  z-index: 3;

  button {
    border: none;
    text-transform: uppercase;
    padding: ${spacing.sm} 0 ${spacing.sm} ${spacing.lg};
    font-size: inherit;
    background: white;
  }

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    display: none;

    button {
      display: none;
    }
  }
`

type Props = {
  label: { id: string; defaultMessage: string }
  path?: string
  onClick?: () => void
  state?: any
  disabled?: boolean
}

export const NavButton: FunctionComponent<Props> = ({
  label,
  path,
  onClick,
  state,
  disabled,
}) => {
  const history = useHistory()

  const action = onClick ? () => onClick() : () => history.push(path!, state)

  return (
    <NavButtonStyled>
      <button onClick={action} disabled={disabled}>
        <FormattedMessage id={label.id} defaultMessage={label.defaultMessage} />
      </button>
    </NavButtonStyled>
  )
}

import React, { FunctionComponent, useContext, useEffect } from 'react'
import { List, ListPageContainer } from '../../../shared/list'
import { environment } from '../../../../environments'
import { PageHeader } from '../../../shared/layout/PageHeader'
import { Card } from '../../../shared/card/Card'
import styled from 'styled-components'
import { colors, spacing } from '../../../../styles/styles'
import { Avatar } from '../../../shared/Avatar'
import { useHistory } from 'react-router-dom'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import {
  Common_New,
  Common_Teams,
  Teams_EmptyState,
} from '../../../../translations/messages'
import { Button } from '../../../shared/Buttons'
import { useIntl } from 'react-intl'
import { AuthContext } from '../../../../contexts/AuthContext'

const ListItem = styled(Card)`
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  border-bottom: 1px solid ${colors.black5};
`

type Props = {
  data: any
}

const TeamListItem: FunctionComponent<Props> = ({ data }) => {
  const intl = useIntl()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const history = useHistory()

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Common_Teams))
  }, [intl, updateNavBarTitle])

  return (
    <li>
      <ListItem onClick={() => history.push(`/teams/${data.id}`)}>
        <Avatar
          style={{ marginRight: spacing.md }}
          size="small"
          image={data.owner.image}
          alt={`Bilde av ${data.owner.first_name}`}
        />
        <div>
          <div>{data.name}</div>
          <small>
            {data.owner.first_name} {data.owner.last_name}
          </small>
        </div>
      </ListItem>
    </li>
  )
}

export const TeamPage = () => {
  const { permissions } = useContext(AuthContext)
  const intl = useIntl()
  const history = useHistory()

  const canCreateTeam = permissions.PRIMARY_CONTACT

  return (
    <ListPageContainer>
      <PageHeader
        title="Teams"
        trailingElement={
          canCreateTeam && (
            <Button
              label={intl.formatMessage(Common_New)}
              ariaLabel={intl.formatMessage(Common_New)}
              isSmall
              onClick={() => history.push(`teams/create`)}
              variant="primary"
            />
          )
        }
      />
      <List
        emptyState={intl.formatMessage(Teams_EmptyState)}
        ListItem={TeamListItem}
        url={`${environment.API_URL}/teams`}
      />
    </ListPageContainer>
  )
}

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CreatePostPage } from './CreatePostPage'
import { PostDetailPage } from './PostDetailPage'
import { EditPostPage } from './EditPostPage'

export const PostRouter = () => {
  return (
    <Switch>
      <Route path={'/posts/create/:ownerId'}>
        <CreatePostPage />
      </Route>
      <Route path={'/posts/create'}>
        <CreatePostPage />
      </Route>
      <Route path={'/posts/:id/edit'}>
        <EditPostPage />
      </Route>
      <Route path={'/posts/:id'}>
        <PostDetailPage />
      </Route>
    </Switch>
  )
}

import React, { FunctionComponent, useState, useRef } from 'react'
import { spacing } from '../../../../styles/styles'
import { TextField } from '../../../shared/form/TextField'
import {
  Common_Cancel,
  Common_Description,
  Common_Friday,
  Common_Monday,
  Common_Saturday,
  Common_Save,
  Common_Sunday,
  Common_Thursday,
  Common_Title,
  Common_Tuesday,
  Common_Wednesday,
  Event_AllDay,
  Event_DoesNotRepeat,
  Event_End,
  Event_EndRecurringDate,
  Event_RepeatDaily,
  Event_RepeatMonthly,
  Event_Repeats,
  Event_RepeatWeekly,
  Event_RepeatXDaily,
  Event_RepeatXMonthly,
  Event_RepeatXWeekly,
  Event_RepeatXYearly,
  Event_RepeatYearly,
  Event_Start,
  Event_StartMustBeBeforeEndError,
  Event_Image,
} from '../../../../translations/messages'
import { TextAreaField } from '../../../shared/form/TextArea'
import { CheckBox } from '../../../shared/form/CheckBox'
import { DateTimePicker } from '../../../shared/form/DateTimePicker'
import { Select } from '../../../shared/form/Select'
import { MultipleSelect } from '../../../shared/form/MultipleSelect'
import { Button, ButtonGroup } from '../../../shared/Buttons'
import { Card } from '../../../shared/card/Card'
import moment from 'moment'
import { CalendarEvent, TranslatedMessage } from '../../../../types'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const validateRecurring = (event: CalendarEvent) => {
  const { options } = event

  const weekdaysIsValid =
    options?.frequency === 'WEEKLY' ? options.weekdays.length > 0 : true

  const endRecurringIsAfterEventEnd =
    moment(event?.options?.end_recurring_date).valueOf() >=
    moment(event.end).valueOf()

  return weekdaysIsValid && endRecurringIsAfterEventEnd
}

const generateIntervals = (
  event: CalendarEvent,
  messageFormatter: Function
) => {
  if (event.options) {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20].map((num) => ({
      // @ts-ignore
      key: `${messageFormatter(translations[event.options.frequency], {
        interval: num,
      })}`,
      value: num,
    }))
  }

  return []
}

const generateWeekdays = (messageFormatter: Function) => {
  return [
    { key: messageFormatter(Common_Monday), value: 0 },
    { key: messageFormatter(Common_Tuesday), value: 1 },
    { key: messageFormatter(Common_Wednesday), value: 2 },
    { key: messageFormatter(Common_Thursday), value: 3 },
    { key: messageFormatter(Common_Friday), value: 4 },
    { key: messageFormatter(Common_Saturday), value: 5 },
    { key: messageFormatter(Common_Sunday), value: 6 },
  ]
}

const translations: { [key: string]: TranslatedMessage } = {
  DAILY: Event_RepeatXDaily,
  WEEKLY: Event_RepeatXWeekly,
  MONTHLY: Event_RepeatXMonthly,
  YEARLY: Event_RepeatXYearly,
}

type Props = {
  event: any
  onChange: (event: any) => void
  submitForm: () => void
  onCancel?: () => void
}

export const EventForm: FunctionComponent<Props> = ({
  event,
  onChange,
  submitForm,
  onCancel,
}) => {
  const intl = useIntl()
  const [isRecurring, setIsRecurring] = useState<boolean>(event.options)

  const frequencyOptions = [
    { key: intl.formatMessage(Event_RepeatDaily), value: 'DAILY' },
    { key: intl.formatMessage(Event_RepeatWeekly), value: 'WEEKLY' },
    { key: intl.formatMessage(Event_RepeatMonthly), value: 'MONTHLY' },
    { key: intl.formatMessage(Event_RepeatYearly), value: 'YEARLY' },
  ]

  const intervalOptions = generateIntervals(event, intl.formatMessage)
  const weekdays = generateWeekdays(intl.formatMessage)

  const startBeforeEnd =
    moment(event.start).valueOf() <= moment(event.end).valueOf()

  const eventValid = event.title && startBeforeEnd
  const recurringValid = event.options ? validateRecurring(event) : true
  const formIsValid = eventValid && recurringValid
  const inputFile: any = useRef(null)

  const onButtonClick = () => {
    inputFile.current.click()
  }
  return (
    <Card style={{ padding: spacing.md }}>
      {event.images && event.images.length > 0 ? (
        <div
          style={{
            display: 'table',
            margin: '0 auto',
          }}
        >
          <img
            width="200"
            height="200"
            src={
              event.images[0].id
                ? event.images[0].medium
                : URL.createObjectURL(event.images[0])
            }
            alt="EventImage"
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => onChange({ ...event, images: null })}
            color="red"
          />
        </div>
      ) : (
        <div style={{ marginBottom: spacing.md }}>
          <input
            style={{ display: 'none' }}
            accept="image/x-png,image/jpg,image/jpeg"
            ref={inputFile}
            onChange={(value: any) =>
              onChange({ ...event, images: [value.target.files[0]] })
            }
            type="file"
          />
          <Button
            label={intl.formatMessage(Event_Image)}
            ariaLabel={intl.formatMessage(Event_Image)}
            variant="secondary"
            onClick={() => onButtonClick()}
          />
        </div>
      )}
      <TextField
        id="title"
        type="text"
        label={Common_Title}
        value={event.title}
        onChange={(value) => onChange({ ...event, title: value })}
      />

      <TextAreaField
        id="description"
        label={Common_Description}
        value={event.description}
        onChange={(value) => onChange({ ...event, description: value })}
      />

      <div style={{ marginBottom: spacing.md }}>
        <CheckBox
          label={Event_AllDay}
          name="isAllDay"
          checked={event.is_all_day}
          onChange={(checked) => onChange({ ...event, is_all_day: checked })}
        />
      </div>

      <DateTimePicker
        id="start"
        label={Event_Start}
        onChange={(value) => onChange({ ...event, start: value })}
        hideTime={event.is_all_day}
        value={event.start}
        error={
          !startBeforeEnd
            ? intl.formatMessage(Event_StartMustBeBeforeEndError)
            : ''
        }
      />

      <DateTimePicker
        id="end"
        label={Event_End}
        onChange={(value) => onChange({ ...event, end: value })}
        hideTime={event.is_all_day}
        value={event.end}
        error={
          !startBeforeEnd
            ? intl.formatMessage(Event_StartMustBeBeforeEndError)
            : ''
        }
      />

      <div style={{ marginBottom: spacing.md }}>
        <CheckBox
          label={isRecurring ? Event_Repeats : Event_DoesNotRepeat}
          name="isRecurring"
          checked={isRecurring}
          onChange={(checked) => {
            setIsRecurring(!!checked)

            if (checked) {
              onChange({
                ...event,
                options: {
                  frequency: 'DAILY',
                  weekdays: [],
                  interval: 1,
                },
              })
            } else {
              onChange({ ...event, options: null })
            }
          }}
        />
      </div>
      {event.options && (
        <React.Fragment>
          <div style={{ marginBottom: spacing.md }}>
            <Select
              value={event.options.frequency}
              ariaLabel="frequency"
              options={frequencyOptions}
              onChange={(value) => {
                if (value) {
                  onChange({
                    ...event,
                    options: { ...event.options, frequency: value },
                  })
                } else {
                  onChange({
                    ...event,
                    options: null,
                  })
                }
              }}
            />
          </div>

          {event.options.frequency && (
            <React.Fragment>
              <div style={{ marginBottom: spacing.md }}>
                <Select
                  value={event.options.interval}
                  ariaLabel="interval"
                  options={intervalOptions}
                  onChange={(value) =>
                    onChange({
                      ...event,
                      options: { ...event.options, interval: value },
                    })
                  }
                />
              </div>

              {event.options.frequency === 'WEEKLY' && (
                <MultipleSelect
                  options={weekdays}
                  selected={event.options.weekdays}
                  onChange={(values) =>
                    onChange({
                      ...event,
                      options: { ...event.options, weekdays: values },
                    })
                  }
                />
              )}

              <DateTimePicker
                id="endRecurringDate"
                label={Event_EndRecurringDate}
                value={event.options.end_recurring_date}
                hideTime={true}
                onChange={(value) =>
                  onChange({
                    ...event,
                    options: {
                      ...event.options,
                      end_recurring_date: value,
                    },
                  })
                }
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      <ButtonGroup>
        {onCancel && (
          <Button
            label={intl.formatMessage(Common_Cancel)}
            ariaLabel={intl.formatMessage(Common_Cancel)}
            variant="secondary"
            onClick={onCancel}
          />
        )}

        <Button
          label={intl.formatMessage(Common_Save)}
          ariaLabel={intl.formatMessage(Common_Save)}
          variant="primary"
          disabled={!formIsValid}
          onClick={submitForm}
        />
      </ButtonGroup>
    </Card>
  )
}

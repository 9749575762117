import React, { useState } from 'react'
import { Button, LinkButton } from '../../shared/Buttons'
import { useIntl } from 'react-intl'
import { spacing } from '../../../styles/styles'
import { SelectMunicipality } from './common/SelectMunicipality'
import { NextButton } from './common/NextButton'
import {
  Login_ChangeAuthMethod,
  Login_Login,
  Login_SelectAuthMethod,
  Create_Account,
} from '../../../translations/messages'
import { environment } from '../../../environments'

type AuthOptionTypes = 'ID_PORTEN' | 'BIOFY'

export const AuthOptions = () => {
  const intl = useIntl()

  const [
    selectedAuthOption,
    setSelectedAuthOption,
  ] = useState<AuthOptionTypes | null>()
  const [selectedMunicipality, setSelectedMunicipality] = useState<any>(
    undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loginWithIdPorten = () => {
    setIsLoading(true)
    window.location.href = `${environment.IDPORTEN_LOGIN_URL}?onbehalfof=${selectedMunicipality.code}`
  }

  const loginWithBiofy = () => {
    setIsLoading(true)
    window.location.href = environment.BIOFY_LOGIN_URL
  }

  return selectedAuthOption === 'ID_PORTEN' ? (
    <div
      style={{
        marginTop: spacing.lg,
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.md,
      }}
    >
      <SelectMunicipality
        onChange={(municipality) => setSelectedMunicipality(municipality)}
      />

      <NextButton
        label={intl.formatMessage(Login_Login)}
        onClick={loginWithIdPorten}
        disabled={!selectedMunicipality || isLoading}
      />

      <div style={{ marginTop: spacing.md }}>
        <LinkButton
          aria-label={intl.formatMessage(Login_ChangeAuthMethod)}
          onClick={() => setSelectedAuthOption(null)}
        >
          {intl.formatMessage(Login_ChangeAuthMethod) || isLoading}
        </LinkButton>
      </div>
    </div>
  ) : (
    <div
      style={{
        marginTop: spacing.lg,
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.md,
      }}
    >
      <h3>{intl.formatMessage(Login_SelectAuthMethod)}</h3>

      <Button
        ariaLabel="ID-Porten"
        label="ID-Porten"
        onClick={() => setSelectedAuthOption('ID_PORTEN')}
        variant="secondary"
      />

      <Button
        ariaLabel="Biofy"
        label="Biofy"
        onClick={() => {
          setSelectedAuthOption('BIOFY')
          loginWithBiofy()
        }}
        variant="secondary"
      />
      <h4>
        <a href="/register">{intl.formatMessage(Create_Account)}</a>
      </h4>
    </div>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { ListItemLoader } from '../Loading'
import { authenticatedFetch } from '../../../lib/service'
import styled from 'styled-components'
import { PageContainer } from '../layout/Layout'
import { FadeIn } from '../animations/FadeIn'
import { Button } from '../Buttons'
import { Common_LoadMore } from '../../../translations/messages'
import { useIntl } from 'react-intl'
import { spacing } from '../../../styles/styles'
import { relationsMap } from '../../../relationMap'

const ListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

type Props = {
  ListItem: FunctionComponent<{ data: any }>
  url: string
  emptyState: string
  forUserList?: boolean
}

export const ListPageContainer = styled(PageContainer)`
  max-width: 37.5rem;
`

export const List: FunctionComponent<Props> = ({
  emptyState,
  url,
  ListItem,
  forUserList = false,
}) => {
  const intl = useIntl()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>(
    'pending'
  )
  const [loadMoreStatus, setLoadMoreStatus] = useState<
    'idle' | 'pending' | 'failure'
  >('idle')
  const [items, setItems] = useState<any[]>([])
  const [nextUrl, setNextUrl] = useState<string | null>(null)

  const groupResultByUserId = (results) => {
    const groupByUserId = results.reduce((usersToGroup, { user, id }) => {
      ;(usersToGroup[user.id] = usersToGroup[user.id] || []).push(user.id)
      return usersToGroup
    }, {})
    const userIds = Object.keys(groupByUserId)
    let resultGroupedByUser: any = []
    userIds.map((u) => {
      const userToAdd: any = results.find((r) => r.user.id === u)
      userToAdd.relation = results
        .filter((x) => x.user.id === u)
        .map((r) =>
          relationsMap[r.relation]
            ? intl.formatMessage(relationsMap[r.relation])
            : r.relation
        )
        .join(', ')
      return resultGroupedByUser.push(userToAdd)
    })
    return resultGroupedByUser
  }
  useEffect(() => {
    authenticatedFetch(url)
      .then(({ results, next }) => {
        if (forUserList) {
          results = groupResultByUserId(results)
        }
        setStatus('idle')
        setItems(results)
        setNextUrl(next)
      })
      .catch((error) => {
        setStatus('failure')
      })
    // eslint-disable-next-line
  }, [url, forUserList])

  if (status === 'pending') {
    return <ListItemLoader />
  }

  if (items?.length === 0) {
    return <div>{emptyState}</div>
  }

  const loadMore = () => {
    if (nextUrl) {
      setLoadMoreStatus('pending')

      authenticatedFetch(nextUrl)
        .then(({ results, next }) => {
          setLoadMoreStatus('idle')
          setItems([...items, ...results])
          setNextUrl(next)
        })
        .catch((error) => {
          setLoadMoreStatus('failure')
        })
    }
  }

  return (
    <ListStyled>
      <FadeIn showOn={items}>
        {items?.map((item) => (
          <ListItem key={item.id} data={item} />
        ))}

        {loadMoreStatus === 'pending' && <ListItemLoader />}

        {nextUrl && (
          <div style={{ marginTop: spacing.md, textAlign: 'center' }}>
            <Button
              ariaLabel={intl.formatMessage(Common_LoadMore)}
              label={intl.formatMessage(Common_LoadMore)}
              isSmall
              onClick={loadMore}
              variant="secondary"
            />
          </div>
        )}
      </FadeIn>
    </ListStyled>
  )
}

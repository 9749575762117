import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { borderRadius, colors } from '../../../styles/styles'
import { TranslatedMessage } from '../../../types'
import { useIntl } from 'react-intl'

type Props = {
  label: TranslatedMessage
  name: string
  checked: boolean
  onChange: (checked: boolean) => void
  helpText?: TranslatedMessage
}

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.6rem;
  width: 1.6rem;
  border: 1px solid ${colors.black};
  border-radius: ${borderRadius};

  &:after {
    transition: opacity 0.25s ease-out;
    opacity: 0;
    content: '';
    position: absolute;
    left: 0.5625rem;
    top: 0.4rem;
    width: 0.3125rem;
    height: 0.625rem;
    border: solid ${colors.black};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const CheckBoxStyled = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input:checked ~ ${Checkmark} {
    border-radius: ${borderRadius};
    border: 1px solid ${colors.black90};
    background: ${colors.black90};
  }

  input:checked ~ ${Checkmark}:after {
    border-color: white;
    opacity: 1;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`

const HelpText = styled.small`
  display: inline-block;
  color: ${colors.black70};
`

export const CheckBox: FunctionComponent<Props> = ({
  label,
  name,
  checked,
  onChange,
  helpText,
}) => {
  const intl = useIntl()

  return (
    <div>
      <CheckBoxStyled>
        <input
          aria-label={label.defaultMessage}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={({ target: { checked } }) => onChange(checked)}
        />

        <div>
          {intl.formatMessage(label)}

          {helpText && <HelpText>{intl.formatMessage(helpText)}</HelpText>}
        </div>

        <Checkmark />
      </CheckBoxStyled>
    </div>
  )
}

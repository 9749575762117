import React from 'react'
import { FadeIn } from './animations/FadeIn'
import { Card } from './card/Card'
import styled from 'styled-components'
import { colors, spacing } from '../../styles/styles'
import { useIntl } from 'react-intl'
import {
  Common_NoAccessText,
  Common_NoAccessTitle,
} from '../../translations/messages'

const NoAccessCard = styled(Card)`
  padding: ${spacing.md};
  background: ${colors.warmth};

  h2 {
    margin: 0;
  }
`

export const NoAccess = () => {
  const intl = useIntl()

  return (
    <FadeIn showOn={true}>
      <NoAccessCard>
        <h2>{intl.formatMessage(Common_NoAccessTitle)}</h2>
        <p>{intl.formatMessage(Common_NoAccessText)}</p>
      </NoAccessCard>
    </FadeIn>
  )
}

import styled from 'styled-components'
import {
  borderRadius,
  colors,
  smallScreenBreakpoint,
  spacing,
} from '../../../styles/styles'

export const Card = styled.div`
  border-radius: ${borderRadius};
  background: ${colors.white};
`

export const CardContent = styled(Card)`
  padding: ${spacing.xl} ${spacing.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const CardHeader = styled.div`
  padding: ${spacing.md};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CardTextWithLoadMore = styled.div`
  padding: ${spacing.sm} ${spacing.md};
`
export const CardText = styled.div`
  padding: ${spacing.sm} ${spacing.md};
`

export const CardImage = styled.img`
  width: 100%;
  // height: 21rem;
  height: 35rem;
  object-fit: contain;
`

export const CardItem = styled(Card)`
  padding: ${spacing.md};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const CardActions = styled.div`
  padding: 0 ${spacing.md};
  display: flex;
  @media only screen and (max-width: ${smallScreenBreakpoint}px) {
    padding: 0 ${spacing.sm};
  }
`

export const CardAction = styled.button`
  padding: 0 ${spacing.md} 0 0;
  height: 3rem;
  font-size: inherit;
  border: none;
  background: transparent;
  color: ${(props) => (props.color ? props.color : 'inherit')};
  display: flex;
  align-items: center;

  svg {
    margin-right: ${spacing.sm};
    font-size: 1.2rem;
  }

  &:not(:last-child) {
    margin-right: ${spacing.md};
  }
`

export const CardMeta = styled.div`
  display: flex;
`

export const Meta = styled.div`
  flex: 1;
  padding: ${spacing.sm} ${spacing.md};
  color: ${colors.black80};
  font-size: 0.9rem;
`

export const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

export const Li = styled.li`
  padding: ${spacing.md};

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.black10};
  }

  h4 {
    margin: 0;
  }
`

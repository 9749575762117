import React, { FunctionComponent } from 'react'
import { Card } from '../card/Card'
import { Avatar } from '../Avatar'
import { relationsMap } from '../../../relationMap'
import { useIntl } from 'react-intl'
import { colors, spacing } from '../../../styles/styles'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)`
  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
`

const RelationListItemCard = styled(Card)`
  display: flex;
  align-items: center;
  gap: ${spacing.md};
  padding: ${spacing.md};
  border-bottom: 1px solid ${colors.black10};

  small {
    display: block;
  }
`

type Props = {
  relation: any
  allUserRelation: string
}

export const RelationListItem: FunctionComponent<Props> = ({
  relation,
  allUserRelation,
}) => {
  const intl = useIntl()
  let formatedRelations = allUserRelation?.split(',')?.map((r) =>
    relationsMap[r]
      ? intl.formatMessage(relationsMap[r], {
          name: relation?.hub?.name,
        })
      : r
  )
  return (
    <li>
      <StyledLink to={`/profile/${relation.user.id}`}>
        <RelationListItemCard>
          <Avatar
            image={relation.user.image}
            size="small"
            alt={`${relation.user.first_name} ${relation.user.last_name}`}
          />
          <div>
            <div>
              {relation.user.first_name} {relation.user.last_name}
            </div>
            {Array.from(new Set(formatedRelations))?.map((r, index) => (
              <small key={`index-${index}`}>{r}</small>
            ))}
          </div>
        </RelationListItemCard>
      </StyledLink>
    </li>
  )
}

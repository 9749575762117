import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { colors, smallScreenBreakpoint } from '../styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Tabs = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 66px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.black10};
  z-index: 1;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    display: none;
  }
`

const TabOuter = styled.div`
  width: calc(100% / 5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const TabInner = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  text-decoration: none;
  font-size: 1.2rem;
  text-align: center;
  color: inherit;
  border-radius: 50%;
  transition: all 0.25s ease-out;

  &::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    transform: scale(0);
    transition: all 0.25s ease-out;
    z-index: -1;
  }

  &.active {
    color: white;

    &::after {
      transform: scale(1);
      background: ${colors.joda};
    }
  }
`

const Badge = styled.small`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  bottom: 50%;
  color: white;
  right: 0;
  border-radius: 50%;
  background: ${colors.closeLight};
`

type Props = {
  to: string
  ariaLabel: string
  icon: any
  badge?: number
}

export const TabItem: FunctionComponent<Props> = ({
  to,
  ariaLabel,
  icon,
  badge,
}) => {
  return (
    <TabOuter>
      <TabInner aria-label={ariaLabel} to={to} activeClassName="active">
        <FontAwesomeIcon icon={icon} />

        {badge !== undefined && badge !== 0 && <Badge>{badge}</Badge>}
      </TabInner>
    </TabOuter>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { H1 } from '../../../shared/Typography'
import { PageCard, PageContainer } from '../../../shared/layout/Layout'
import { breakpoints, colors, spacing } from '../../../../styles/styles'
import {
  Common_Cancel,
  Common_Description,
  Common_ErrorOccurred,
  Common_Name,
  Common_Remove,
  Common_Save,
  Common_Search,
  Common_ServiceReceiver,
  Teams_Create,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import { RequestStatus } from '../../../../types'
import { MessageLoader } from '../../../shared/Loading'
import { TextField } from '../../../shared/form/TextField'
import { Button } from '../../../shared/Buttons'
import { useHistory } from 'react-router-dom'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { SearchField } from '../../../shared/SearchField'
import { UserListItem } from '../../../shared/list'
import { FormButtons } from '../../../shared/form/FormButtons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const noOp = () => {}

export const CreateTeamPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const [status, setStatus] = useState<RequestStatus>('idle')
  const [searchStatus, setSearchStatus] = useState<RequestStatus>('idle')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [serviceReceiverResults, setServiceReceiverResults] = useState<any[]>(
    []
  )
  const [selectedServiceReceiver, setSelectedServiceReceiver] = useState<
    any | null
  >(null)
  const [team, setTeam] = useState<{
    name: string
    description: string
    service_receiver_id: string | null
  }>({
    name: '',
    description: '',
    service_receiver_id: null,
  })

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Teams_Create))
  }, [updateNavBarTitle, intl])

  useEffect(() => {
    if (searchTerm.length > 2) {
      setSearchStatus('pending')

      authenticatedFetch(
        `${environment.API_URL}/users/search?term=${searchTerm}`
      )
        .then(({ results }) => {
          setSearchStatus('idle')
          setServiceReceiverResults(results)
        })
        .catch(() => setSearchStatus('failure'))
    }
  }, [searchTerm])

  const createTeam = () => {
    setStatus('pending')

    authenticatedFetch(`${environment.API_URL}/teams`, { method: 'POST' }, team)
      .then((response) => {
        setStatus('idle')
        history.push(`/teams/${response.id}`)
      })
      .catch(() => setStatus('failure'))
  }

  return (
    <PageContainer style={{ maxWidth: breakpoints.LARGE }}>
      <PageCard>
        <H1 style={{ textAlign: 'center', marginBottom: spacing.lg }}>
          {intl.formatMessage(Teams_Create)}
        </H1>

        {status === 'pending' ? (
          <MessageLoader />
        ) : (
          <React.Fragment>
            <div
              style={{
                maxWidth: breakpoints.SMALL,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <TextField
                id="name"
                type="text"
                label={Common_Name}
                value={team.name}
                onChange={(value) => setTeam({ ...team, name: value })}
              />

              <TextField
                id="description"
                type="text"
                label={Common_Description}
                value={team.description}
                onChange={(value) => setTeam({ ...team, description: value })}
              />

              <label
                style={{
                  display: 'inline-block',
                  textTransform: 'uppercase',
                  marginBottom: spacing.md,
                }}
              >
                {intl.formatMessage(Common_ServiceReceiver)}
              </label>

              <div style={{ marginBottom: spacing.lg }}>
                {selectedServiceReceiver ? (
                  <UserListItem
                    data={selectedServiceReceiver}
                    onClick={noOp}
                    trailingElement={
                      <Button
                        ariaLabel={intl.formatMessage(Common_Remove)}
                        label={intl.formatMessage(Common_Remove)}
                        variant="danger"
                        onClick={() => {
                          setSelectedServiceReceiver(null)
                          setTeam({ ...team, service_receiver_id: null })
                        }}
                        isSmall
                        smallScreenIcon={<FontAwesomeIcon icon={faTrash} />}
                      />
                    }
                  />
                ) : (
                  <SearchField
                    searching={searchStatus === 'pending'}
                    placeholder={Common_Search}
                    onChange={(term) => setSearchTerm(term)}
                    results={serviceReceiverResults}
                    itemSelected={(sr) => {
                      setServiceReceiverResults([])
                      setSelectedServiceReceiver(sr)
                      setTeam({ ...team, service_receiver_id: sr.id })
                    }}
                    ListItemComponent={UserListItem}
                  />
                )}
              </div>

              <FormButtons>
                <Button
                  ariaLabel={intl.formatMessage(Common_Cancel)}
                  label={intl.formatMessage(Common_Cancel)}
                  variant="danger"
                  onClick={() => history.goBack()}
                />

                <Button
                  ariaLabel={intl.formatMessage(Common_Save)}
                  label={intl.formatMessage(Common_Save)}
                  variant="primary"
                  disabled={!team.name || !team.service_receiver_id}
                  onClick={createTeam}
                />
              </FormButtons>

              {status === 'failure' && (
                <div style={{ color: colors.error, marginTop: spacing.md }}>
                  {intl.formatMessage(Common_ErrorOccurred)}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </PageCard>
    </PageContainer>
  )
}

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  borderRadius,
  breakpoints,
  colors,
  smallScreenBreakpoint,
  spacing,
} from '../styles/styles'
import { JodacareLogo } from '../components/shared/images/JodacareLogo'
import { NavLink, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useIntl } from 'react-intl'
import {
  Common_Book,
  Common_Calendar,
  Common_Messages,
  Common_More,
  Common_Profile,
} from '../translations/messages'
import { useInvitations } from '../contexts/InvitationContext'
import { useMessages } from '../contexts/MessageProvider'
import { NavBarDropDownMenu } from './NavBarDropDownMenu'
import { NavBarTitle } from './NavBarTitle'
import { useContentToModerate } from '../contexts/ModeratorContext'

const NavBar = styled.div`
  position: fixed;
  width: 100%;
  height: 64px;
  background: white;
  z-index: 2;
  border-bottom: 1px solid ${colors.black5};
`

const Container = styled.div`
  position: relative;
  padding: 0 ${spacing.md};
  margin: 0 auto;
  max-width: ${breakpoints.XLARGE};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: ${smallScreenBreakpoint}) {
    padding: 0;
  }
`

const NavBarItem = styled(NavLink)`
  position: relative;
  text-transform: uppercase;

  &:link,
  &:visited {
    color: ${colors.joda};
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid white;
    transition: all 0.25s ease-out;
  }

  &.active,
  &:hover {
    border-color: ${colors.jodaDark};
  }
`

const BackButton = styled.button`
  flex: 1;
  padding: 0 ${spacing.md} 0 0;
  font-size: 1.5rem;
  border: none;
  background: transparent;
  text-align: left;

  &:active {
    color: white;
  }
`

const NavButtonSpacer = styled.div`
  flex: 1;
`

const Badge = styled.small`
  position: absolute;
  bottom: 50%;
  color: white;
  border-radius: ${borderRadius};
  padding: 0.1rem 0.3rem;
  background: ${colors.closeLight};
`

type Props = {
  isRoot: boolean
  serviceReceiverId: string
}

export const SingleServiceReceiverNavBar: FunctionComponent<Props> = ({
  serviceReceiverId,
  isRoot,
}) => {
  const intl = useIntl()
  const { invitations } = useInvitations()
  const { contentToModerate } = useContentToModerate()
  const history = useHistory()
  const { unreadMessages } = useMessages()
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < smallScreenBreakpoint
  )

  const onResize = useCallback(() => {
    setIsSmallScreen(window.innerWidth < smallScreenBreakpoint)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  return (
    <NavBar>
      <Container>
        {isSmallScreen ? (
          <React.Fragment>
            {isRoot ? (
              <NavButtonSpacer />
            ) : (
              <BackButton onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </BackButton>
            )}

            <NavBarTitle />

            <NavButtonSpacer />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <NavLink to="/">
              <JodacareLogo style={{ height: '35px' }} />
            </NavLink>

            <NavBarItem to="/feed" activeClassName="active">
              {intl.formatMessage(Common_Book)}
            </NavBarItem>

            <NavBarItem to="/calendar" activeClassName="active">
              {intl.formatMessage(Common_Calendar)}
            </NavBarItem>

            <NavBarItem
              to={`/profile/${serviceReceiverId}`}
              activeClassName="active"
            >
              {intl.formatMessage(Common_Profile)}
            </NavBarItem>

            <NavBarItem to="/messages" activeClassName="active">
              {intl.formatMessage(Common_Messages)}
              {unreadMessages > 0 && <Badge>{unreadMessages}</Badge>}
            </NavBarItem>

            <NavBarItem to="/more" activeClassName="active">
              {intl.formatMessage(Common_More)}

              {(invitations.length > 0 || contentToModerate.length > 0) && (
                <Badge>{invitations.length + contentToModerate.length}</Badge>
              )}
            </NavBarItem>

            <NavBarDropDownMenu />
          </React.Fragment>
        )}
      </Container>
    </NavBar>
  )
}

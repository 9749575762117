import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Button } from '../Buttons'
import { Dialog } from '../modal/Dialog'
import { Card } from '../card/Card'
import styled from 'styled-components'
import {
  borderRadius,
  breakpoints,
  colors,
  spacing,
} from '../../../styles/styles'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { ListItemLoader } from '../Loading'
import { SelectableListItem } from '../list/SelectableListItem'
import { Avatar } from '../Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faPlus,
  faTimes,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { ErrorMessage } from '../Messages'
import { useIntl } from 'react-intl'
import {
  Common_ChooseBook,
  Common_ErrorOccurred,
  Common_LoadMore,
  Common_Ok,
  Common_TryAgain,
  Common_ChooseServiceUser,
} from '../../../translations/messages'
import { ListAvatarIcon } from '../list/ListAvatarIcon'

const Wrapper = styled.div`
  margin-bottom: ${spacing.lg};
`

const DialogCard = styled(Card)`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100%;
  padding: ${spacing.md};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: scroll;

  @media only screen and (min-width: ${breakpoints.LARGE}) {
    position: relative;
    top: 10rem;
    left: 50%;
    max-height: 50%;
    transform: translateX(-50%);
    max-width: ${breakpoints.MEDIUM};
  }
`

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.black5};
  padding-bottom: ${spacing.md};

  h1 {
    margin: 0;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li``

const Selected = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.md};
`

const SelectedOwner = styled.div`
  padding: ${spacing.sm};
  display: flex;
  align-items: center;
  border-radius: ${borderRadius};
  background: ${colors.black5};
`

const RemoveButton = styled.button`
  margin-left: ${spacing.md};
  align-self: flex-start;
  border: none;
  background: none;
`

const AddButton = styled.button`
  padding: 0 ${spacing.md};
  border-radius: ${borderRadius};
  border: 1px solid ${colors.black20};

  &:active {
    background: white;
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

type Props = {
  contentType: 'Post' | 'Message'
  selected: any[]
  disabled: boolean
  onChange: (selected: any[]) => void
}

export const OwnerSelector: FunctionComponent<Props> = ({
  contentType,
  selected,
  disabled,
  onChange,
}) => {
  const intl = useIntl()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [books, setBooks] = useState<any[]>([])
  const [nextUrl, setNextUrl] = useState<string>()

  const toggleSelected = (book: any) => {
    const index = selected.findIndex((s) => s.id === book.id)

    if (index !== -1) {
      const state = selected.filter((b) => b.id !== book.id)
      onChange(state)
    } else {
      const state = [...selected, book]
      onChange(state)
    }
  }

  const loadMore = () => {
    if (nextUrl) {
      setStatus('pending')

      authenticatedFetch(nextUrl)
        .then(({ results, next }) => {
          setStatus('idle')
          setBooks(() => [...books, ...results])
          setNextUrl(next)
        })
        .catch(() => {
          setStatus('failure')
        })
    }
  }
  const getBooks = useCallback(() => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/books?contentType=${contentType}`
    )
      .then(({ results, next }) => {
        setStatus('idle')
        setBooks(results)
        if (results?.length === 1) {
          onChange([results[0]])
        }
        setNextUrl(next)
      })
      .catch(() => {
        setStatus('failure')
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => getBooks(), [getBooks])

  return (
    <Wrapper>
      {selected.length > 0 ? (
        <Selected>
          {selected.map((book) => (
            <SelectedOwner key={`o-${book.id}`}>
              {book.type === 'User' ? (
                <Avatar
                  size="xsmall"
                  image={book.image}
                  alt="Profilbilde"
                  style={{ marginRight: spacing.sm }}
                />
              ) : book.type === 'Institution' ? (
                <ListAvatarIcon icon={faBuilding} size="small" />
              ) : book.type === 'Group' ? (
                <ListAvatarIcon icon={faUsers} size="small" />
              ) : null}

              <div>{book.name}</div>

              {books?.length > 1 && (
                <RemoveButton onClick={() => toggleSelected(book)}>
                  <FontAwesomeIcon icon={faTimes} />
                </RemoveButton>
              )}
            </SelectedOwner>
          ))}

          {books?.length > 1 && (
            <AddButton onClick={() => setShowDialog(true)}>
              <FontAwesomeIcon icon={faPlus} />
            </AddButton>
          )}
        </Selected>
      ) : (
        books?.length > 1 && (
          <Center>
            <Button
              label={
                contentType === 'Post'
                  ? intl.formatMessage(Common_ChooseBook)
                  : intl.formatMessage(Common_ChooseServiceUser)
              }
              ariaLabel={
                contentType === 'Post'
                  ? intl.formatMessage(Common_ChooseBook)
                  : intl.formatMessage(Common_ChooseServiceUser)
              }
              variant="primary"
              disabled={disabled}
              onClick={() => setShowDialog(true)}
            />
          </Center>
        )
      )}

      {showDialog && (
        <Dialog onClose={() => setShowDialog(false)}>
          <DialogCard>
            <DialogHeader>
              <h1>{intl.formatMessage(Common_ChooseBook)}</h1>
              <Button
                label={intl.formatMessage(Common_Ok)}
                ariaLabel={intl.formatMessage(Common_Ok)}
                variant="secondary"
                isSmall={true}
                onClick={() => setShowDialog(false)}
              />
            </DialogHeader>

            {status === 'failure' ? (
              <React.Fragment>
                <ErrorMessage>
                  {intl.formatMessage(Common_ErrorOccurred)}
                </ErrorMessage>

                <Button
                  ariaLabel={intl.formatMessage(Common_TryAgain)}
                  label={intl.formatMessage(Common_TryAgain)}
                  variant="secondary"
                  onClick={getBooks}
                />
              </React.Fragment>
            ) : books ? (
              <React.Fragment>
                <List>
                  {books.map((book) => (
                    <ListItem>
                      <SelectableListItem
                        data={book}
                        selected={
                          selected.findIndex((s) => s.id === book.id) !== -1
                        }
                        onClick={() => toggleSelected(book)}
                      />
                    </ListItem>
                  ))}
                </List>

                {nextUrl && (
                  <div style={{ marginTop: spacing.md, textAlign: 'center' }}>
                    <Button
                      label={intl.formatMessage(Common_LoadMore)}
                      ariaLabel={intl.formatMessage(Common_LoadMore)}
                      variant="secondary"
                      onClick={loadMore}
                      isSmall
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ListItemLoader />
                <ListItemLoader />
                <ListItemLoader />
              </React.Fragment>
            )}
          </DialogCard>
        </Dialog>
      )}
    </Wrapper>
  )
}

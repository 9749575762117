import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { spacing } from '../../../styles/styles'

const StyledSelect = styled.select`
  display: block;
  width: 100%;
  padding: ${spacing.md};
  font-family: inherit;
  font-size: inherit;

  option {
    padding: ${spacing.md} ${spacing.sm};
  }
`

type Props = {
  id?: string
  value: any
  disabled?: boolean
  ariaLabel: string
  options: { key: string; value: any }[]
  onChange: (value: any) => void
  padding?: any
}

export const Select: FunctionComponent<Props> = ({
  id,
  value,
  disabled,
  ariaLabel,
  options,
  onChange,
  padding = null,
}) => {
  return (
    <StyledSelect
      value={value}
      disabled={disabled}
      aria-label={ariaLabel}
      onChange={({ target: { value } }) => onChange(value)}
      style={{ padding: padding && padding }}
    >
      {options.map((option) => (
        <option key={`opt-${option.value}`} value={option.value}>
          {option.key}
        </option>
      ))}
    </StyledSelect>
  )
}

import styled from 'styled-components'
import {
  breakpoints,
  smallScreenBreakpoint,
  spacing,
} from '../../../styles/styles'
import { Card } from '../card/Card'

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${breakpoints.XLARGE};
`

export const PageContainer = styled(Container)`
  padding-top: calc(${spacing.xl} + ${spacing.md});
  padding-bottom: calc(${spacing.xl} + ${spacing.md});
  padding-left: ${spacing.md};
  padding-right: ${spacing.md};

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: ${spacing.lg};
  }
`

export const PageCard = styled(Card)`
  padding: ${spacing.sm};

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    padding: ${spacing.lg} 0;
  }

  h1 {
    display: none;

    @media only screen and (min-width: ${smallScreenBreakpoint}px) {
      display: block;
    }
  }
`

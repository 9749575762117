import { Event_AllDay } from '../../../translations/messages'
import moment from 'moment'
import { IntlShape } from 'react-intl'

const isAllDay = (event: any) => {
  return (
    event.start === moment(event.start).startOf('day').toISOString() &&
    event.end === moment(event.end).endOf('day').toISOString()
  )
}

export const parseStartEnd = (event: any, intl: IntlShape) => {
  const startIsNotSameDateAsEnd =
    moment(event.start).format('YYYY-MM-DD') !==
    moment(event.end).format('YYYY-MM-DD')

  return event.is_all_day || isAllDay(event)
    ? intl.formatMessage(Event_AllDay)
    : startIsNotSameDateAsEnd
    ? `${moment(event.start).format('D/M HH:mm')} - ${moment(event.end).format(
        'D/M HH:mm'
      )}`
    : `${moment(event.start).format('HH:mm')} - ${moment(event.end).format(
        'HH:mm'
      )}`
}

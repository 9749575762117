import React, { FunctionComponent } from 'react'
import { Card } from '../../../shared/card/Card'
import styled from 'styled-components'
import { colors, spacing } from '../../../../styles/styles'
import { Avatar } from '../../../shared/Avatar'
import { useHistory } from 'react-router-dom'
import { Common_ServiceReceiver } from '../../../../translations/messages'
import { useIntl } from 'react-intl'

const ListItem = styled(Card)`
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  border-bottom: 1px solid ${colors.black5};
`

type Props = {
  data: any
}

export const ServiceReceiverListItem: FunctionComponent<Props> = ({ data }) => {
  const intl = useIntl()
  const history = useHistory()
  return (
    <li>
      <ListItem>
        <Avatar
          style={{ marginRight: spacing.md }}
          size="small"
          image={data.image}
          alt={`Bilde av ${data.first_name}`}
          onClick={() => history.push(`/service-receivers/${data.id}`)}
        />

        <div
          style={{ flex: 1 }}
          onClick={() => history.push(`/service-receivers/${data.id}`)}
        >
          <div>
            {data.first_name} {data.last_name}
          </div>
          <small>{intl.formatMessage(Common_ServiceReceiver)}</small>
        </div>

        {/* <VideoCallButton user={data} /> */}
      </ListItem>
    </li>
  )
}

import React, { useContext } from 'react'
import { NavBarContext } from '../contexts/NavBarContext'
import styled from 'styled-components'
import { colors } from '../styles/styles'

const Title = styled.div`
  flex: 4;
  text-align: center;
`
const ButtonStyled = styled.button`
  text-align: center;
  color: ${colors.black90};
  font-family: 'PT Serif', Times New Roman, serif;
  font-size: 1.2rem;
  font-weight: bold;
  display: inline-block;
  border: none;
  background: transparent;
`
export const NavBarTitle = () => {
  const { title } = useContext(NavBarContext)

  return (
    <Title>
      <ButtonStyled
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        {title}
      </ButtonStyled>
      {/* {(title === 'Book' || title === 'Boka') && (
        <Button
          ariaLabel={intl.formatMessage(More_Survey)}
          label={intl.formatMessage(More_Survey)}
          onClick={() =>
            window.open(
              'https://no.surveymonkey.com/r/7KLFMJY',
              '_blank',
              'noreferrer'
            )
          }
          style={{
            position: 'absolute',
            left: 0,
            fontSize: '0.8rem',
          }}
          isSmall={true}
          variant="primary"
        />
      )} */}
    </Title>
  )
}

import React, {
  useState,
  FunctionComponent,
  useCallback,
  useEffect,
} from 'react'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { Card } from '../../../shared/card/Card'
import styled from 'styled-components'
import {
  smallScreenBreakpoint,
  spacing,
  colors,
} from '../../../../styles/styles'
import {
  Common_Edit,
  Common_Name,
  Common_Description,
  Common_Close,
  Common_Save,
  Common_ErrorOccurred,
  Common_Edit_Group,
  Common_Delete,
  Group_DeleteConfirmation,
} from '../../../../translations/messages'
import { Button, ButtonGroup } from '../../../shared/Buttons'
import { useIntl } from 'react-intl'
import { TextField } from '../../../shared/form/TextField'
import { MessageLoader } from '../../../shared/Loading'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import { ConfirmDialog } from '../../../shared/modal/ConfirmDialog'

const GroupCard = styled(Card)`
  position: relative;
  margin-bottom: ${spacing.md};
  padding: ${spacing.lg};

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    padding: ${spacing.md};
  }

  h1 {
    display: none;

    @media only screen and (min-width: ${smallScreenBreakpoint}px) {
      display: block;
      margin: 0;
    }
  }

  h1,
  p {
    text-align: center;
  }
`
type Props = {
  group: any
  handleChangeGroup: (value: any) => void
  canEdit: boolean
  canDelete: boolean
}

export const GroupDetailPageHeader: FunctionComponent<Props> = ({
  group,
  handleChangeGroup,
  canEdit,
  canDelete,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const intl = useIntl()
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < smallScreenBreakpoint
  )
  const onResize = useCallback(() => {
    setIsSmallScreen(window.innerWidth < smallScreenBreakpoint)
  }, [])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(
    false
  )

  const history = useHistory()

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  const handleEditGroupDetails = () => {
    setStatus('pending')
    authenticatedFetch(
      `${environment.API_URL}/groups/${group.id}`,
      { method: 'PATCH' },
      { name: group.name, description: group.description }
    )
      .then(() => {
        setStatus('idle')
        setEditMode(false)
      })
      .catch(() => {
        setStatus('failure')
      })
  }
  const handleDeleteGroup = () => {
    setStatus('pending')
    authenticatedFetch(`${environment.API_URL}/groups/${group.id}`, {
      method: 'DELETE',
    })
      .then(() => {
        setStatus('idle')
        history.push('/')
      })
      .catch(() => {
        setStatus('failure')
      })
  }
  return (
    <GroupCard>
      {status === 'pending' ? (
        <MessageLoader />
      ) : (
        <>
          {editMode && <h1> {intl.formatMessage(Common_Edit_Group)}</h1>}

          {!editMode ? (
            <React.Fragment>
              <div
                style={{
                  position: 'absolute',
                  top: spacing.md,
                  right: spacing.md,
                }}
              >
                {isSmallScreen ? (
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => setEditMode(!editMode)}
                  />
                ) : (
                  <ButtonGroup>
                    {canDelete && (
                      <React.Fragment>
                        <Button
                          ariaLabel={intl.formatMessage(Common_Delete)}
                          label={intl.formatMessage(Common_Delete)}
                          onClick={() => setShowDeleteConfirmation(true)}
                          variant="danger"
                          isSmall={true}
                        />

                        <ConfirmDialog
                          show={showDeleteConfirmation}
                          title={{
                            ...Group_DeleteConfirmation,
                            values: { name: group.name },
                          }}
                          onClose={() => setShowDeleteConfirmation(false)}
                          onConfirm={() => {
                            handleDeleteGroup()
                            setShowDeleteConfirmation(false)
                          }}
                          onCancel={() => setShowDeleteConfirmation(false)}
                        />
                      </React.Fragment>
                    )}
                    <Button
                      ariaLabel={intl.formatMessage(Common_Edit)}
                      label={intl.formatMessage(Common_Edit)}
                      onClick={() => setEditMode(!editMode)}
                      variant="secondary"
                      isSmall={true}
                    />
                  </ButtonGroup>
                )}
              </div>

              <h1>{group.name}</h1>
              <p>{group.description}</p>
            </React.Fragment>
          ) : (
            <div style={{ maxWidth: '50%' }}>
              <TextField
                id="name"
                type="text"
                label={Common_Name}
                value={group.name}
                onChange={(value) =>
                  handleChangeGroup({ ...group, name: value })
                }
              />
              <TextField
                id="lastName"
                type="text"
                label={Common_Description}
                value={group.description}
                onChange={(value) =>
                  handleChangeGroup({ ...group, description: value })
                }
                required={true}
              />
            </div>
          )}
          {status === 'failure' && (
            <div
              style={{
                color: colors.error,
                marginTop: spacing.md,
                textAlign: 'right',
              }}
            >
              {intl.formatMessage(Common_ErrorOccurred)}
            </div>
          )}
          {canEdit && editMode && (
            <ButtonGroup>
              <Button
                ariaLabel={intl.formatMessage(Common_Close)}
                label={intl.formatMessage(Common_Close)}
                onClick={() => setEditMode(!editMode)}
                variant="secondary"
                isSmall={true}
              />
              <Button
                ariaLabel={intl.formatMessage(Common_Save)}
                label={intl.formatMessage(Common_Save)}
                onClick={() => handleEditGroupDetails()}
                variant="primary"
                isSmall={true}
              />
            </ButtonGroup>
          )}
        </>
      )}
    </GroupCard>
  )
}

import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { PageContainer } from '../../shared/layout/Layout'
import { ProfileHeader } from '../../shared/ProfileHeader'
import { MessageLoader } from '../../shared/Loading'
import { useUser } from '../../../contexts/UserProvider'
import styled from 'styled-components'
import {
  breakpoints,
  colors,
  smallScreenBreakpoint,
  spacing,
} from '../../../styles/styles'
import { Permissions } from '../../shared/permissions/Permissions'
import { NavBarContext } from '../../../contexts/NavBarContext'
import { hasPermission } from '../../../lib/permissionHelpers'
import { NavButton } from '../../shared/NavButton'
import {
  Common_Edit,
  Common_ErrorOccurred,
  Common_Relations,
  Relation_PrimaryContact,
  Relation_Family,
  Relation_Employee,
  Relation_ClosestRelative,
} from '../../../translations/messages'
import { usePermissions } from '../../../contexts/PermissionProvider'
import { NoAccess } from '../../shared/NoAccess'
import { useIntl } from 'react-intl'
import { AuthContext } from '../../../contexts/AuthContext'
import { Button } from '../../shared/Buttons'
import { EditProfileImage } from './EditProfileImage'
import { uploadProfileImage, authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { JodabookSetup } from '../serviceReceiver/detail/Jodabook'
import { InvitationList } from '../../shared/invitations/InvitationList'
import { RelationList } from '../../shared/relations/RelationList'
import { InviteButton } from '../../shared/invitations/InviteButton'

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const JobTitle = styled.small`
  display: inline-block;
`

const ProfileMeta = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 75ch;
  text-align: center;

  &:not(:last-of-type) {
    margin-bottom: ${spacing.md};
  }
`

const LargeScreenButtons = styled.div`
  display: none;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    display: flex;
    gap: ${spacing.md};
    position: absolute;
    top: ${spacing.md};
    right: ${spacing.md};
  }
`

type ProfileParams = {
  id: string
}

type Props = {
  userId?: string
}

export const ProfilePage: FunctionComponent<Props> = ({ userId }) => {
  const intl = useIntl()
  const history = useHistory()
  const { authUser } = useContext(AuthContext)
  const { updateNavBarTitle } = useContext(NavBarContext)
  const { id: idParam } = useParams<ProfileParams>()
  const id = userId ?? idParam
  const { user, profile, status, updateUser } = useUser(id)
  const { relationsByTargetId, status: permissionStatus } = usePermissions(id)
  const location = useLocation()
  const [uploadedImage, setUploadedImage] = useState<any>({})
  const [croppedImage, setCroppedImage] = useState<any>({})
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false)
  const relations = relationsByTargetId[id]

  useEffect(() => {
    updateNavBarTitle(user?.first_name ?? '')
  }, [user, updateNavBarTitle])

  const canEdit = hasPermission(relations, 'UserProfile', 'UPDATE')
  const canReadRelations = hasPermission(relations, 'Relation', 'READ')

  const handleUploadImage = (event: any) => {
    if (event && event.files && event.files.length > 0) {
      setUploadedImage(event.files[0])
      setShowCropperModal(true)
    }
  }
  const croppedImageActionFinished = async (croppedImage: any) => {
    setShowCropperModal(!showCropperModal)
    setCroppedImage(croppedImage)
  }

  const handleSaveImage = async (image: any, fileName: string) => {
    const imageId = await uploadProfileImage(image, fileName)
    if (imageId && id) {
      try {
        authenticatedFetch(
          `${environment.API_URL}/users/${id}/profile`,
          {
            method: 'PATCH',
          },
          { image_id: imageId }
        ).then(() => window.location.reload())
      } catch (error) {
        return
      }
    }
  }
  return (
    <PageContainer>
      {permissionStatus === 'failure' ? (
        <NoAccess />
      ) : status === 'failure' ? (
        <div style={{ color: colors.error }}>
          {intl.formatMessage(Common_ErrorOccurred)}
        </div>
      ) : user ? (
        <React.Fragment>
          {canEdit && (
            <NavButton
              path={`/profile/${user.id}/edit`}
              label={Common_Edit}
              state={{
                userId: user.id,
                from: location.pathname,
              }}
            />
          )}

          <ProfileHeader
            user={user}
            handleUploadImage={handleUploadImage}
            userCroppedImage={croppedImage}
            forEditProfileImage={true}
            canEdit={canEdit}
          >
            {profile ? (
              <Profile>
                {canEdit && (
                  <LargeScreenButtons>
                    <Button
                      ariaLabel={intl.formatMessage(Common_Edit)}
                      label={intl.formatMessage(Common_Edit)}
                      variant="primary"
                      isSmall={true}
                      onClick={() => history.push(`/profile/${id}/edit`)}
                    />
                  </LargeScreenButtons>
                )}

                {user.is_employee && (
                  <ProfileMeta>
                    <JobTitle>{profile.job_title}</JobTitle>
                  </ProfileMeta>
                )}
                <ProfileMeta>{user.email}</ProfileMeta>

                {profile.description && (
                  <ProfileMeta>{profile.description}</ProfileMeta>
                )}
              </Profile>
            ) : null}

            {showCropperModal && (
              <EditProfileImage
                image={uploadedImage}
                closeModal={() => {
                  setShowCropperModal(!showCropperModal)
                  setUploadedImage(null)
                }}
                croppedImageAction={croppedImageActionFinished}
                handleSaveImage={handleSaveImage}
              />
            )}
          </ProfileHeader>

          {user.is_service_receiver && canEdit && (
            <div style={{ marginBottom: spacing.md }}>
              <InvitationList id={user.id} />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap-reverse',
              gap: spacing.lg,
            }}
          >
            {authUser?.id !== id && (
              <div style={{ flex: 1, minWidth: breakpoints.XSMALL }}>
                <Permissions name={user.first_name} relations={relations} />
              </div>
            )}

            {user.is_service_receiver && (
              <div style={{ flex: 1, minWidth: breakpoints.XSMALL }}>
                {canEdit && (
                  <React.Fragment>
                    <JodabookSetup
                      serviceReceiver={user}
                      updateJodabook={updateUser}
                    />

                    <div style={{ marginTop: spacing.lg }}>
                      <InvitationList id={user.id} />
                    </div>
                  </React.Fragment>
                )}

                {canReadRelations && (
                  <div
                    style={{
                      flex: 1,
                      marginTop: spacing.md,
                      marginBottom: spacing.md,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: spacing.sm,
                      }}
                    >
                      <h3>{intl.formatMessage(Common_Relations)}</h3>
                      {relations &&
                        relations.length === 1 &&
                        relations[0].relation === 'CLOSEST_RELATIVE' && (
                          <InviteButton
                            id={id}
                            entityType="User"
                            relations={[
                              { key: 'EMPLOYEE', value: Relation_Employee },
                              { key: 'FAMILY', value: Relation_Family },
                              {
                                key: 'CLOSEST_RELATIVE',
                                value: Relation_ClosestRelative,
                              },
                              {
                                key: 'PRIMARY_CONTACT',
                                value: Relation_PrimaryContact,
                              },
                            ]}
                          />
                        )}
                    </div>
                    <RelationList userId={user.id} />
                  </div>
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      ) : (
        <MessageLoader />
      )}
    </PageContainer>
  )
}

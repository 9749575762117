import React, { FunctionComponent } from 'react'
import logo from '../../../images/logo.svg'
import styled from 'styled-components'

const Img = styled.img<{ style: any }>`
  width: auto;
  height: 100%;
`

type Props = {
  style?: Partial<CSSStyleDeclaration>
}

export const JodacareLogo: FunctionComponent<Props> = ({ style }) => {
  return <Img style={style} src={logo} alt="Jodacare logo" />
}

import React, { FunctionComponent } from 'react'
import { animated, useSpring, config } from 'react-spring'

type Props = {
  showOn: any
}

export const FadeIn: FunctionComponent<Props> = ({ showOn, children }) => {
  const fadeStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0 },
    to: {
      opacity: showOn ? 1 : 0,
    },
  })

  return <animated.div style={fadeStyles}>{children}</animated.div>
}

import React, { useContext, useEffect, useState } from 'react'
import { PageContainer } from '../../shared/layout/Layout'
import { useUser } from '../../../contexts/UserProvider'
import { Card } from '../../shared/card/Card'
import { TextField } from '../../shared/form/TextField'
import styled from 'styled-components'
import {
  breakpoints,
  smallScreenBreakpoint,
  spacing,
} from '../../../styles/styles'
import { ListItemLoader, MessageLoader } from '../../shared/Loading'
import { useHistory, useParams } from 'react-router-dom'
import { NavButton } from '../../shared/NavButton'
import { TextAreaField } from '../../shared/form/TextArea'
import { ErrorMessage } from '../../shared/Messages'
import { NavBarContext } from '../../../contexts/NavBarContext'
import {
  Common_About,
  Common_Cancel,
  Common_Email,
  Common_ErrorOccurred,
  Common_FirstName,
  Common_LastName,
  Common_Save,
  Profile_Edit,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'
import { Button } from '../../shared/Buttons'
import { RequestStatus } from '../../../types'
import { Avatar } from '../../shared/Avatar'
import { uploadProfileImage, authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { EditProfileImage } from './EditProfileImage'
import heic2any from 'heic2any'

const Form = styled(Card)`
  padding: ${spacing.md};
  margin-left: auto;
  margin-right: auto;
  max-width: ${breakpoints.MEDIUM};
`

const LargeScreenButtons = styled.div`
  display: none;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    display: flex;
    gap: ${spacing.md};
    justify-content: flex-end;
  }
`

export const EditProfilePage = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const intl = useIntl()
  const { user, profile, updateUser, updateProfile } = useUser(id)
  const { updateNavBarTitle } = useContext(NavBarContext)
  const [editedUser, setEditedUser] = useState<any>(user)
  const [editedProfile, setEditedProfile] = useState<any>(profile)
  const [status, setStatus] = useState<RequestStatus>('idle')
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false)
  const [uploadedImage, setUploadedImage] = useState<any>({})
  const [croppedImage, setCroppedImage] = useState<any>({})

  useEffect(() => {
    setEditedUser(user)
    setEditedProfile(profile)
    updateNavBarTitle(intl.formatMessage(Profile_Edit))
  }, [user, profile, updateNavBarTitle, intl])

  const onUpdate = () => {
    setStatus('pending')

    Promise.all([
      updateUser({
        first_name: editedUser.first_name,
        last_name: editedUser.last_name,
        email: editedUser.email,
      }),
      updateProfile({
        description: editedProfile.description,
        job_title: editedProfile.job_title,
        cell_phone_number: editedProfile.cell_phone_number,
      }),
    ])
      .then(() => {
        setStatus('idle')

        const state = history.location.state as { from: string }

        if (state) {
          history.replace(state.from)
        } else {
          history.goBack()
        }
      })
      .catch(() => setStatus('failure'))
  }
  const handleUploadImage = async (event: any) => {
    if (event && event.files && event.files.length > 0) {
      let file = event.files[0]
      if (
        file.type.toLowerCase() === 'image/heic' ||
        file.name.toLowerCase().includes('.heic')
      ) {
        await heic2any({ blob: file, toType: 'image/jpg', quality: 1 }).then(
          (newImage: any) => {
            file = new File([newImage], '1245')
            setUploadedImage(file)
            setShowCropperModal(true)
          }
        )
      } else {
        setUploadedImage(file)
        setShowCropperModal(true)
      }
    }
  }

  const croppedImageActionFinished = async (croppedImage: any) => {
    setShowCropperModal(!showCropperModal)
    setCroppedImage(croppedImage)
  }
  const handleSaveImage = async (image: any, fileName: string) => {
    const imageId = await uploadProfileImage(image, fileName)
    if (imageId && id) {
      try {
        authenticatedFetch(
          `${environment.API_URL}/users/${user.id}/profile`,
          {
            method: 'PATCH',
          },
          { image_id: imageId }
        ).then(() => {
          window.location.reload()
          // handleGetAuthUser()
        })
      } catch (error) {
        return
      }
    }
  }

  if (editedUser && editedProfile) {
    return (
      <React.Fragment>
        <NavButton label={Common_Save} onClick={onUpdate} />

        <PageContainer>
          {status === 'failure' && (
            <ErrorMessage style={{ textAlign: 'center', padding: spacing.md }}>
              {intl.formatMessage(Common_ErrorOccurred)}
            </ErrorMessage>
          )}

          {status === 'pending' ? (
            <MessageLoader />
          ) : (
            <Form>
              <div onChange={(event) => handleUploadImage(event.target)}>
                <label htmlFor="file-input">
                  <Avatar
                    size="medium"
                    image={editedUser?.image}
                    alt={`Bilde av ${editedUser?.first_name}`}
                    userCroppedImage={croppedImage}
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      display: 'block',
                    }}
                  />
                  <input
                    id="file-input"
                    type="file"
                    accept="image/x-png,image/jpg,image/jpeg, image/heic"
                    style={{ display: 'none' }}
                  />
                </label>
              </div>
              {showCropperModal && (
                <EditProfileImage
                  image={uploadedImage}
                  closeModal={() => {
                    setShowCropperModal(!showCropperModal)
                    setUploadedImage(null)
                  }}
                  croppedImageAction={croppedImageActionFinished}
                  handleSaveImage={handleSaveImage}
                />
              )}
              <TextField
                id="firstName"
                type="text"
                label={Common_FirstName}
                value={editedUser.first_name}
                onChange={(value) =>
                  setEditedUser({ ...editedUser, first_name: value })
                }
                required={true}
              />

              <TextField
                id="lastName"
                type="text"
                label={Common_LastName}
                value={editedUser.last_name}
                onChange={(value) =>
                  setEditedUser({ ...editedUser, last_name: value })
                }
                required={true}
              />

              <TextField
                id="email"
                type="text"
                label={Common_Email}
                value={editedUser.email}
                onChange={(value) =>
                  setEditedUser({ ...editedUser, email: value })
                }
                required={!user.service_receiver}
              />

              <TextAreaField
                id="description"
                label={Common_About}
                value={editedProfile.description}
                onChange={(value) =>
                  setEditedProfile({ ...editedProfile, description: value })
                }
              />

              <LargeScreenButtons>
                <Button
                  ariaLabel={intl.formatMessage(Common_Cancel)}
                  label={intl.formatMessage(Common_Cancel)}
                  variant="danger"
                  onClick={() => history.goBack()}
                />

                <Button
                  ariaLabel={intl.formatMessage(Common_Save)}
                  label={intl.formatMessage(Common_Save)}
                  variant="primary"
                  onClick={onUpdate}
                />
              </LargeScreenButtons>
            </Form>
          )}
        </PageContainer>
      </React.Fragment>
    )
  }

  return (
    <PageContainer>
      <ListItemLoader />
    </PageContainer>
  )
}

import React, { FunctionComponent, useState } from 'react'
import { HandleRelations } from '../../../shared/relations/HandleRelations'
import {
  Common_ErrorOccurred,
  Common_ServiceReceivers,
  Institution_Employees,
  ServiceReceivers_Create,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../shared/Buttons'
import { colors, spacing } from '../../../../styles/styles'
import { Dialog } from '../../../shared/modal/Dialog'
import { CreateServiceReceiverForm } from '../../../shared/forms/CreateServiceReceiverForm'
import { DialogCard } from '../../../shared/modal/ConfirmDialog'
import { H1 } from '../../../shared/Typography'
import { RequestStatus } from '../../../../types'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { MessageLoader } from '../../../shared/Loading'

type Props = {
  id: string
  canViewEmployees: boolean
  canViewMembers: boolean
}

export const InstitutionMembers: FunctionComponent<Props> = ({
  id,
  canViewEmployees,
  canViewMembers,
}) => {
  const intl = useIntl()
  const [showCreateServiceReceiver, setShowCreateServiceReceiver] = useState<
    boolean
  >(false)
  const [status, setStatus] = useState<RequestStatus>('idle')

  const createAndAddToInstitution = (user: any) => {
    setStatus('pending')

    authenticatedFetch(`${environment.API_URL}/users`, { method: 'POST' }, user)
      .then((response) => {
        return authenticatedFetch(
          `${environment.API_URL}/institutions/${id}/service-receivers`,
          { method: 'POST' },
          { user_ids: [response.id] }
        )
      })
      .then((response) => {
        setShowCreateServiceReceiver(false)
        setStatus('idle')
      })
      .catch(() => setStatus('failure'))
  }

  const employeeSearchFilter = (user: any) => user.is_employee
  const memberSearchFilter = (user: any) => user.is_service_receiver

  return (
    <React.Fragment>
      {canViewEmployees && (
        <div style={{ marginTop: spacing.lg }}>
          <HandleRelations
            id={id}
            dataKey="user_ids"
            heading={intl.formatMessage(Institution_Employees)}
            path="institutions"
            entity="Institution"
            relationType="employees"
            requiredLimit={1}
            searchFilter={employeeSearchFilter}
          />
        </div>
      )}

      {canViewMembers && (
        <div style={{ marginTop: spacing.lg }}>
          {status === 'pending' ? (
            <MessageLoader />
          ) : (
            <HandleRelations
              id={id}
              dataKey="user_ids"
              heading={intl.formatMessage(Common_ServiceReceivers)}
              path="institutions"
              entity="Institution"
              relationType="service-receivers"
              searchFilter={memberSearchFilter}
            >
              <Button
                label={intl.formatMessage(ServiceReceivers_Create)}
                ariaLabel={intl.formatMessage(ServiceReceivers_Create)}
                variant="primary"
                isSmall={true}
                smallScreenIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => setShowCreateServiceReceiver(true)}
              />
            </HandleRelations>
          )}
        </div>
      )}

      {showCreateServiceReceiver && (
        <Dialog
          onClose={() => {
            setStatus('idle')
            setShowCreateServiceReceiver(false)
          }}
        >
          <DialogCard>
            <H1 style={{ marginBottom: spacing.md }}>
              {intl.formatMessage(ServiceReceivers_Create)}
            </H1>

            {status === 'pending' ? (
              <MessageLoader />
            ) : (
              <CreateServiceReceiverForm
                onCancel={() => {
                  setStatus('idle')
                  setShowCreateServiceReceiver(false)
                }}
                onSubmit={(user) => createAndAddToInstitution(user)}
              />
            )}

            {status === 'failure' && (
              <div style={{ color: colors.error, marginTop: spacing.md }}>
                {intl.formatMessage(Common_ErrorOccurred)}
              </div>
            )}
          </DialogCard>
        </Dialog>
      )}
    </React.Fragment>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { PageContainer } from '../../shared/layout/Layout'
import { NavBarContext } from '../../../contexts/NavBarContext'
import { breakpoints } from '../../../styles/styles'
import { AnimateHeight } from '../../shared/animations/AnimateHeight'
import { MessageLoader } from '../../shared/Loading'
import { ErrorMessage } from '../../shared/Messages'
import moment from 'moment'
import styled from 'styled-components'
import { authenticatedFetch, uploadImages } from '../../../lib/service'
import { environment } from '../../../environments'
import {
  Event_Edit,
  Common_ErrorOccurred,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'
import { EventForm } from './common/EventForm'

const CreateContainer = styled(PageContainer)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${breakpoints.SMALL};
`

const roundToClosestHalfHour = (date: string) => {
  const start = moment(date)
  const remainder = 30 - (start.minute() % 30)
  return moment(start).add(remainder, 'minutes').toISOString()
}

const initialStart = roundToClosestHalfHour(moment().toISOString())
const initialEnd = moment(initialStart).add(30, 'minutes').toISOString()

const initialEvent = {
  title: '',
  description: '',
  start: initialStart,
  end: initialEnd,
  options: null,
}

const removeProp = (obj: any, prop: any) => {
  let { [prop]: omit, ...res } = obj
  return res
}

export const EditEventPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const { eventId } = useParams<{ eventId: string }>()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const { ownerId } = useParams<{ ownerId: string }>()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>(
    'pending'
  )
  const [event, setEvent] = useState<any>({
    ...initialEvent,
    owner_id: ownerId,
  })

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Event_Edit))
  }, [updateNavBarTitle, intl])

  useEffect(() => {
    setStatus('pending')

    authenticatedFetch(`${environment.API_URL}/events/${eventId}`)
      .then((result) => {
        setEvent(result)
        setStatus('idle')
      })
      .catch((error) => {
        setStatus('failure')
      })
  }, [eventId])

  const updateEvent = async () => {
    setStatus('pending')
    if (event.images && event.images.length > 0 && event.images[0].name) {
      try {
        const imageIds = await uploadImages(event.images)
        event.image_id = imageIds && imageIds.length > 0 ? imageIds[0] : ''
      } catch (error) {
        setStatus('failure')
        return
      }
    }
    let data = { ...event }
    data = removeProp(data, 'duration')
    data = removeProp(data, 'images')
    data = removeProp(data, 'created')
    data = removeProp(data, 'creator')
    data = removeProp(data, 'owner')
    data = removeProp(data, 'natural_language')
    data = removeProp(data, 'occurrences')
    data = removeProp(data, 'num_comments')
    data = removeProp(data, 'is_all_day')
    data = removeProp(data, 'date_range')

    let opt = data.options ? removeProp(data.options, 'rule_str') : null
    if (opt) opt.weekdays = opt.weekdays ?? []
    data = { ...data, options: opt }
    authenticatedFetch(
      `${environment.API_URL}/events/${eventId}`,
      { method: 'PATCH' },
      data
    )
      .then(() => {
        setStatus('idle')
        history.goBack()
      })
      .catch(() => {
        setStatus('failure')
      })
  }

  return (
    <CreateContainer>
      <AnimateHeight animateOn={true}>
        {status === 'pending' ? (
          <MessageLoader />
        ) : (
          <React.Fragment>
            {status === 'failure' && (
              <ErrorMessage>
                {intl.formatMessage(Common_ErrorOccurred)}
              </ErrorMessage>
            )}

            <EventForm
              event={event}
              onChange={setEvent}
              submitForm={updateEvent}
              onCancel={() => {
                history.goBack()
              }}
            />
          </React.Fragment>
        )}
      </AnimateHeight>
    </CreateContainer>
  )
}

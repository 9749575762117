import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CreateMessagePage } from './CreateMessagePage'
import { MessageDetailPage } from './MessageDetailPage'
import { MessageFeedPage } from './MessageFeedPage'

export const MessageRouter = () => {
  return (
    <Switch>
      <Route path={'/messages/create/:ownerId'}>
        <CreateMessagePage />
      </Route>
      <Route path={'/messages/create'}>
        <CreateMessagePage />
      </Route>
      <Route path={'/messages/:id'}>
        <MessageDetailPage />
      </Route>
      <Route path={'/messages'}>
        <MessageFeedPage />
      </Route>
    </Switch>
  )
}

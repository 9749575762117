import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../shared/layout/Layout'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { ListItemLoader } from '../../../shared/Loading'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import { HandleRelations } from '../../../shared/relations/HandleRelations'
import { InvitationList } from '../../../shared/invitations/InvitationList'
import { FlexColumn, FlexContainer } from '../../../shared/layout/Flex'
import {
  Common_Members,
  Invitations_Invitations,
  Relation_PrimaryContact,
  Relation_TeamMember,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { TeamDetailPageHeader } from './TeamDetailPageHeader'
import { InviteButton } from '../../../shared/invitations/InviteButton'
import { spacing } from '../../../../styles/styles'

export const TeamDetailPage = () => {
  const intl = useIntl()
  const { id } = useParams<{ id: string }>()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const [team, setTeam] = useState<any>()

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/teams/${id}`)
      .then((response) => {
        setTeam(response)
        updateNavBarTitle(`${response.name}`)
      })
      .catch(() => {})
  }, [id, updateNavBarTitle])

  const searchFilter = (user: any) => user.is_employee

  return (
    <PageContainer>
      {team ? (
        <React.Fragment>
          <TeamDetailPageHeader team={team} handleChangeTeam={setTeam} />

          <div style={{ marginBottom: spacing.md }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <h3 style={{ margin: 0 }}>
                {intl.formatMessage(Invitations_Invitations)}
              </h3>

              <InviteButton
                id={id}
                entityType="Team"
                relations={[{ key: 'TEAM_MEMBER', value: Relation_TeamMember }]}
              />
            </div>

            <InvitationList id={id} />
          </div>

          <FlexContainer>
            <FlexColumn>
              <HandleRelations
                id={id}
                heading={intl.formatMessage(Relation_PrimaryContact)}
                relationType="admins"
                path="teams"
                entity="Team"
                dataKey="user_ids"
                requiredLimit={1}
                searchFilter={searchFilter}
              />
            </FlexColumn>
            <FlexColumn>
              <HandleRelations
                id={id}
                heading={intl.formatMessage(Common_Members)}
                relationType="members"
                path="teams"
                entity="Team"
                dataKey="user_ids"
                searchFilter={searchFilter}
              />
            </FlexColumn>
          </FlexContainer>
        </React.Fragment>
      ) : (
        <ListItemLoader />
      )}
    </PageContainer>
  )
}

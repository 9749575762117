import React from 'react'
import { Card } from '../../../shared/card/Card'
import { PageContainer } from '../../../shared/layout/Layout'
import {
  Common_Or,
  Register_VerifyDescription,
  Register_VerifyTitle,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { WizardContainer } from '../common/WizardContainer'
import illustration from '../../../../images/illustrations/woman_cell_phone_blue.svg'
import { H1 } from '../../../shared/Typography'
import { NextButton } from '../common/NextButton'
import { spacing } from '../../../../styles/styles'
import { environment } from '../../../../environments'

export const VerifyInfoPage = () => {
  const intl = useIntl()

  return (
    <PageContainer>
      <Card>
        <WizardContainer
          numSteps={6}
          currentStep={3}
          illustration={illustration}
        >
          <div style={{ textAlign: 'center' }}>
            <H1>{intl.formatMessage(Register_VerifyTitle)}</H1>
            <p>{intl.formatMessage(Register_VerifyDescription)}</p>

            <div style={{ marginTop: spacing.lg }}>
              <NextButton
                label="ID-Porten"
                onClick={() => {
                  window.location.href = environment.IDPORTEN_VERIFY_URL
                }}
              />

              <h3 style={{ marginTop: spacing.md, marginBottom: spacing.md }}>
                {intl.formatMessage(Common_Or)}
              </h3>

              <NextButton
                label="Biofy"
                onClick={() => {
                  window.location.href = environment.BIOFY_LOGIN_URL
                }}
              />
            </div>
          </div>
        </WizardContainer>
      </Card>
    </PageContainer>
  )
}

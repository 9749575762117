import React, { useContext, useEffect } from 'react'
import { List, ListPageContainer } from '../../../shared/list'
import { environment } from '../../../../environments'
import { PageHeader } from '../../../shared/layout/PageHeader'
import { GroupListItem } from './GroupListItem'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import {
  Common_Groups,
  Common_New,
  Groups_EmptyState,
} from '../../../../translations/messages'
import { Button } from '../../../shared/Buttons'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

export const GroupPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const { updateNavBarTitle } = useContext(NavBarContext)

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Common_Groups))
  }, [intl, updateNavBarTitle])

  return (
    <ListPageContainer>
      <PageHeader
        title={intl.formatMessage(Common_Groups)}
        trailingElement={
          <Button
            label={intl.formatMessage(Common_New)}
            ariaLabel={intl.formatMessage(Common_New)}
            isSmall
            onClick={() => history.push(`groups/create`)}
            variant="primary"
          />
        }
      />
      <List
        emptyState={intl.formatMessage(Groups_EmptyState)}
        ListItem={GroupListItem}
        url={`${environment.API_URL}/groups`}
      />
    </ListPageContainer>
  )
}

import React, { FunctionComponent } from 'react'
import { Avatar } from './Avatar'
import styled from 'styled-components'
import { Card } from './card/Card'
import { spacing } from '../../styles/styles'

const ProfileCard = styled(Card)`
  position: relative;
  margin-top: 150px;
  margin-bottom: ${spacing.md};
  padding: ${spacing.lg};
`

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const ProfileTitle = styled.h2`
  margin: 0;
  padding-top: 116px;
`

type Props = {
  user: any
  handleUploadImage?: (event: any) => void
  userCroppedImage?: any
  forEditProfileImage?: boolean
  canEdit?: boolean
}

export const ProfileHeader: FunctionComponent<Props> = ({
  children,
  user,
  handleUploadImage,
  userCroppedImage,
  forEditProfileImage = false,
  canEdit = false,
}) => {
  return (
    <ProfileCard>
      <ProfileWrapper>
        {forEditProfileImage && canEdit ? (
          <div
            onChange={(event) =>
              handleUploadImage && handleUploadImage(event.target)
            }
            style={{
              position: 'absolute',
              marginTop: '-150px',
            }}
          >
            <Avatar
              size="large"
              image={user?.image}
              alt={`Bilde av ${user?.first_name}`}
              userCroppedImage={userCroppedImage}
            />
            <input
              id="file-input"
              type="file"
              accept="image/x-png,image/jpg,image/jpeg,image/heic"
              style={{ display: 'none' }}
            />
          </div>
        ) : (
          <Avatar
            style={{ position: 'absolute', marginTop: '-150px' }}
            size="large"
            image={user?.image}
            alt={`Bilde av ${user?.first_name}`}
          />
        )}
        <ProfileTitle>
          {user.first_name} {user.last_name}
        </ProfileTitle>
      </ProfileWrapper>
      {children}
    </ProfileCard>
  )
}

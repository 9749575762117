import styled from 'styled-components'
import { smallScreenBreakpoint, spacing } from '../../../styles/styles'

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const FlexColumn = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin: 0 0 ${spacing.md} 0;

    @media only screen and (min-width: ${smallScreenBreakpoint}px) {
      margin: 0 ${spacing.md} 0 0;
    }
  }
`

import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { authenticatedFetch } from '../lib/service'
import { environment } from '../environments'

type ContentToModerateCtx = {
  contentToModerate: any[] | undefined
  setContentToModerate: (contentToModerate: any[]) => void
}

const ModeratorContext = createContext<ContentToModerateCtx>({
  contentToModerate: undefined,
  setContentToModerate: () => {},
})

export const ModeratorsProvider: FunctionComponent = ({ children }) => {
  const [contentToModerate, setContentToModerate] = useState<any[]>([])

  return (
    <ModeratorContext.Provider
      value={{ contentToModerate, setContentToModerate }}
    >
      {children}
    </ModeratorContext.Provider>
  )
}

export const useContentToModerate = () => {
  const { contentToModerate, setContentToModerate } = useContext(
    ModeratorContext
  )

  if (contentToModerate === undefined) {
    throw new Error(
      'useContentToModerate must be used within a ContentToModerateProvider'
    )
  }

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/moderator`)
      .then(({ results }) => {
        setContentToModerate(results)
      })
      .catch(() => {})
  }, [setContentToModerate])

  return {
    contentToModerate,
  }
}

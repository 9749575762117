import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { authenticatedFetch } from '../lib/service'
import { environment } from '../environments'

type InvCtx = {
  invitations: any[] | undefined
  setInvitations: (invitations: any[]) => void
}

const InvitationContext = createContext<InvCtx>({
  invitations: undefined,
  setInvitations: () => {},
})

export const InvitationsProvider: FunctionComponent = ({ children }) => {
  const [invitations, setInvitations] = useState<any[]>([])

  return (
    <InvitationContext.Provider value={{ invitations, setInvitations }}>
      {children}
    </InvitationContext.Provider>
  )
}

export const useInvitations = () => {
  const { invitations, setInvitations } = useContext(InvitationContext)

  if (invitations === undefined) {
    throw new Error(
      'useInvitations must be used within a InvitationContextProvider'
    )
  }

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/invitations`)
      .then((response) => {
        setInvitations(response)
      })
      .catch(() => {})
  }, [setInvitations])

  return {
    invitations,
  }
}

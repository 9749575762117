import { Avatar } from '../components/shared/Avatar'
import {
  Common_Logout,
  Invitations_Invitations,
  Profile_MyProfile,
  Settings_Settings,
} from '../translations/messages'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import styled from 'styled-components'
import { colors, smallScreenBreakpoint, spacing } from '../styles/styles'
import { NavLink } from 'react-router-dom'
import { useIntl } from 'react-intl'

const LoggedInUser = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-self: flex-end;

  img {
    margin-right: ${spacing.sm};
  }

  @media only screen and (max-width: ${smallScreenBreakpoint}) {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`

const DropDownMenu = styled.ul`
  top: 3.3rem;
  right: 0;
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: 15rem;
  background: white;
  border: 1px solid ${colors.black5};
  text-align: center;

  li:not(:last-child) {
    border-bottom: 1px solid ${colors.black5};
  }
`

const DropDownMenuNavItem = styled(NavLink)`
  display: block;
  color: inherit;
  padding: ${spacing.sm} ${spacing.md};
  text-decoration: none;
`

export const NavBarDropDownMenu = () => {
  const intl = useIntl()
  const { authUser } = useContext(AuthContext)
  const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false)

  return (
    <LoggedInUser onClick={() => setShowDropDownMenu(() => !showDropDownMenu)}>
      <Avatar size="xsmall" image={authUser?.image} alt="Profile image" />
      <div>{authUser?.first_name}</div>

      {showDropDownMenu && (
        <DropDownMenu>
          <li>
            <DropDownMenuNavItem to={`/profile/${authUser?.id}`}>
              {intl.formatMessage(Profile_MyProfile)}
            </DropDownMenuNavItem>
          </li>
          <li>
            <DropDownMenuNavItem to="/invitations">
              {intl.formatMessage(Invitations_Invitations)}
            </DropDownMenuNavItem>
          </li>
          <li>
            <DropDownMenuNavItem to="/settings">
              {intl.formatMessage(Settings_Settings)}
            </DropDownMenuNavItem>
          </li>
          <li>
            <DropDownMenuNavItem to="/logout">
              {intl.formatMessage(Common_Logout)}
            </DropDownMenuNavItem>
          </li>
        </DropDownMenu>
      )}
    </LoggedInUser>
  )
}

import React from 'react'
import { DataProvider } from './contexts/DataProvider'
import { MultipleServiceReceiversNavBar } from './navigation/MultipleServiceReceiversNavBar'
import { Route, Switch, useLocation } from 'react-router-dom'
import { UserProvider } from './contexts/UserProvider'
import { ServiceReceiverDetailPage } from './components/pages/serviceReceiver/detail/ServiceReceiverDetailPage'
import { ServiceReceiverPage } from './components/pages/serviceReceiver/list/ServiceReceiverPage'
import { rootRoutes } from './routes/routes'
import { MultipleServiceReceiversTabs } from './navigation/MultipleServiceReceiversTabs'
import { CommonRoutes } from './CommonRoutes'
import { CreateServiceReceiverPage } from './components/pages/serviceReceiver/create/CreateServiceReceiverPage'

export const MultipleServiceReceiversApp = () => {
  const { pathname } = useLocation()
  const isRoot = rootRoutes.indexOf(pathname) !== -1

  return (
    <DataProvider>
      <MultipleServiceReceiversNavBar isRoot={isRoot} />

      <Switch>
        <Route path="/service-receivers/create" exact>
          <CreateServiceReceiverPage />
        </Route>

        <Route path="/service-receivers/:id">
          <UserProvider>
            <ServiceReceiverDetailPage />
          </UserProvider>
        </Route>

        <Route path="/service-receivers">
          <ServiceReceiverPage />
        </Route>

        <Route path="/" component={CommonRoutes} />
      </Switch>

      {isRoot && <MultipleServiceReceiversTabs />}
    </DataProvider>
  )
}

import React, { FunctionComponent } from 'react'
import { DataProvider } from './contexts/DataProvider'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { rootRoutes } from './routes/routes'
import { SingleServiceReceiverNavBar } from './navigation/SingleServiceReceiverNavBar'
import { CalendarPage } from './components/pages/calendar/CalendarPage'
import { SingleServiceReceiverTabs } from './navigation/SingleServiceReceiverTabs'
import { CommonRoutes } from './CommonRoutes'

type Props = {
  serviceReceiver: any
}

export const SingleServiceReceiverApp: FunctionComponent<Props> = ({
  serviceReceiver,
}) => {
  const { pathname } = useLocation()

  const isRoot =
    rootRoutes.includes(pathname) ||
    `/profile/${serviceReceiver.id}` === pathname

  return (
    <DataProvider>
      <SingleServiceReceiverNavBar
        isRoot={isRoot}
        serviceReceiverId={serviceReceiver.id}
      />

      <Switch>
        <Route path="/calendar" exact>
          <CalendarPage ownerId={serviceReceiver.id} />
        </Route>

        <Route path={`/service-receivers`}>
          <Redirect to={`profile/${serviceReceiver.id}`} />
        </Route>

        <Route path="/" component={CommonRoutes} />
      </Switch>

      {isRoot && (
        <SingleServiceReceiverTabs serviceReceiverId={serviceReceiver.id} />
      )}
    </DataProvider>
  )
}

import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { authenticatedFetch } from '../lib/service'
import { environment } from '../environments'
import { AuthContext } from './AuthContext'
import { Message } from '../types'

type MessagesByOwner = { [key: string]: any[] | undefined }

type Messages = {
  hasProvider: boolean
  allMessages: any[] | undefined
  setAllMessages: Dispatch<any>
  allMessagesNextUrl: string | null
  setAllMessagesNextUrl: Dispatch<any>
  messagesByOwner: MessagesByOwner
  setMessagesByOwner: Dispatch<any>
  messagesByOwnerNextUrl: any
  setMessagesByOwnerNextUrl: Dispatch<any>
  updatingMessage: string
  setUpdatingMessage: Dispatch<string>
  unreadMessages: number
  setUnreadMessages: Dispatch<number>
}

const MessageContext = createContext<Messages>({
  hasProvider: false,
  allMessages: [],
  setAllMessages: () => {},
  allMessagesNextUrl: null,
  setAllMessagesNextUrl: () => {},
  messagesByOwner: {},
  setMessagesByOwner: () => {},
  messagesByOwnerNextUrl: {},
  setMessagesByOwnerNextUrl: () => {},
  updatingMessage: '',
  setUpdatingMessage: () => {},
  unreadMessages: 0,
  setUnreadMessages: () => {},
})

const updateMessageInArray = (
  messages: any[],
  messageId: string,
  data: any
) => {
  return messages
    ? messages.map((message) =>
        message.id === messageId ? { ...message, ...data } : message
      )
    : [data]
}

const updateMessageByOwner = (
  messagesByOwner: any,
  messageId: string,
  ownerId: string,
  data: any
) => {
  return {
    ...messagesByOwner,
    [ownerId]: messagesByOwner[ownerId]
      ? updateMessageInArray(messagesByOwner[ownerId]!, messageId, data)
      : [],
  }
}

export const MessageProvider: FunctionComponent = ({ children }) => {
  const [hasProvider] = useState<boolean>(true)
  const [allMessages, setAllMessages] = useState<Message[] | undefined>(
    undefined
  )
  const [allMessagesNextUrl, setAllMessagesNextUrl] = useState<string | null>(
    null
  )
  const [messagesByOwner, setMessagesByOwner] = useState<MessagesByOwner>({})
  const [messagesByOwnerNextUrl, setMessagesByOwnerNextUrl] = useState<any>({})
  const [updatingMessage, setUpdatingMessage] = useState<string>('')
  const [unreadMessages, setUnreadMessages] = useState<number>(0)

  useEffect(() => {
    authenticatedFetch(
      `${environment.API_URL}/messages/unread`
    ).then(({ count }) => setUnreadMessages(count))
  }, [])

  return (
    <MessageContext.Provider
      value={{
        hasProvider,
        allMessages,
        setAllMessages,
        allMessagesNextUrl,
        setAllMessagesNextUrl,
        messagesByOwner,
        setMessagesByOwner,
        messagesByOwnerNextUrl,
        setMessagesByOwnerNextUrl,
        updatingMessage,
        setUpdatingMessage,
        unreadMessages,
        setUnreadMessages,
      }}
    >
      {children}
    </MessageContext.Provider>
  )
}

export const useMessages = () => {
  const { authUser } = useContext(AuthContext)
  const context = useContext(MessageContext)

  if (!context.hasProvider) {
    throw new Error('useMessages must be wrapped in a MessageProvider')
  }

  const {
    allMessages,
    setAllMessages,
    allMessagesNextUrl,
    setAllMessagesNextUrl,
    messagesByOwner,
    setMessagesByOwner,
    messagesByOwnerNextUrl,
    setMessagesByOwnerNextUrl,
    updatingMessage,
    setUpdatingMessage,
    unreadMessages,
    setUnreadMessages,
  } = context

  const notifyModerator = (postId: string, ownerId: string) => {
    setUpdatingMessage(postId)

    authenticatedFetch(
      `${environment.API_URL}/moderator`,
      { method: 'POST' },
      { target_id: postId }
    )
      .then(() => {
        const newState = { need_moderation: true }

        setAllMessages(updateMessageInArray(allMessages!, postId, newState))
        setMessagesByOwner(
          updateMessageByOwner(messagesByOwner, postId, ownerId, newState)
        )
        setUpdatingMessage('')
      })
      .catch(() => {
        setUpdatingMessage('')
      })
  }

  const updateMessageState = (newState: any) => {
    setAllMessages(updateMessageInArray(allMessages!, newState.id, newState))

    setMessagesByOwner(
      updateMessageByOwner(
        messagesByOwner,
        newState.id,
        newState.owner.id,
        newState
      )
    )
  }

  const deleteMessage = (message: any) => {
    setUpdatingMessage(message.id)

    authenticatedFetch(`${environment.API_URL}/messages/${message.id}`, {
      method: 'DELETE',
    })
      .then(() => {
        setUpdatingMessage('')

        if (allMessages) {
          setAllMessages(allMessages.filter((m) => m.id !== message.id))
        }

        const ownerId = message.owner.id
        if (messagesByOwner && messagesByOwner[ownerId] !== undefined) {
          setMessagesByOwner({
            ...messagesByOwner,
            // @ts-ignore
            [ownerId]: messagesByOwner[ownerId].filter(
              (m) => m.id !== message.id
            ),
          })
        }
      })
      .catch(() => setUpdatingMessage(''))
  }

  const markAllMessagesAsRead = () => {
    authenticatedFetch(`${environment.API_URL}/messages/read-all`, {
      method: 'POST',
    }).then(() => {
      const addAuthUserToReadByList = (m: Message) => {
        const hasRead = m.read.some((r) => r.id === authUser?.id)

        return hasRead
          ? m
          : {
              ...m,
              read: [
                ...m.read,
                {
                  first_name: authUser?.first_name,
                  last_name: authUser?.last_name,
                  id: authUser?.id,
                },
              ],
            }
      }
      const newState = allMessages?.map(addAuthUserToReadByList)

      setAllMessages(newState)

      setUnreadMessages(0)
    })
  }

  return {
    allMessages,
    setAllMessages,
    allMessagesNextUrl,
    setAllMessagesNextUrl,
    messagesByOwner,
    setMessagesByOwner,
    messagesByOwnerNextUrl,
    setMessagesByOwnerNextUrl,
    notifyModerator,
    updatingMessage,
    updateMessageState,
    deleteMessage,
    unreadMessages,
    markAllMessagesAsRead,
  }
}

import React, { useContext, useEffect, useState } from 'react'
import { PageContainer } from '../../shared/layout/Layout'
import { H1 } from '../../shared/Typography'
import { AuthContext } from '../../../contexts/AuthContext'
import {
  Common_ErrorOccurred,
  Common_Save,
  EULA_AcceptCrm,
  EULA_AcceptJodacare,
  EULA_Greeting,
  EULA_Terms,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'
import { Card } from '../../shared/card/Card'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { MessageLoader } from '../../shared/Loading'
import { spacing } from '../../../styles/styles'
import { CheckBox } from '../../shared/form/CheckBox'
import { Button } from '../../shared/Buttons'
import { useHistory } from 'react-router-dom'
import { ErrorMessage } from '../../shared/Messages'
import { NavBarContext } from '../../../contexts/NavBarContext'

const language = navigator.language.split('-')[0]

export const EulaPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const { authUser } = useContext(AuthContext)
  const [eula, setEula] = useState<{
    lang: string
    html: string
    version: string
  }>()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [acceptCrm, setAcceptCrm] = useState<boolean>(false)
  const [acceptJodacare, setAcceptJodacare] = useState<boolean>(false)

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(EULA_Terms))

    if (authUser) {
      authenticatedFetch(`${environment.API_URL}/eula/latest/${language}`)
        .then((eula) => {
          setEula(eula)
          return authenticatedFetch(
            `${environment.API_URL}/users/${authUser?.id}/eula`
          )
        })
        .then((eulaResponse) => {
          setAcceptCrm(eulaResponse.accept_crm)
          setAcceptJodacare(eulaResponse.accept_jodacare)
        })
        .catch((error) => {})
    }
  }, [authUser, updateNavBarTitle, intl])

  const updateResponse = () => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/eula/respond`,
      {
        method: 'POST',
      },
      {
        eula_version: eula?.version ?? 1,
        accept_crm: acceptCrm ?? false,
        accept_jodacare: acceptJodacare ?? false,
      }
    )
      .then(({ accept_jodacare }) => {
        setStatus('idle')

        if (accept_jodacare) {
          history.push('/')
        }
      })
      .catch(() => setStatus('failure'))
  }

  return (
    <PageContainer>
      <Card>
        <div style={{ padding: spacing.lg }}>
          <H1 style={{ textAlign: 'center' }}>
            {intl.formatMessage(EULA_Greeting, { name: authUser?.first_name })}
          </H1>

          {eula ? (
            <React.Fragment>
              <p dangerouslySetInnerHTML={{ __html: eula.html }}></p>

              <div style={{ marginBottom: spacing.md }}>
                <CheckBox
                  label={EULA_AcceptCrm}
                  name="acceptCrm"
                  checked={acceptCrm}
                  onChange={(checked) => setAcceptCrm(checked)}
                />
              </div>

              <div style={{ marginBottom: spacing.lg }}>
                <CheckBox
                  label={EULA_AcceptJodacare}
                  name="acceptJodacare"
                  checked={acceptJodacare}
                  onChange={(checked) => setAcceptJodacare(checked)}
                />
              </div>

              {status === 'failure' && (
                <ErrorMessage>
                  {intl.formatMessage(Common_ErrorOccurred)}
                </ErrorMessage>
              )}

              <div>
                <Button
                  ariaLabel={intl.formatMessage(Common_Save)}
                  variant="primary"
                  label={intl.formatMessage(Common_Save)}
                  disabled={!acceptJodacare || status === 'pending'}
                  onClick={updateResponse}
                />
              </div>
            </React.Fragment>
          ) : (
            <MessageLoader />
          )}
        </div>
      </Card>
    </PageContainer>
  )
}

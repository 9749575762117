import React, { useContext, useEffect, useState } from 'react'
import { PageContainer } from '../../../shared/layout/Layout'
import {
  Common_ErrorOccurred,
  ServiceReceivers_Create,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import { H1 } from '../../../shared/Typography'
import { breakpoints, colors, spacing } from '../../../../styles/styles'
import { Card } from '../../../shared/card/Card'
import { authenticatedFetch } from '../../../../lib/service'
import { RequestStatus } from '../../../../types'
import { MessageLoader } from '../../../shared/Loading'
import { useHistory } from 'react-router-dom'
import { CreateServiceReceiverForm } from '../../../shared/forms/CreateServiceReceiverForm'
import { environment } from '../../../../environments'

export const CreateServiceReceiverPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const [status, setStatus] = useState<RequestStatus>('idle')

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(ServiceReceivers_Create))
  }, [updateNavBarTitle, intl])

  const createServiceReceiver = (user: any) => {
    setStatus('pending')

    authenticatedFetch(`${environment.API_URL}/users`, { method: 'POST' }, user)
      .then((response) => {
        setStatus('idle')
        history.push(`/service-receivers/${response.id}`)
      })
      .catch(() => setStatus('failure'))
  }

  return (
    <PageContainer style={{ maxWidth: breakpoints.LARGE }}>
      <Card style={{ padding: spacing.lg }}>
        <H1 style={{ textAlign: 'center', marginBottom: spacing.lg }}>
          {intl.formatMessage(ServiceReceivers_Create)}
        </H1>

        {status === 'pending' ? (
          <MessageLoader />
        ) : (
          <React.Fragment>
            <div
              style={{
                maxWidth: breakpoints.SMALL,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <CreateServiceReceiverForm
                onCancel={() => history.goBack()}
                onSubmit={(user) => createServiceReceiver(user)}
              />

              {status === 'failure' && (
                <div style={{ color: colors.error, marginTop: spacing.md }}>
                  {intl.formatMessage(Common_ErrorOccurred)}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </Card>
    </PageContainer>
  )
}

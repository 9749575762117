import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  useContext,
  useState,
} from 'react'
import { authenticatedFetch } from '../lib/service'
import { environment } from '../environments'

type PostsByOwner = { [key: string]: any[] | undefined }

type Posts = {
  hasProvider: boolean
  allPosts: any[] | undefined
  setAllPosts: Dispatch<any>
  allPostsNextUrl: string | null
  setAllPostsNextUrl: Dispatch<any>
  postsByOwner: PostsByOwner
  setPostsByOwner: Dispatch<any>
  postsByOwnerNextUrl: any
  setPostsByOwnerNextUrl: Dispatch<any>
  updatingPost: string
  setUpdatingPost: Dispatch<string>
}

const PostContext = createContext<Posts>({
  hasProvider: false,
  allPosts: [],
  setAllPosts: () => {},
  allPostsNextUrl: null,
  setAllPostsNextUrl: () => {},
  postsByOwner: {},
  setPostsByOwner: () => {},
  postsByOwnerNextUrl: {},
  setPostsByOwnerNextUrl: () => {},
  updatingPost: '',
  setUpdatingPost: () => {},
})

const updatePostInArray = (posts: any[], postId: string, data: any) => {
  return posts
    ? posts.map((post) => (post.id === postId ? { ...post, ...data } : post))
    : [data]
}

const updatePostByOwner = (
  postsByOwner: any,
  postId: string,
  ownerId: string,
  data: any
) => {
  return {
    ...postsByOwner,
    [ownerId]: postsByOwner[ownerId]
      ? updatePostInArray(postsByOwner[ownerId]!, postId, data)
      : [],
  }
}

export const PostProvider: FunctionComponent = ({ children }) => {
  const [hasProvider] = useState<boolean>(true)
  const [allPosts, setAllPosts] = useState<any[] | undefined>(undefined)
  const [allPostsNextUrl, setAllPostsNextUrl] = useState<string | null>(null)
  const [postsByOwner, setPostsByOwner] = useState<PostsByOwner>({})
  const [postsByOwnerNextUrl, setPostsByOwnerNextUrl] = useState<any>({})
  const [updatingPost, setUpdatingPost] = useState<string>('')

  return (
    <PostContext.Provider
      value={{
        hasProvider,
        allPosts,
        setAllPosts,
        allPostsNextUrl,
        setAllPostsNextUrl,
        postsByOwner,
        setPostsByOwner,
        postsByOwnerNextUrl,
        setPostsByOwnerNextUrl,
        updatingPost,
        setUpdatingPost,
      }}
    >
      {children}
    </PostContext.Provider>
  )
}

export const usePosts = () => {
  const context = useContext(PostContext)

  if (!context.hasProvider) {
    throw new Error('usePosts must be wrapped in a PostProvider')
  }

  const {
    allPosts,
    setAllPosts,
    allPostsNextUrl,
    setAllPostsNextUrl,
    postsByOwner,
    setPostsByOwner,
    postsByOwnerNextUrl,
    setPostsByOwnerNextUrl,
    updatingPost,
    setUpdatingPost,
  } = context

  const notifyModerator = (postId: string, ownerId: string) => {
    setUpdatingPost(postId)

    authenticatedFetch(
      `${environment.API_URL}/moderator`,
      { method: 'POST' },
      { target_id: postId }
    )
      .then(() => {
        const newState = { need_moderation: true }

        setAllPosts(updatePostInArray(allPosts!, postId, newState))
        setPostsByOwner(
          updatePostByOwner(postsByOwner, postId, ownerId, newState)
        )
        setUpdatingPost('')
      })
      .catch(() => {
        setUpdatingPost('')
      })
  }

  const updatePostState = (newState: any) => {
    setAllPosts(updatePostInArray(allPosts!, newState.id, newState))
    setPostsByOwner(
      updatePostByOwner(postsByOwner, newState.id, newState.owner.id, newState)
    )
  }

  const deletePost = (post: any) => {
    setUpdatingPost(post.id)

    authenticatedFetch(`${environment.API_URL}/posts/${post.id}`, {
      method: 'DELETE',
    })
      .then(() => {
        setUpdatingPost('')

        if (allPosts) {
          setAllPosts(allPosts.filter((p) => p.id !== post.id))
        }

        const ownerId = post.owner.id
        if (postsByOwner && postsByOwner[ownerId] !== undefined) {
          setPostsByOwner({
            ...postsByOwner,
            // @ts-ignore
            [ownerId]: postsByOwner[ownerId].filter((p) => p.id !== post.id),
          })
        }
      })
      .catch(() => setUpdatingPost(''))
  }

  return {
    allPosts,
    setAllPosts,
    allPostsNextUrl,
    setAllPostsNextUrl,
    postsByOwner,
    setPostsByOwner,
    postsByOwnerNextUrl,
    setPostsByOwnerNextUrl,
    notifyModerator,
    updatingPost,
    updatePostState,
    deletePost,
  }
}

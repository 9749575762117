import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../../styles/styles'
import { Avatar } from '../Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCheck, faUsers } from '@fortawesome/free-solid-svg-icons'
import { ListAvatarIcon } from './ListAvatarIcon'

const ListItem = styled.button`
  font-size: inherit;
  border-radius: ${borderRadius};
  background: ${colors.white};
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  border: none;
  width: 100%;
  border-bottom: 1px solid ${colors.black5};

  svg {
    font-size: 1.5rem;
  }
`

const Title = styled.span`
  flex: 1;
  text-align: left;
`

type Props = {
  data: any
  onClick?: (item: any) => void
  selected: boolean
}

export const SelectableListItem: FunctionComponent<Props> = ({
  data,
  onClick,
  selected,
}) => {
  return (
    <ListItem onClick={onClick} aria-label={data.first_name ?? data.name}>
      {data.type === 'User' ? (
        <Avatar
          size="small"
          image={data.image}
          alt={`Bilde av ${data.first_name ?? data.name}`}
          style={{ marginRight: spacing.md }}
        />
      ) : data.type === 'Institution' ? (
        <ListAvatarIcon icon={faBuilding} />
      ) : data.type === 'Group' ? (
        <ListAvatarIcon icon={faUsers} />
      ) : null}
      <Title>
        {data.name ? data.name : `${data.first_name} ${data.last_name}`}
      </Title>
      {selected && <FontAwesomeIcon icon={faCheck} />}
    </ListItem>
  )
}

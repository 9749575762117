import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import {
  borderRadius,
  colors,
  smallScreenBreakpoint,
  spacing,
} from '../../styles/styles'

type ButtonProps = {
  size: 'small' | 'large' | 'xsmall'
  variant?: 'primary' | 'secondary' | 'danger' | 'dangerOutline'
}

const ButtonStyled = styled.button`
  position: relative;
  min-width: ${({ size }: ButtonProps) =>
    size === 'small' || size === 'xsmall' ? '' : ' 300px'};
  padding: ${({ size }: ButtonProps) =>
    size !== 'xsmall' ? `0  ${spacing.lg}` : `3 11 1`};
  height: ${({ size }: ButtonProps) =>
    size === 'small' ? '38px' : size === 'xsmall' ? '25px' : '48px'};
  font-size: inherit;
  border: ${({ variant }: ButtonProps) =>
    variant === 'secondary'
      ? `2px solid ${colors.black90}`
      : variant === 'dangerOutline'
      ? `2px solid ${colors.error}`
      : 'none'};
  border-radius: ${borderRadius};
  color: ${({ variant }: ButtonProps) =>
    variant === 'primary' || variant === 'danger'
      ? 'white'
      : variant === 'dangerOutline'
      ? colors.error
      : colors.black90};
  background: ${({ variant }: ButtonProps) =>
    variant === 'primary'
      ? colors.safeDark
      : variant === 'secondary'
      ? colors.white
      : variant === 'danger'
      ? colors.error
      : 'transparent'};

  &:disabled {
    border-color: ${colors.black40};
    color: ${colors.black40};
    background: ${colors.black10};
    cursor: not-allowed;
  }

  .icon {
    @media only screen and (min-width: ${smallScreenBreakpoint}px) {
      display: none;
    }

    & ~ span {
      display: none;

      @media only screen and (min-width: ${smallScreenBreakpoint}px) {
        display: inline-block;
      }
    }
  }
`

type Props = {
  label?: string | ReactNode
  ariaLabel: string
  smallScreenIcon?: any
  onClick?: () => void
  isSmall?: boolean
  isXSmall?: boolean
  type?: 'button' | 'submit'
  variant?: 'primary' | 'secondary' | 'danger' | 'dangerOutline'
  disabled?: boolean
  style?: {}
}

export const Button: FunctionComponent<Props> = ({
  label,
  ariaLabel,
  onClick,
  type = 'button',
  isSmall,
  isXSmall,
  variant,
  disabled,
  smallScreenIcon,
  style,
}) => {
  return (
    <ButtonStyled
      aria-label={ariaLabel}
      type={type}
      onClick={onClick}
      size={isSmall ? 'small' : isXSmall ? 'xsmall' : 'large'}
      variant={variant}
      disabled={disabled}
      style={style}
    >
      {smallScreenIcon && <span className="icon">{smallScreenIcon}</span>}
      <span>{label}</span>
    </ButtonStyled>
  )
}

export const LinkButton = styled.button`
  font-size: inherit;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid ${colors.warmthDark};
  border-left: none;
  background: none;
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    min-width: auto;
  }

  button:not(:last-child) {
    margin-right: ${spacing.md};
  }
`

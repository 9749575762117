import arrowRight from '../../../../icons/arrow-right.svg'
import { Button } from '../../../shared/Buttons'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { spacing } from '../../../../styles/styles'

const Icon = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${spacing.md};
`

type Props = {
  label: string
  disabled?: boolean
  onClick: () => void
}

export const NextButton: FunctionComponent<Props> = ({
  label,
  disabled,
  onClick,
}) => {
  return (
    <Button
      ariaLabel={label}
      disabled={disabled}
      label={
        <React.Fragment>
          {label} <Icon src={arrowRight} />
        </React.Fragment>
      }
      onClick={onClick}
      variant="primary"
    />
  )
}

import React, { FunctionComponent, useContext, useState } from 'react'
import { colors, spacing } from '../../../styles/styles'
import {
  Card,
  CardAction,
  CardActions,
  CardHeader,
  CardMeta,
  CardText,
  Meta,
} from '../card/Card'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from '../../../contexts/AuthContext'
import { ModeratedContendCard } from '../ModeratedContendCard'
import { useMessages } from '../../../contexts/MessageProvider'
import { CardMenu } from '../card/CardMenu'
import { ConfirmDialog } from '../modal/ConfirmDialog'
import { NotifyButton } from '../NotifyButton'
import {
  Common_Cancel,
  Common_Comment,
  Common_Delete,
  Common_NumComments,
  Messages_ConfirmDelete,
  Messages_Important,
  Messages_New,
} from '../../../translations/messages'
import { FormattedMessage, useIntl } from 'react-intl'
import { SwipeableMedia } from '../post/SwipeableMedia'

const MessageCardStyled = styled(Card)`
  margin-bottom: ${spacing.md};
  padding: ${spacing.sm} 0;

  &.updating {
    background: ${colors.black20};
  }
`

const HighPriority = styled.div`
  padding: ${spacing.sm} ${spacing.md};
  font-weight: bold;
  color: ${colors.closeLight};
`

const New = styled.span`
  font-weight: bold;
  color: ${colors.closeLight};
`

const ProfileLink = styled(Link)`
  color: ${colors.joda};
  font-weight: bold;
  font-size: 0.9rem;
  text-decoration: none;
`

const ReadBy = styled.div`
  padding: ${spacing.sm} ${spacing.md};
  color: ${colors.black80};
  font-size: 0.9rem;
`

type Props = {
  data: any
}

const formatter = new Intl.DateTimeFormat('nb-NO')

const getReadByNames = (authUser: any, read: any) =>
  read.map((r: any) => (r.id === authUser!.id ? 'Deg' : r.first_name))

export const MessageCard: FunctionComponent<Props> = ({ data }) => {
  const intl = useIntl()
  const { authUser } = useContext(AuthContext)
  const history = useHistory()
  const { updatingMessage, deleteMessage } = useMessages()
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  const {
    id,
    owner,
    text,
    created,
    created_by,
    read,
    need_moderation,
    num_comments,
    images,
    videos,
  } = data
  const readBy = getReadByNames(authUser, read)

  const hasRead =
    data.read.findIndex((user: any) => user.id === authUser!.id) !== -1
  const isNew = !hasRead && authUser?.id !== data.created_by.id

  if (need_moderation) {
    return <ModeratedContendCard />
  }

  return (
    <React.Fragment>
      <MessageCardStyled className={updatingMessage === id ? 'updating' : ''}>
        {data.priority === 1 && (
          <HighPriority>{intl.formatMessage(Messages_Important)}</HighPriority>
        )}
        <CardHeader>
          <ProfileLink
            to={
              owner.type === 'User'
                ? `/service-receiver/${owner.id}`
                : owner.type === 'Group'
                ? `/groups/${owner.id}`
                : `/institutions/${owner.id}`
            }
          >
            {isNew && <New>{intl.formatMessage(Messages_New)}</New>}{' '}
            {owner.name}
          </ProfileLink>
          {authUser?.id === created_by.id && (
            <CardMenu
              onEdit={() => history.push(`/messages/${id}/edit`)}
              onDelete={() => {
                setShowConfirmDelete(true)
              }}
            />
          )}
        </CardHeader>
        <CardText onClick={() => history.push(`/messages/${id}`)}>
          {text}
        </CardText>
        {images && <SwipeableMedia media={[...videos, ...images]} />}
        <CardText>
          {formatter.format(created)}{' '}
          <ProfileLink to={`/profile/${created_by.id}`}>
            {created_by.first_name} {created_by.last_name}
          </ProfileLink>
        </CardText>

        <CardMeta onClick={() => history.push(`/messages/${id}`)}>
          {read.length > 0 && <ReadBy>{readBy.join(', ')}</ReadBy>}
          {num_comments > 0 && (
            <Meta>
              {intl.formatMessage(Common_NumComments, {
                numComments: num_comments,
              })}
            </Meta>
          )}
        </CardMeta>

        <CardActions>
          <CardAction onClick={() => history.push(`/messages/${id}`)}>
            <FormattedMessage
              id={Common_Comment.id}
              defaultMessage={Common_Comment.defaultMessage}
            />
          </CardAction>
          <NotifyButton id={id} ownerId={owner.id} contentType="Message" />
        </CardActions>
      </MessageCardStyled>

      <ConfirmDialog
        show={showConfirmDelete}
        title={Messages_ConfirmDelete}
        confirmButtonText={Common_Delete}
        cancelButtonText={Common_Cancel}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={() => {
          setShowConfirmDelete(false)
          deleteMessage(data)
        }}
        onCancel={() => setShowConfirmDelete(false)}
      />
    </React.Fragment>
  )
}

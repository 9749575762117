import { Card } from '../../shared/card/Card'
import React, { useState } from 'react'
import { H1 } from '../../shared/Typography'
import { breakpoints, colors, spacing } from '../../../styles/styles'
import { Button } from '../../shared/Buttons'
import { environment } from '../../../environments'
import { PageContainer } from '../../shared/layout/Layout'
import styled from 'styled-components'
import illustration from '../../../images/illustrations/young_and_old_2.svg'
import { Link, useParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { unauthenticatedFetch } from '../../../lib/service'
import { ErrorMessage } from '../../shared/Messages'
import { TextField } from '../../shared/form/TextField'
import { Select } from '../../shared/form/Select'
import { useIntl } from 'react-intl'
import {
  Common_CellPhoneNumber,
  VerifyAccountPage_AccountConfirmedTitle,
  VerifyAccountPage_AccountConfirmedText,
  VerifyAccountPage_SMSCode,
  VerifyAccountPage_SMSCodeText,
  VerifyAccountPage_StartUsingJodacare,
  Common_ErrorOccurred,
  Common_Confirm,
  VerifyAccountPage_ConfirmAccountTitle,
  VerifyAccountPage_ConfirmAccountText,
  Common_CountryCode,
  VerifyAccountPage_ReceiveCode,
  VerifyAccountPage_NoAccount,
  VerifyAccountPage_CreateAccountLink,
} from '../../../translations/messages'
import { Box } from '../../shared/Box'

const LoginContainer = styled(PageContainer)`
  height: 100vh;
`

const InnerContainer = styled.div`
  padding: ${spacing.xl} ${spacing.lg};
  display: flex;
  align-items: center;
`

const Illustration = styled.div`
  display: none;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    display: inline-block;
    flex: 1;
    text-align: center;

    img {
      height: 20rem;
    }
  }
`

const Login = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LoginText = styled.p`
  margin: ${spacing.lg} 0;
  text-align: center;
  max-width: 50ch;
`

export const VerifyAccountPage = () => {
  const intl = useIntl()
  const { token } = useParams<{ token: string }>()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [code, setCode] = useState<any>('')
  const [countryCode, setCountryCode] = useState<any>('+47')
  const [cellPhoneNumber, setCellPhoneNumber] = useState<any>(undefined)
  const [codeSent, setCodeSent] = useState<boolean>(false)
  const [confirmed, setConfirmed] = useState<boolean>(false)

  const getVerificationCode = () => {
    setStatus('pending')

    unauthenticatedFetch(
      `${environment.API_URL}/auth/send-confirm`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
      {
        phone_no: `${countryCode}${cellPhoneNumber.trim()}`,
      }
    )
      .then(() => {
        setStatus('idle')
        setCodeSent(true)
      })
      .catch((error) => {
        if (error?.message === 'jwt expired') {
          window.location.href = environment.IDPORTEN_VERIFY_URL
        } else {
          setStatus('failure')
        }
      })
  }

  const confirmAccount = () => {
    setStatus('pending')
    const decoded: any = jwt_decode(token)

    unauthenticatedFetch(
      `${environment.API_URL}/auth/confirm-account`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
      {
        code,
        sub: decoded.sub,
        auth_method: decoded.auth_method,
      }
    )
      .then(({ token }) => {
        setStatus('idle')
        localStorage.setItem('jodacare-token', token)
        setConfirmed(true)
      })
      .catch(() => {
        setStatus('failure')
      })
  }

  const enterJodacare = () => window.location.reload()

  return (
    <LoginContainer>
      <Card>
        <InnerContainer>
          <Illustration>
            <img
              alt="Illustrasjon av en gammel mann og et barn som leker"
              src={illustration}
            />
          </Illustration>
          <Login>
            {codeSent ? (
              <React.Fragment>
                {confirmed ? (
                  <React.Fragment>
                    <H1>
                      {intl.formatMessage(
                        VerifyAccountPage_AccountConfirmedTitle
                      )}
                    </H1>
                    <LoginText>
                      {intl.formatMessage(
                        VerifyAccountPage_AccountConfirmedText
                      )}
                    </LoginText>

                    <Button
                      ariaLabel={intl.formatMessage(
                        VerifyAccountPage_StartUsingJodacare
                      )}
                      label={intl.formatMessage(
                        VerifyAccountPage_StartUsingJodacare
                      )}
                      variant="primary"
                      onClick={enterJodacare}
                    />
                  </React.Fragment>
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <H1>{intl.formatMessage(VerifyAccountPage_SMSCode)}</H1>

                    <LoginText>
                      {intl.formatMessage(VerifyAccountPage_SMSCodeText)}
                    </LoginText>

                    <TextField
                      id="code"
                      type="text"
                      label={VerifyAccountPage_SMSCode}
                      value={code}
                      onChange={(value) => setCode(value)}
                    />

                    {status === 'failure' && (
                      <div>
                        <ErrorMessage>
                          {intl.formatMessage(Common_ErrorOccurred)}
                        </ErrorMessage>
                      </div>
                    )}

                    <Button
                      ariaLabel={intl.formatMessage(Common_Confirm)}
                      label={intl.formatMessage(Common_Confirm)}
                      variant="primary"
                      disabled={status === 'pending' || !code}
                      onClick={confirmAccount}
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <H1>
                  {intl.formatMessage(VerifyAccountPage_ConfirmAccountTitle)}
                </H1>
                <LoginText>
                  {intl.formatMessage(VerifyAccountPage_ConfirmAccountText)}
                </LoginText>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: '1', marginRight: spacing.lg }}>
                    <Select
                      value={countryCode}
                      ariaLabel={intl.formatMessage(Common_CountryCode)}
                      options={[
                        { key: '+47', value: '+47' },
                        { key: '+46', value: '+46' },
                        { key: '+66', value: '+66' },
                        { key: '+32', value: '+32' },
                      ]}
                      padding={spacing.sm}
                      onChange={(value) => setCountryCode(value)}
                    />
                  </div>

                  <div style={{ flex: '3' }}>
                    <TextField
                      id="phoneNumber"
                      type="phone"
                      label={Common_CellPhoneNumber}
                      value={cellPhoneNumber}
                      onChange={(value) => {
                        setCellPhoneNumber(value.replace(/[^\d]/g, ''))
                      }}
                    />
                  </div>
                </div>

                {status === 'failure' && (
                  <Box
                    style={{
                      textAlign: 'center',
                      padding: spacing.md,
                      marginBottom: spacing.md,
                    }}
                  >
                    <ErrorMessage>
                      {intl.formatMessage(Common_ErrorOccurred)}
                    </ErrorMessage>
                    <p>{intl.formatMessage(VerifyAccountPage_NoAccount)}</p>

                    <Link
                      style={{
                        color: colors.black,
                        borderBottom: `2px solid ${colors.warmthDark}`,
                        textDecoration: 'none',
                      }}
                      to="/register"
                    >
                      {intl.formatMessage(VerifyAccountPage_CreateAccountLink)}
                    </Link>
                  </Box>
                )}

                <Button
                  ariaLabel={intl.formatMessage(VerifyAccountPage_ReceiveCode)}
                  label={intl.formatMessage(VerifyAccountPage_ReceiveCode)}
                  variant="primary"
                  disabled={status === 'pending' || !cellPhoneNumber}
                  onClick={getVerificationCode}
                />
              </React.Fragment>
            )}
          </Login>
        </InnerContainer>
      </Card>
    </LoginContainer>
  )
}

import React, { FunctionComponent, useState } from 'react'
import { TextField } from '../form/TextField'
import {
  Common_Cancel,
  Common_Email,
  Common_FirstName,
  Common_LastName,
  Common_Password,
  Common_Save,
  CreateServiceReceiver_ConsentDescription,
  CreateServiceReceiver_HaveConsent,
  CreateServiceReceiver_UsingJodabook,
} from '../../../translations/messages'
import { spacing } from '../../../styles/styles'
import { CheckBox } from '../form/CheckBox'
import { Button } from '../Buttons'
import { useIntl } from 'react-intl'

type NewServiceReceiver = {
  first_name: string
  last_name: string
  consent_obtained: boolean
  using_jodabook: boolean
  is_service_receiver: boolean
  email?: string
  password?: string
}

type Props = {
  onCancel: () => void
  onSubmit: (user: NewServiceReceiver) => void
}

export const CreateServiceReceiverForm: FunctionComponent<Props> = ({
  onCancel,
  onSubmit,
}) => {
  const intl = useIntl()
  const [user, setUser] = useState<NewServiceReceiver>({
    first_name: '',
    last_name: '',
    is_service_receiver: true,
    consent_obtained: false,
    using_jodabook: false,
  })

  const formIsValid = Boolean(
    user.first_name && user.last_name && user.consent_obtained
  )
  const jodabookValid = (user.password && user.email) || !user.using_jodabook

  return (
    <React.Fragment>
      <TextField
        id="firstName"
        type="text"
        label={Common_FirstName}
        value={user.first_name}
        onChange={(value) => setUser({ ...user, first_name: value })}
      />

      <div style={{ marginBottom: spacing.lg }}>
        <TextField
          id="lastName"
          type="text"
          label={Common_LastName}
          value={user.last_name}
          onChange={(value) => setUser({ ...user, last_name: value })}
        />
      </div>

      <div style={{ marginBottom: spacing.lg }}>
        <CheckBox
          label={CreateServiceReceiver_HaveConsent}
          name="consent"
          checked={user.consent_obtained}
          onChange={(value) => setUser({ ...user, consent_obtained: value })}
          helpText={CreateServiceReceiver_ConsentDescription}
        />
      </div>

      <div style={{ marginBottom: spacing.lg }}>
        <CheckBox
          label={CreateServiceReceiver_UsingJodabook}
          name="consent"
          checked={user.using_jodabook}
          onChange={(value) => setUser({ ...user, using_jodabook: value })}
        />
      </div>

      {user.using_jodabook && (
        <React.Fragment>
          <TextField
            id="email"
            type="email"
            label={Common_Email}
            value={user.email ?? ''}
            onChange={(value) => setUser({ ...user, email: value })}
          />

          <TextField
            id="password"
            type="text"
            label={Common_Password}
            value={user.password ?? ''}
            onChange={(value) => setUser({ ...user, password: value })}
          />
        </React.Fragment>
      )}

      <div style={{ display: 'flex', gap: spacing.md }}>
        <Button
          ariaLabel={intl.formatMessage(Common_Cancel)}
          label={intl.formatMessage(Common_Cancel)}
          variant="danger"
          onClick={onCancel}
        />

        <Button
          ariaLabel={intl.formatMessage(Common_Save)}
          label={intl.formatMessage(Common_Save)}
          variant="primary"
          disabled={!formIsValid || !jodabookValid}
          onClick={() => onSubmit(user)}
        />
      </div>
    </React.Fragment>
  )
}

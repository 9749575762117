import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { PageContainer } from '../../shared/layout/Layout'
import { useIntl } from 'react-intl'
import { Common_PleaseWait } from '../../../translations/messages'
import { environment } from '../../../environments'
import { AuthContext } from '../../../contexts/AuthContext'
import jwt_decode from 'jwt-decode'

const Container = styled(PageContainer)`
  height: 100vh;
  text-align: center;
`

export const LogoutPage = () => {
  const { authToken } = useContext(AuthContext)
  const intl = useIntl()

  useEffect(() => {
    const token = authToken

    const { auth_method } = jwt_decode(token) as any

    if (auth_method === 'BIOFY') {
      window.location.href = `${environment.BIOFY_LOGOUT_URL}?token=${token}`
    } else {
      window.location.href = `${environment.IDPORTEN_LOGOUT_URL}?token=${token}`
    }
  }, [authToken])

  return (
    <Container>
      <h2>{intl.formatMessage(Common_PleaseWait)}</h2>
    </Container>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { authenticatedFetch } from './lib/service'
import { environment } from './environments'
import { MultipleServiceReceiversApp } from './MultipleServiceReceiversApp'
import { SingleServiceReceiverApp } from './SingleServiceReceiverApp'
import { AuthContext } from './contexts/AuthContext'
import { Switch } from 'react-router-dom'
import { useVideoCalls } from './contexts/VideoCallsProvider'
import { VideoCallPage } from './components/pages/video-call/VideoCallPage'

export const AuthenticatedApp = () => {
  const { videoCall } = useVideoCalls()
  const { authUser } = useContext(AuthContext)
  const [serviceReceiverCount, setServiceReceiverCount] = useState<
    number | null
  >(null)
  const [serviceReceivers, setServiceReceivers] = useState<any[] | null>(null)

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/users/service-receivers`)
      .then(({ count, results }) => {
        setServiceReceivers(results)
        setServiceReceiverCount(count)
      })
      .catch(() => setServiceReceiverCount(0))
  }, [])

  if (serviceReceiverCount === null) {
    return <div />
  }

  // @ts-ignore
  const isNative = window?.ReactNativeWebView

  if (videoCall && !isNative) {
    return <VideoCallPage />
  }

  return (
    <Switch>
      {!authUser!.is_employee && serviceReceiverCount === 1 ? (
        <SingleServiceReceiverApp serviceReceiver={serviceReceivers![0]} />
      ) : (
        <MultipleServiceReceiversApp />
      )}
    </Switch>
  )
}

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'

const SelectWrapper = styled.div`
  margin-bottom: ${spacing.lg};
  border-top: 1px solid ${colors.black20};
  border-left: 1px solid ${colors.black20};
  border-right: 1px solid ${colors.black20};

  button {
    padding: ${spacing.md};
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    background: white;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid ${colors.black20};

    &.selected {
      color: white;
      background: ${colors.safeLight};
      border-bottom: 1px solid ${colors.safeDark};
    }
  }
`

type Props = {
  options: { key: string; value: any }[]
  selected: any[]
  onChange: (values: any[]) => void
}

export const MultipleSelect: FunctionComponent<Props> = ({
  options,
  selected,
  onChange,
}) => {
  const isSelected = (value: any) => selected.indexOf(value) !== -1

  const toggleSelected = (value: any) => {
    if (!isSelected(value)) {
      onChange([...selected, value])
    } else {
      onChange(selected.filter((v) => v !== value))
    }
  }

  return (
    <SelectWrapper>
      {options.map((option) => (
        <button
          className={isSelected(option.value) ? 'selected' : ''}
          onClick={() => toggleSelected(option.value)}
        >
          {option.key}
        </button>
      ))}
    </SelectWrapper>
  )
}

import { getBlob } from './file.service'
import { environment } from '../environments'

export const authenticatedFetch = (
  url: string,
  options?: RequestInit,
  data?: any
): Promise<any> => {
  const token = localStorage.getItem('jodacare-token')

  return fetch(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (!response.ok) {
      if (response.status === 401) {
        // Log user out
        localStorage.removeItem('jodacare-token')
        window.location.reload()
        return
      }

      const res = await response.json()
      throw new Error(res?.message || 'An error occurred')
    }

    return response.json()
  })
}

export const unauthenticatedFetch = (
  url: string,
  options?: RequestInit,
  data?: any
): Promise<any> => {
  let opt = {
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    body: JSON.stringify(data),
  }

  return fetch(url, {
    ...opt,
    ...options,
  }).then(async (response) => {
    if (!response.ok) {
      const res = await response.json()
      throw new Error(res?.message || 'An error occurred')
    }

    return response.json()
  })
}

export async function uploadImages(images: any) {
  const data = await authenticatedFetch(
    `${environment.API_URL}/images/upload`,
    {
      method: 'POST',
    },
    {
      filenames: images.map((i: any) => i.name),
    }
  )
  let imageIds: any[] = []
  await Promise.all(
    images.map(async (image: any) => {
      const orientation =
        image.exifData && image.exifData.Orientation
          ? image.exifData.Orientation
          : 1
      const filePath: any = URL.createObjectURL(image)
      const blob = await getBlob(filePath)
      var imageFile = new Image()
      imageFile.src = filePath
      const imageData = data.find((x: any) => x.filename.includes(image.name))
      const { url, filename } = imageData
      await uploadFileToS3(url, blob, blob.type)
      const imageResolutions = new Image()
      imageResolutions.src = filePath
      const { id: imageId } = await authenticatedFetch(
        `${environment.API_URL}/images`,
        {
          method: 'POST',
        },
        {
          filename,
          width: imageResolutions.width ?? null,
          height: imageResolutions.height ?? null,
          orientation,
        }
      )
      imageIds.push(imageId)
    })
  )
  if (imageIds && imageIds.length > 0) {
    return imageIds
  }
}
export async function uploadVideos(videos: any) {
  let videoIds: any[] = []
  await Promise.all(
    videos.map(async (video: any) => {
      const videoFilePath = URL.createObjectURL(video.file)
      const blob = await getBlob(videoFilePath)
      const videoContentType = blob.type.toLowerCase()
      //TODO this should be commented only for native
      // const imageBlob = video.thumbnail
      // const imageContentType = imageBlob.type
      const {
        url,
        filename,
        thumbnail_url,
        thumbnail_filename,
      } = await authenticatedFetch(
        `${environment.API_URL}/videos/upload`,
        {
          method: 'POST',
        },
        {
          filename: video.file.name.replace('trim.', '').toLowerCase(),
        }
      )
      const imageBlob = await getBlob(thumbnail_url)
      await uploadFileToS3(url, blob, videoContentType)
      await uploadFileToS3(thumbnail_url, imageBlob, imageBlob.type)
      const { id: videoId } = await authenticatedFetch(
        `${environment.API_URL}/videos`,
        {
          method: 'POST',
        },
        {
          filename,
          thumbnail_filename,
        }
      )
      videoIds.push(videoId)
    })
  )
  if (videoIds && videoIds.length > 0) {
    return videoIds
  }
}

export async function uploadProfileImage(image: any, fileName: string) {
  const { url, filename } = await authenticatedFetch(
    `${environment.API_URL}/images/upload`,
    {
      method: 'POST',
    },
    {
      filename: fileName,
    }
  )
  const orientation =
    image.exifData && image.exifData.Orientation
      ? image.exifData.Orientation
      : 1
  await uploadFileToS3(url, image, 'image/jpeg')
  const { id: imageId } = await authenticatedFetch(
    `${environment.API_URL}/images`,
    {
      method: 'POST',
    },
    {
      filename,
      width: null,
      height: null,
      orientation,
    }
  )
  return imageId
}

export function uploadFileToS3(url: string, data: any, contentType: string) {
  let options: any = {
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
    },
    body: data,
  }

  return fetch(url, options).then(async (response) => {
    if (!response.ok) {
      return
    }

    return await response.text()
  })
}

import React, { useContext, useEffect, useState } from 'react'
import { PageContainer } from '../../shared/layout/Layout'
import { NavBarContext } from '../../../contexts/NavBarContext'
import { useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {
  Common_Edit,
  Common_Cancel,
  Common_Text,
  Post_Edit,
  Common_ErrorOccurred,
} from '../../../translations/messages'
import styled from 'styled-components'
import { Card } from '../../shared/card/Card'
import {
  spacing,
  breakpoints,
  smallScreenBreakpoint,
} from '../../../styles/styles'
import {
  authenticatedFetch,
  uploadImages,
  uploadVideos,
} from '../../../lib/service'
import { environment } from '../../../environments'
import { TextAreaField } from '../../shared/form/TextArea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../shared/Buttons'
import { EditPostMedia } from '../../shared/EditPostMedia'
import { MessageLoader } from '../../shared/Loading'
import { ErrorMessage } from '../../shared/Messages'
import VideoThumbnail from 'react-video-thumbnail'
import { b64toBlob } from '../../../lib/file.service'

const Form = styled(Card)`
  padding: ${spacing.md};
  margin-left: auto;
  margin-right: auto;
  max-width: ${breakpoints.XLARGE};
`
const ButtonGroup = styled.div`
  margin-top: ${spacing.lg};
  display: flex;
  flex-direction: column;

  button:last-child {
    margin-top: ${spacing.md};
  }

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    justify-content: flex-start;
    flex-direction: row-reverse;

    button:last-child {
      margin-top: 0;
      margin-right: ${spacing.md};
    }
  }
`

export const EditPostPage = () => {
  const intl = useIntl()
  const { id } = useParams<{ id: string }>()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const [post, setPost] = useState<any>({})
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/posts/${id}`)
      .then((post) => {
        setPost(post)
      })
      .catch(() => {})
  }, [id])

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Common_Edit))
  }, [id, updateNavBarTitle, intl])

  const history = useHistory()
  const handleEditPost = () => {
    setStatus('pending')
    Promise.all([
      uploadImages(post.images.filter((x: any) => !x.medium)),
      uploadVideos(post.videos.filter((x: any) => !x.mp4_urls)),
      authenticatedFetch(
        `${environment.API_URL}/posts/${id}/`,
        { method: 'PATCH' },
        { text: post.text }
      ),
    ])
      .then(([imageIds, videoIds]) => {
        return authenticatedFetch(
          `${environment.API_URL}/posts/${id}/media`,
          { method: 'POST' },
          {
            image_ids: imageIds ?? [],
            video_ids: videoIds ?? [],
          }
        )
      })
      .then(() => {
        setStatus('idle')
        history.goBack()
      })
      .catch((error) => {
        setStatus('failure')
      })
  }
  const handleDeleteImage = (image: any) => {
    if (image.id) {
      setStatus('pending')
      authenticatedFetch(`${environment.API_URL}/images/${image.id}`, {
        method: 'DELETE',
      })
        .then(() => {
          setStatus('idle')
          setPost({
            ...post,
            images: post.images.filter((img: any) => img !== image),
          })
        })
        .catch(() => {
          setStatus('failure')
        })
    }
  }
  const handleDeleteVideo = (video: any) => {
    if (video.id) {
      setStatus('pending')
      authenticatedFetch(`${environment.API_URL}/videos/${video.id}`, {
        method: 'DELETE',
      })
        .then(() => {
          setStatus('idle')
          setPost({
            ...post,
            videos: post.videos.filter((v: any) => v !== video),
          })
        })
        .catch(() => {
          setStatus('failure')
        })
    }
  }
  const handleCreateThumbnail = (thumbnail, index) => {
    const blob: any = b64toBlob(thumbnail)
    if (blob) {
      const videoFilename = post.videos[index].file.name
      const ext = videoFilename.split('.')[1]
      blob.name =
        post.videos[index].file.name.replace(`.${ext}`, '_thumb') + '.jpeg'
      let newVideos = post.videos
      newVideos[index].thumbnail = blob
      setPost({ ...post, videos: newVideos })
    }
  }
  return (
    <PageContainer>
      <Form>
        {status === 'failure' && (
          <div>
            <ErrorMessage>
              {intl.formatMessage(Common_ErrorOccurred)}
            </ErrorMessage>
          </div>
        )}
        {status === 'pending' ? (
          <MessageLoader />
        ) : (
          <React.Fragment>
            <h1 style={{ textAlign: 'center' }}>
              {intl.formatMessage(Post_Edit)}
            </h1>
            <TextAreaField
              id="text"
              label={Common_Text}
              value={post.text}
              onChange={(value: any) => setPost({ ...post, text: value })}
            />
            <div
              style={{
                display: 'flex',
                flexFlow: 'wrap',
              }}
            >
              {post && post.images && post.images.length > 0 && (
                <>
                  {post.images.map((image: any, index: number) => {
                    return (
                      <div
                        style={{ marginRight: '10px' }}
                        key={image.name ?? image.fileName}
                      >
                        <img
                          width="200"
                          height="200"
                          src={
                            image.medium
                              ? image.medium
                              : URL.createObjectURL(image)
                          }
                          alt="uploaded"
                          id={`image-${index}`}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => {
                            handleDeleteImage(image)
                          }}
                          color="red"
                        />
                      </div>
                    )
                  })}
                </>
              )}
              {post && post.videos && post.videos.length > 0 && (
                <React.Fragment key={post.videos ?? null}>
                  {post.videos.map((video: any, index: number) => {
                    return (
                      <div
                        style={{ marginRight: '10px' }}
                        key={video.name ?? video.fileName}
                      >
                        <video width="300" controls id={`video-${index}`}>
                          <source
                            src={
                              video.status === undefined
                                ? URL.createObjectURL(video.file)
                                : video.mp4_urls && video.mp4_urls[0]
                            }
                          />
                        </video>
                        {video.status === undefined && (
                          <div style={{ display: 'none' }}>
                            <VideoThumbnail
                              videoUrl={
                                video.status === undefined
                                  ? URL.createObjectURL(video.file)
                                  : video.mp4_urls && video.mp4_urls[0]
                              }
                              thumbnailHandler={(thumbnail: any) =>
                                handleCreateThumbnail(thumbnail, index)
                              }
                            />
                          </div>
                        )}
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => {
                            handleDeleteVideo(video)
                          }}
                          color="red"
                        />
                      </div>
                    )
                  })}
                </React.Fragment>
              )}
            </div>
            <EditPostMedia
              onChangeVideo={(video) =>
                setPost({ ...post, videos: [...post.videos, video] })
              }
              onChangeImage={(image: any) =>
                setPost({ ...post, images: [...post.images, image] })
              }
            />
            <ButtonGroup>
              <Button
                label={intl.formatMessage(Common_Edit)}
                ariaLabel={intl.formatMessage(Common_Edit)}
                variant="primary"
                onClick={handleEditPost}
              />
              <Button
                label={intl.formatMessage(Common_Cancel)}
                ariaLabel={intl.formatMessage(Common_Cancel)}
                variant="danger"
                onClick={() => history.goBack()}
              />
            </ButtonGroup>
          </React.Fragment>
        )}
      </Form>
    </PageContainer>
  )
}

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { ListItemLoader } from '../Loading'
import { RelationListItem } from './RelationListItem'
import { colors, spacing } from '../../../styles/styles'
import {
  Common_ErrorOccurred,
  Common_LoadMore,
} from '../../../translations/messages'
import { Relation, RequestStatus } from '../../../types'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { useIntl } from 'react-intl'
import { Button } from '../Buttons'

type Props = {
  userId: string | undefined
}

export const RelationList: FunctionComponent<Props> = ({ userId }) => {
  const intl = useIntl()
  const [network, setNetwork] = useState<Relation[]>([])
  const [notGroupedNetwork, setNotGroupedNetwork] = useState<Relation[]>([])
  const [status, setStatus] = useState<RequestStatus>('pending')
  const [loadMoreStatus, setLoadMoreStatus] = useState<RequestStatus>('idle')
  const [nextUrl, setNextUrl] = useState<string | null>(null)

  const groupByUser = (result: any) => {
    const groupByUserId = result.reduce((usersToGroup, { user, id }) => {
      ;(usersToGroup[user.id] = usersToGroup[user.id] || []).push(user.id)
      return usersToGroup
    }, {})
    const userIds = Object.keys(groupByUserId)
    let networkItems: any = []
    userIds.map((x) =>
      networkItems.push({
        userId: x,
        items: result.filter((i) => i.user.id === x),
        grouped:
          result.filter((i) => i.user.id === x)?.length > 1 ? true : false,
        allRelations: result
          .filter((i) => i.user.id === x)
          ?.map((x) => x.relation)
          .join(','),
      })
    )

    return networkItems
  }
  useEffect(() => {
    if (userId) {
      setStatus('idle')

      authenticatedFetch(`${environment.API_URL}/users/${userId}/network`)
        .then(({ results, next }) => {
          setStatus('idle')
          setNotGroupedNetwork(results)
          setNetwork(groupByUser(results))
          setNextUrl(next)
        })
        .catch(() => {
          setStatus('failure')
        })
    }
  }, [userId])

  const loadMoreRelations = useCallback(() => {
    if (loadMoreStatus !== 'pending' && nextUrl) {
      setLoadMoreStatus('pending')

      authenticatedFetch(nextUrl)
        .then(({ results, next }) => {
          setLoadMoreStatus('idle')
          setNextUrl(next)
          setNotGroupedNetwork([...notGroupedNetwork, ...results])
          const groupedResult = groupByUser([...notGroupedNetwork, ...results])
          setNetwork(groupedResult)
        })
        .catch(() => setLoadMoreStatus('failure'))
    }
  }, [nextUrl, loadMoreStatus, notGroupedNetwork])

  return (
    <React.Fragment>
      {status === 'pending' ? (
        <ListItemLoader />
      ) : (
        <React.Fragment>
          <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
            {network.map((r: any, i) => (
              <RelationListItem
                key={`relation-${i}`}
                relation={r.items[0]}
                allUserRelation={r.allRelations}
              />
            ))}
          </ul>

          {nextUrl && (
            <div style={{ textAlign: 'center', marginTop: spacing.md }}>
              <Button
                variant="secondary"
                isSmall
                ariaLabel={intl.formatMessage(Common_LoadMore)}
                label={intl.formatMessage(Common_LoadMore)}
                onClick={loadMoreRelations}
              />
            </div>
          )}
        </React.Fragment>
      )}

      {status === 'failure' && (
        <div
          style={{
            marginTop: spacing.md,
            textAlign: 'center',
            color: colors.error,
          }}
        >
          {intl.formatMessage(Common_ErrorOccurred)}
        </div>
      )}

      {loadMoreStatus === 'pending' && <ListItemLoader />}
    </React.Fragment>
  )
}

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { smallScreenBreakpoint, spacing } from '../../../../styles/styles'
import { HandleRelations } from '../../../shared/relations/HandleRelations'
import { Common_Admin, Common_Members } from '../../../../translations/messages'
import { useIntl } from 'react-intl'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Column = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin: 0 0 ${spacing.md} 0;

    @media only screen and (min-width: ${smallScreenBreakpoint}px) {
      margin: 0 ${spacing.md} 0 0;
    }
  }
`

type Props = {
  id: string
}

export const GroupMembers: FunctionComponent<Props> = ({ id }) => {
  const intl = useIntl()

  return (
    <Container>
      <Column>
        <HandleRelations
          id={id}
          dataKey="admin_ids"
          heading={intl.formatMessage(Common_Admin)}
          path="groups"
          entity="Group"
          relationType="admins"
          requiredLimit={1}
        />
      </Column>
      <Column>
        <HandleRelations
          id={id}
          dataKey="member_ids"
          heading={intl.formatMessage(Common_Members)}
          path="groups"
          entity="Group"
          relationType="members"
        />
      </Column>
    </Container>
  )
}

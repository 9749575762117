import React, { FunctionComponent, useContext, useState } from 'react'
import { TextField } from '../form/TextField'
import { Button } from '../Buttons'
import styled from 'styled-components'
import { spacing } from '../../../styles/styles'
import { Card } from '../card/Card'
import { CommentContext } from './CommentList'
import { MessageLoader } from '../Loading'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { ErrorMessage } from '../Messages'
import {
  Comment_WriteAComment,
  Common_ErrorOccurred,
  Common_Publish,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'

const Form = styled(Card)`
  width: 100%;
  padding: ${spacing.md};
  margin: ${spacing.md} 0;
  display: flex;
  align-items: center;
  align-content: space-between;

  div:first-child {
    flex: 1;
    margin-right: ${spacing.md};
  }
`

type Props = {
  parentId: string
}

export const CommentForm: FunctionComponent<Props> = ({ parentId }) => {
  const intl = useIntl()
  const { comments, setComments } = useContext(CommentContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [text, setText] = useState<string>('')

  const createComment = () => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/comments`,
      { method: 'POST' },
      {
        parent_id: parentId,
        text,
      }
    )
      .then((comment) => {
        setStatus('idle')
        setComments([...comments, comment])
        setText('')
      })
      .catch(() => setStatus('failure'))
  }

  return (
    <React.Fragment>
      <Form>
        <TextField
          id="text"
          type="text"
          label={Comment_WriteAComment}
          value={text}
          onChange={setText}
        />
        <Button
          label={intl.formatMessage(Common_Publish)}
          ariaLabel={intl.formatMessage(Common_Publish)}
          onClick={createComment}
          isSmall={true}
          variant="primary"
        />
      </Form>

      {status === 'failure' && (
        <ErrorMessage style={{ marginBottom: spacing.md }}>
          {intl.formatMessage(Common_ErrorOccurred)}
        </ErrorMessage>
      )}

      {status === 'pending' && (
        <div style={{ marginBottom: spacing.md }}>
          <MessageLoader />
        </div>
      )}
    </React.Fragment>
  )
}

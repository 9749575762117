import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { MessageCard } from './MessageCard'
import { MessageLoader } from '../Loading'
import styled from 'styled-components'
import { useMessages } from '../../../contexts/MessageProvider'
import { useScrolledToBottom } from '../../../hooks/useScrolledToBottom'
import { environment } from '../../../environments'
import { authenticatedFetch } from '../../../lib/service'
import { FadeIn } from '../animations/FadeIn'
import { EmptyState } from '../EmptyState'
import {
  Messages_WriteAMessage,
  Messages_EmptyStateDescription,
  Messages_EmptyStateTitle,
  Messages_MarkAllAsRead,
} from '../../../translations/messages'
import { FeedHeaderItem } from '../FeedHeaderItem'
import { useHistory } from 'react-router-dom'
import { NoAccess } from '../NoAccess'
import { Button } from '../Buttons'
import { useIntl } from 'react-intl'
import { spacing } from '../../../styles/styles'

type Props = {
  ownerId?: string
  canCreate: boolean
}

const FeedContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 37.5rem;
`

export const MessageFeed: FunctionComponent<Props> = ({
  ownerId,
  canCreate,
}) => {
  const intl = useIntl()
  const history = useHistory()
  const {
    allMessages,
    setAllMessages,
    allMessagesNextUrl,
    setAllMessagesNextUrl,
    messagesByOwner,
    setMessagesByOwner,
    messagesByOwnerNextUrl,
    setMessagesByOwnerNextUrl,
    markAllMessagesAsRead,
    unreadMessages,
  } = useMessages()
  const [initialStatus, setInitialStatus] = useState<
    'idle' | 'pending' | 'failure'
  >(allMessages ? 'idle' : 'pending')
  const [loadMoreStatus, setLoadMoreStatus] = useState<
    'idle' | 'pending' | 'failure'
  >('idle')

  useScrolledToBottom(() => loadMoreMessages())

  useEffect(() => {
    const url = ownerId
      ? `${environment.API_URL}/messages/owner/${ownerId}`
      : `${environment.API_URL}/messages`

    authenticatedFetch(url)
      .then(({ results, next }) => {
        setInitialStatus('idle')

        if (ownerId) {
          setMessagesByOwner((prevState: any) => ({
            ...prevState,
            [ownerId]: results,
          }))
          setMessagesByOwnerNextUrl((prevState: any) => ({
            ...prevState,
            [ownerId]: next,
          }))
        } else {
          setAllMessages(results)
          setAllMessagesNextUrl(next)
        }
      })
      .catch(() => setInitialStatus('failure'))
  }, [
    ownerId,
    setMessagesByOwner,
    setAllMessages,
    setAllMessagesNextUrl,
    setMessagesByOwnerNextUrl,
  ])

  const loadMoreMessages = useCallback(() => {
    const url = ownerId ? messagesByOwnerNextUrl[ownerId] : allMessagesNextUrl

    if (url && loadMoreStatus !== 'pending') {
      setLoadMoreStatus('pending')

      authenticatedFetch(url)
        .then(({ results, next }) => {
          setLoadMoreStatus('idle')

          if (ownerId) {
            setMessagesByOwner((prevState: any) => ({
              ...prevState,
              [ownerId]: [...prevState[ownerId], ...results],
            }))
            setMessagesByOwnerNextUrl((prevState: any) => ({
              ...prevState,
              [ownerId]: next,
            }))
          } else {
            setAllMessages((prevState: any[]) => [...prevState, ...results])
            setAllMessagesNextUrl(next)
          }
        })
        .catch(() => setLoadMoreStatus('failure'))
    }
  }, [
    ownerId,
    loadMoreStatus,
    allMessagesNextUrl,
    setAllMessages,
    setAllMessagesNextUrl,
    setMessagesByOwner,
    messagesByOwnerNextUrl,
    setMessagesByOwnerNextUrl,
  ])

  if (initialStatus === 'pending') {
    return (
      <FeedContainer>
        <MessageLoader />
      </FeedContainer>
    )
  }

  if (initialStatus === 'failure') {
    return (
      <FeedContainer>
        <NoAccess />
      </FeedContainer>
    )
  }

  const messages = ownerId ? messagesByOwner[ownerId] : allMessages
  const createUrl = ownerId ? `/messages/create/${ownerId}` : `/messages/create`

  return (
    <FeedContainer>
      {canCreate && (
        <FeedHeaderItem
          label={Messages_WriteAMessage}
          onClick={() => history.push(createUrl)}
        />
      )}

      {messages && messages.length > 0 && (
        <FadeIn showOn={true}>
          <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
            {!ownerId && unreadMessages > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: spacing.md,
                  marginBottom: spacing.md,
                }}
              >
                <Button
                  ariaLabel={intl.formatMessage(Messages_MarkAllAsRead)}
                  label={intl.formatMessage(Messages_MarkAllAsRead)}
                  onClick={markAllMessagesAsRead}
                  variant="secondary"
                  isSmall
                />
              </div>
            )}

            {messages.map((message: any) => (
              <li key={message.id}>
                <MessageCard data={message} />
              </li>
            ))}
          </ul>
        </FadeIn>
      )}

      {messages && messages.length === 0 && (
        <EmptyState
          title={Messages_EmptyStateTitle}
          text={Messages_EmptyStateDescription}
        />
      )}

      {loadMoreStatus === 'pending' && <MessageLoader />}
    </FeedContainer>
  )
}

export const spacing = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '32px',
  xl: '64px',
  xl2: '128px',
}

export const colors = {
  joda: '#33707E',
  jodaLight: '#679ead',
  jodaDark: '#004552',
  safe: '#6a1b9a',
  safeLight: '#9c4dcc',
  safeDark: '#38006b',
  warmthLight: '#ff8076',
  warmth: '#ffcdd2',
  warmthDark: '#c50021',
  close: '#ad1457',
  closeLight: '#e35183',
  closeDark: '#78002e',
  trust: '#ffcdd2',
  trustLight: '#ffebee',
  trustDark: '#cb9ca1',
  hope: '#e1f5fe',
  hopeLight: '#f4fcff',
  hopeDark: '#afc2cb',
  black: '#000000',
  black90: '#191919',
  black80: '#333333',
  black70: '#4c4c4c',
  black60: '#666666',
  black50: '#7f7f7f',
  black40: '#999999',
  black30: '#b2b2b2',
  black20: '#cccccc',
  black10: '#e5e5e5',
  black5: '#ececec',
  white: '#ffffff',
  error: '#ff4a4a',
}

export const borderRadius = '4px'

export const breakpoints = {
  XSMALL: '320px',
  SMALL: '600px',
  MEDIUM: '768px',
  LARGE: '992px',
  XLARGE: '1200px',
}

export const smallScreenBreakpoint = 1200

import React, { useContext } from 'react'
import { List, ListPageContainer } from '../../../shared/list'
import { environment } from '../../../../environments'
import { PageHeader } from '../../../shared/layout/PageHeader'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import { InstitutionListItem } from './InstitutionListItem'
import {
  Common_Institutions,
  Institutions_EmptyState,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'

export const InstitutionPage = () => {
  const intl = useIntl()
  const { updateNavBarTitle } = useContext(NavBarContext)

  const title = intl.formatMessage(Common_Institutions)
  updateNavBarTitle(title)

  return (
    <ListPageContainer>
      <PageHeader title={title} />

      <List
        emptyState={intl.formatMessage(Institutions_EmptyState)}
        ListItem={InstitutionListItem}
        url={`${environment.API_URL}/institutions`}
      />
    </ListPageContainer>
  )
}

import React, { useContext, useState } from 'react'
import { List, ListPageContainer } from '../../../shared/list'
import { environment } from '../../../../environments'
import { PageHeader } from '../../../shared/layout/PageHeader'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import { InstitutionListItem } from './InstitutionListItem'
import {
  Common_Institutions,
  Institutions_EmptyState,
  MyInstitutions_ShowHierarchy,
  MyInstitutions_ShowList,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { Button } from '../../../shared/Buttons'
import styled from 'styled-components'
import { InstitutionHierarchy } from './hierarchy/InstitutionHierarchy'

const ButtonGroup = styled.div`
  button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

export const MyInstitutionPage = () => {
  const intl = useIntl()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const [view, setView] = useState<'LIST' | 'HIERARCHY'>('HIERARCHY')

  const title = intl.formatMessage(Common_Institutions)
  updateNavBarTitle(title)

  return (
    <ListPageContainer>
      <PageHeader
        title={title}
        trailingElement={
          <ButtonGroup>
            <Button
              label={intl.formatMessage(MyInstitutions_ShowList)}
              ariaLabel={intl.formatMessage(MyInstitutions_ShowList)}
              isSmall
              onClick={() => setView('LIST')}
              variant={view === 'LIST' ? 'primary' : 'secondary'}
            />
            <Button
              label={intl.formatMessage(MyInstitutions_ShowHierarchy)}
              ariaLabel={intl.formatMessage(MyInstitutions_ShowHierarchy)}
              isSmall
              onClick={() => setView('HIERARCHY')}
              variant={view === 'HIERARCHY' ? 'primary' : 'secondary'}
            />
          </ButtonGroup>
        }
      />

      {view === 'HIERARCHY' ? (
        <InstitutionHierarchy />
      ) : (
        <List
          emptyState={intl.formatMessage(Institutions_EmptyState)}
          ListItem={InstitutionListItem}
          url={`${environment.API_URL}/institutions`}
        />
      )}
    </ListPageContainer>
  )
}

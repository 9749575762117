import React, { useContext, useEffect, useState } from 'react'
import { H1 } from '../../../shared/Typography'
import { PageCard, PageContainer } from '../../../shared/layout/Layout'
import { breakpoints, colors, spacing } from '../../../../styles/styles'
import {
  Common_Cancel,
  Common_Description,
  Common_ErrorOccurred,
  Common_Name,
  Common_Save,
  Groups_Create,
} from '../../../../translations/messages'
import { useIntl } from 'react-intl'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import { RequestStatus } from '../../../../types'
import { MessageLoader } from '../../../shared/Loading'
import { TextField } from '../../../shared/form/TextField'
import { Button } from '../../../shared/Buttons'
import { useHistory } from 'react-router-dom'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { FormButtons } from '../../../shared/form/FormButtons'

export const CreateGroupPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const [status, setStatus] = useState<RequestStatus>('idle')
  const [group, setGroup] = useState<{ name: string; description: string }>({
    name: '',
    description: '',
  })

  useEffect(() => {
    updateNavBarTitle(intl.formatMessage(Groups_Create))
  }, [updateNavBarTitle, intl])

  const createGroup = () => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/groups`,
      { method: 'POST' },
      group
    )
      .then((response) => {
        setStatus('idle')
        history.push(`/groups/${response.id}`)
      })
      .catch(() => setStatus('failure'))
  }

  return (
    <PageContainer style={{ maxWidth: breakpoints.LARGE }}>
      <PageCard>
        <H1 style={{ textAlign: 'center', marginBottom: spacing.lg }}>
          {intl.formatMessage(Groups_Create)}
        </H1>

        {status === 'pending' ? (
          <MessageLoader />
        ) : (
          <React.Fragment>
            <div
              style={{
                maxWidth: breakpoints.SMALL,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <TextField
                id="name"
                type="text"
                label={Common_Name}
                value={group.name}
                onChange={(value) => setGroup({ ...group, name: value })}
              />

              <TextField
                id="description"
                type="text"
                label={Common_Description}
                value={group.description}
                onChange={(value) => setGroup({ ...group, description: value })}
              />

              <FormButtons>
                <Button
                  ariaLabel={intl.formatMessage(Common_Cancel)}
                  label={intl.formatMessage(Common_Cancel)}
                  variant="danger"
                  onClick={() => history.goBack()}
                />

                <Button
                  ariaLabel={intl.formatMessage(Common_Save)}
                  label={intl.formatMessage(Common_Save)}
                  variant="primary"
                  disabled={!group.name || !group.description}
                  onClick={createGroup}
                />
              </FormButtons>

              {status === 'failure' && (
                <div style={{ color: colors.error, marginTop: spacing.md }}>
                  {intl.formatMessage(Common_ErrorOccurred)}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </PageCard>
    </PageContainer>
  )
}

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Card } from '../../../shared/card/Card'
import { colors, spacing } from '../../../../styles/styles'
import { ListAvatarIcon } from '../../../shared/list/ListAvatarIcon'
import { useHistory } from 'react-router-dom'
import { Institution_NumDepartments } from '../../../../translations/messages'
import { useIntl } from 'react-intl'

const ListItem = styled(Card)`
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  border-bottom: 1px solid ${colors.black5};
`

type Props = {
  data: any
}

export const InstitutionListItem: FunctionComponent<Props> = ({ data }) => {
  const history = useHistory()
  const intl = useIntl()
  const { id, name, children } = data

  return (
    <li>
      <ListItem onClick={() => history.push(`/institutions/${id}`)}>
        <ListAvatarIcon icon={faBuilding} />

        <div>
          <div>{name}</div>
          <small>
            {intl.formatMessage(Institution_NumDepartments, {
              numDepartments: children.length,
            })}
          </small>
        </div>
      </ListItem>
    </li>
  )
}

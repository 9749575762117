import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { LoginPage } from './components/pages/authentication/LoginPage'
import { VerifyAccountPage } from './components/pages/authentication/VerifyAccountPage'
import { RegisterWelcomePage } from './components/pages/authentication/register/RegisterWelcomePage'
import { LoginNavBar } from './components/pages/authentication/common/LoginNavBar'
import { SelectRolePage } from './components/pages/authentication/register/SelectRolePage'
import { CreateAccountPage } from './components/pages/authentication/register/CreateAccountPage'
import { VerifyInfoPage } from './components/pages/authentication/register/VerifyInfoPage'
import { ContactUsPage } from './components/pages/authentication/register/ContactUsPage'

export const UnAuthenticatedApp = () => {
  return (
    <React.Fragment>
      <LoginNavBar />

      <Switch>
        <Route path={`/login/:token`}>
          <LoginPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/register" exact>
          <RegisterWelcomePage />
        </Route>
        <Route path="/register/select-role" exact>
          <SelectRolePage />
        </Route>
        <Route path="/register/:role/create-account" exact>
          <CreateAccountPage />
        </Route>
        <Route path="/register/verify-info" exact>
          <VerifyInfoPage />
        </Route>
        <Route path="/contact-us" exact>
          <ContactUsPage />
        </Route>
        <Route path="/verify/:token">
          <VerifyAccountPage />
        </Route>
        <Redirect to={`/login`} />
      </Switch>
    </React.Fragment>
  )
}

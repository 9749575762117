import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../shared/layout/Layout'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { MessageLoader } from '../../../shared/Loading'
import { spacing } from '../../../../styles/styles'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import { SubPageTabs } from '../../../shared/SubPageTabs'
import { faBookOpen, faUsers } from '@fortawesome/free-solid-svg-icons'
import { PostFeed } from '../../../shared/post/PostFeed'
import { GroupMembers } from './GroupMembers'
import { InviteButton } from '../../../shared/invitations/InviteButton'
import {
  Common_Admin,
  Common_Book,
  Common_Member,
  Common_Members,
  Invitations_Invitations,
} from '../../../../translations/messages'
import { usePermissions } from '../../../../contexts/PermissionProvider'
import { hasPermission } from '../../../../lib/permissionHelpers'
import { GroupDetailPageHeader } from './GroupDetailPageHeader'
import { useIntl } from 'react-intl'
import { InvitationList } from '../../../shared/invitations/InvitationList'

const tabs = [
  { label: Common_Book, icon: faBookOpen },
  { label: Common_Members, icon: faUsers },
]

export const GroupDetailPage = () => {
  const intl = useIntl()
  const { id } = useParams<{ id: string }>()
  const { updateNavBarTitle } = useContext(NavBarContext)
  const { relationsByTargetId } = usePermissions(id)

  const [activeTab, setActiveTab] = useState<number>(0)
  const [group, setGroup] = useState<any>()

  const relations = relationsByTargetId[id]
  const canCreatePost = hasPermission(relations, 'Post', 'CREATE')
  const canEditGroup = hasPermission(relations, 'Group', 'UPDATE')
  const canDeleteGroup = hasPermission(relations, 'Group', 'DELETE')
  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/groups/${id}`).then(
      (response) => {
        setGroup(response)
        updateNavBarTitle(response.name)
      }
    )
  }, [id, updateNavBarTitle])

  return (
    <PageContainer>
      {group ? (
        <React.Fragment>
          <GroupDetailPageHeader
            group={group}
            handleChangeGroup={setGroup}
            canEdit={canEditGroup}
            canDelete={canDeleteGroup}
          />

          <SubPageTabs
            tabs={tabs}
            activeIndex={activeTab}
            onTabChanged={setActiveTab}
          />

          {activeTab === 0 && (
            <PostFeed ownerId={id} canCreate={canCreatePost} />
          )}

          {activeTab === 1 && (
            <React.Fragment>
              {canEditGroup && (
                <div style={{ marginBottom: spacing.md }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <h3 style={{ margin: 0 }}>
                      {intl.formatMessage(Invitations_Invitations)}
                    </h3>

                    <InviteButton
                      id={id}
                      entityType="Group"
                      relations={[
                        { key: 'MEMBER', value: Common_Member },
                        { key: 'ADMIN', value: Common_Admin },
                      ]}
                    />
                  </div>

                  <InvitationList id={id} />
                </div>
              )}

              <GroupMembers id={id} />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <MessageLoader />
      )}
    </PageContainer>
  )
}

import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'
import { createContext, FunctionComponent } from 'react'
import norwegian from '../translations/locales/nb.json'

const local = navigator.language.split('-')[0]

const messages: { [key: string]: any } = {
  nb: norwegian,
}

type LangCtx = {
  locale: string
  selectLanguage: (newLocale: string) => void
}

export const LanguageContext = createContext<LangCtx>({
  locale: local,
  selectLanguage: () => {},
})

export const LanguageProvider: FunctionComponent = ({ children }) => {
  const [locale, setLocale] = useState(local)

  const selectLanguage = (newLocale: string) => {
    setLocale(newLocale)
  }

  return (
    <LanguageContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider
        defaultLocale="en"
        locale={locale}
        messages={messages[locale]}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'
import { Invitation } from '../../../types'
import { relationsMap } from '../../../relationMap'
import { useIntl } from 'react-intl'

const InvitationItemStyled = styled.div`
  padding: ${spacing.md};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.black20};

  small {
    display: block;
    margin-right: ${spacing.md};
  }
`

const User = styled.div`
  margin-right: ${spacing.md};
`

type Props = {
  invitation: Invitation
  trailingElement?: ReactNode
}

export const InvitationItem: FunctionComponent<Props> = ({
  invitation,
  trailingElement,
}) => {
  const intl = useIntl()

  const { first_name, last_name, email, relation, entity_name } = invitation

  return (
    <InvitationItemStyled>
      <User>
        {first_name} {last_name}
        <small>{email}</small>
      </User>
      <div>
        <div>
          {relation && relationsMap[relation]
            ? intl.formatMessage(relationsMap[relation])
            : relation}
        </div>
        {entity_name}
      </div>
      {trailingElement && trailingElement}
    </InvitationItemStyled>
  )
}

import React, { FunctionComponent, useContext } from 'react'
import { environment } from '../../../environments'
import { AuthContext } from '../../../contexts/AuthContext'
import { List } from '../list'
import styled from 'styled-components'
import { Card } from '../card/Card'
import { breakpoints, colors, spacing } from '../../../styles/styles'
import { Avatar } from '../Avatar'
import { relationsMap } from '../../../relationMap'
import { useIntl } from 'react-intl'
import { usePermissions } from '../../../contexts/PermissionProvider'
import { hasPermission } from '../../../lib/permissionHelpers'
import { NoAccess } from '../NoAccess'
import { useHistory } from 'react-router-dom'
import {
  Invitations_Invitations,
  Relation_ClosestRelative,
  Relation_Employee,
  Relation_Family,
  Relation_PrimaryContact,
  Relations_EmptyState,
} from '../../../translations/messages'
import { InviteButton } from '../invitations/InviteButton'
import { InvitationList } from '../invitations/InvitationList'

const Container = styled.div`
  max-width: ${breakpoints.SMALL};
  margin-left: auto;
  margin-right: auto;
`

const ListItem = styled(Card)`
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  border-bottom: 1px solid ${colors.black5};
`

type Prop = {
  ownerId: string
}

type Props = {
  data: any
}

const RelationListItem: FunctionComponent<Props> = ({ data }) => {
  const intl = useIntl()
  const history = useHistory()
  const { user, relation, hub } = data
  return (
    <li>
      <ListItem onClick={() => history.push(`/profile/${user.id}`)}>
        <Avatar
          size="small"
          image={user.image}
          alt={`Bilde av ${user.first_name}`}
        />
        <div style={{ marginLeft: spacing.md }}>
          <div>
            {user.first_name} {user.last_name}
          </div>
          <small>
            {relationsMap[relation]
              ? intl.formatMessage(relationsMap[relation])
              : relation}{' '}
            {hub ? hub.name : ''}
          </small>
        </div>
      </ListItem>
    </li>
  )
}

export const Relations: FunctionComponent<Prop> = ({ ownerId }) => {
  const intl = useIntl()
  const { relationsByTargetId } = usePermissions(ownerId)
  const { authUser } = useContext(AuthContext)

  const relations = relationsByTargetId[ownerId]
  const canView = hasPermission(relations, 'Relation', 'READ')
  const canEdit = hasPermission(relations, 'User', 'UPDATE')

  const url = ownerId
    ? `${environment.API_URL}/users/${ownerId}/network`
    : `${environment.API_URL}/users/${authUser!.id}/network`

  return (
    <Container>
      {canView ? (
        <div>
          {canEdit && ownerId && (
            <div
              style={{
                marginBottom: spacing.md,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  marginBottom: spacing.md,
                }}
              >
                <h3 style={{ margin: 0 }}>
                  {intl.formatMessage(Invitations_Invitations)}
                </h3>

                <InviteButton
                  id={ownerId}
                  entityType="User"
                  relations={[
                    { key: 'EMPLOYEE', value: Relation_Employee },
                    {
                      key: 'CLOSEST_RELATIVE',
                      value: Relation_ClosestRelative,
                    },
                    { key: 'FAMILY', value: Relation_Family },
                    { key: 'PRIMARY_CONTACT', value: Relation_PrimaryContact },
                  ]}
                />
              </div>

              <InvitationList id={ownerId} />
            </div>
          )}

          <List
            emptyState={intl.formatMessage(Relations_EmptyState)}
            ListItem={RelationListItem}
            url={url}
            forUserList={true}
          />
        </div>
      ) : (
        <NoAccess />
      )}
    </Container>
  )
}

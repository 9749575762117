import styled from 'styled-components'

export const H1 = styled.h1`
  margin: 0;
  font-size: 3rem;
  line-height: 1.4;
`

export const H2 = styled.h2`
  margin: 0;
`

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { ChangeEvent, FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../styles/styles'
import { useIntl } from 'react-intl'
import { Common_AddMedia } from '../../translations/messages'

const EditPostMediaButton = styled.label`
  display: block;
  padding: ${spacing.lg};
  border: 1px solid ${colors.black20};
  background: ${colors.black5};
  text-align: center;

  svg {
    margin-bottom: ${spacing.md};
    font-size: 1.5rem;
  }

  input {
    visibility: hidden;
  }

  &:active {
    background: white;
  }
`

type Props = {
  onChangeVideo: (video: any) => void
  onChangeImage: (image: any) => void
}

export const EditPostMedia: FunctionComponent<Props> = ({
  onChangeVideo,
  onChangeImage,
}) => {
  const intl = useIntl()
  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    // @ts-ignore
    if (target.files.length > 0) {
      // @ts-ignore
      const file = target.files[0]
      if (file.type.includes('video')) {
        onChangeVideo({ file, thumbnail: null })
      } else if (file.type.includes('image')) {
        onChangeImage(file)
      }
    }
  }
  return (
    <div>
      <EditPostMediaButton htmlFor="file">
        <FontAwesomeIcon icon={faPlus} />
        <div>{intl.formatMessage(Common_AddMedia)}</div>
        <input
          id="file"
          type="file"
          onChange={onFileChange}
          accept="video/mp4,video/mov,video/quicktime,image/x-png,image/jpg,image/jpeg"
        />
      </EditPostMediaButton>
    </div>
  )
}

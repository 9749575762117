import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'
import { ErrorMessage } from '../Messages'
import { TranslatedMessage } from '../../../types'
import { useIntl } from 'react-intl'

const TextFieldStyled = styled.div`
  margin-bottom: ${spacing.md};
`

const Label = styled.label`
  display: block;
  width: 100%;
  text-transform: uppercase;
`

const Input = styled.input`
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid ${colors.black80};
  font-size: inherit;
  font-family: inherit;
  padding: ${spacing.sm} 0;
`

type Props = {
  id: string
  type: string
  label: TranslatedMessage
  value: string
  required?: boolean
  onChange: (value: string) => void
  error?: string
  helpText?: { id: string; defaultMessage: string }
}

export const TextField: FunctionComponent<Props> = ({
  id,
  label,
  type,
  value,
  required,
  onChange,
  error,
  helpText,
}) => {
  const intl = useIntl()
  const handleKeyDown = (event, type) => {
    if (type === 'date') event.preventDefault()
  }
  return (
    <TextFieldStyled>
      <Label htmlFor={id}>{intl.formatMessage(label)}</Label>
      <Input
        id={id}
        type={type}
        value={value}
        required={required}
        onChange={({ target: { value } }) => onChange(value)}
        onKeyDown={(event) => handleKeyDown(event, type)}
      />
      {helpText && <div>{intl.formatMessage(helpText)}</div>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </TextFieldStyled>
  )
}

export const getBlob = async (fileUri: string) => {
  const resp = await fetch(fileUri)
  return await resp.blob()
}
export function b64toBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1])
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/jpeg' })
}

import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { authenticatedFetch } from '../../../lib/service'
import { environment } from '../../../environments'
import { MessageLoader } from '../Loading'
import { Comment } from './Comment'
import styled from 'styled-components'
import { spacing } from '../../../styles/styles'
import { CommentForm } from './CommentForm'
import { AnimateHeight } from '../animations/AnimateHeight'

const ListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin-bottom: ${spacing.md};
`

export const CommentContext = createContext({
  updatingComment: '',
  comments: [],
  setComments: (comments: any[]) => {},
  deleteComment: (id: string) => {},
  updateComment: (id: string, text: string) => {},
})

const CommentProvider: FunctionComponent = ({ children }) => {
  const [comments, setComments] = useState<any>([])
  const [updatingComment, setUpdatingComment] = useState<string>('')

  const deleteComment = (id: string) => {
    setUpdatingComment(id)

    authenticatedFetch(`${environment.API_URL}/comments/${id}`, {
      method: 'DELETE',
    })
      .then(() => {
        setUpdatingComment('')
        setComments(comments.filter((c: any) => c.id !== id))
      })
      .catch(() => {
        setUpdatingComment('')
      })
  }

  const updateComment = (id: string, text: string) => {
    setUpdatingComment(id)

    authenticatedFetch(
      `${environment.API_URL}/comments/${id}`,
      {
        method: 'PATCH',
      },
      { text }
    )
      .then((updated) => {
        setUpdatingComment('')
        setComments(comments.map((c: any) => (c.id === id ? updated : c)))
      })
      .catch(() => {
        setUpdatingComment('')
      })
  }

  return (
    <CommentContext.Provider
      value={{
        updatingComment,
        comments,
        setComments,
        deleteComment,
        updateComment,
      }}
    >
      {children}
    </CommentContext.Provider>
  )
}

const List: FunctionComponent<Props> = ({ parentId }) => {
  const { comments, setComments } = useContext(CommentContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')

  useEffect(() => {
    setStatus('pending')

    authenticatedFetch(`${environment.API_URL}/comments/parent/${parentId}`)
      .then((results) => {
        setStatus('idle')
        setComments(results)
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [parentId, setComments])

  if (status === 'pending' && comments.length === 0) {
    return <MessageLoader />
  }

  return (
    <AnimateHeight animateOn={comments}>
      <ListStyled>
        {comments.map((comment) => (
          <ListItem>
            <Comment comment={comment} />
          </ListItem>
        ))}
        {status === 'pending' && <MessageLoader />}
      </ListStyled>
    </AnimateHeight>
  )
}

type Props = {
  parentId: string
}

export const CommentList: FunctionComponent<Props> = ({ parentId }) => {
  return (
    <CommentProvider>
      <CommentForm parentId={parentId} />
      <List parentId={parentId} />
    </CommentProvider>
  )
}

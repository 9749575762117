import React, { useState, FunctionComponent } from 'react'
import { authenticatedFetch } from '../../../../lib/service'
import { environment } from '../../../../environments'
import { Card } from '../../../shared/card/Card'
import styled from 'styled-components'
import {
  smallScreenBreakpoint,
  spacing,
  colors,
} from '../../../../styles/styles'
import {
  Common_Edit,
  Common_Name,
  Common_Description,
  Common_Close,
  Common_Save,
  Common_ErrorOccurred,
  Edit_Team,
} from '../../../../translations/messages'
import { Button, ButtonGroup } from '../../../shared/Buttons'
import { useIntl } from 'react-intl'
import { TextField } from '../../../shared/form/TextField'
import { ProfileHeader } from '../../../shared/ProfileHeader'
import { MessageLoader } from '../../../shared/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const TeamCard = styled(Card)`
  position: relative;
  margin-bottom: ${spacing.md};
  padding: ${spacing.lg};

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    padding: ${spacing.md};
  }

  h1,
  p {
    text-align: center;
  }
`
type Props = {
  team: any
  handleChangeTeam: (value: any) => void
}

export const TeamDetailPageHeader: FunctionComponent<Props> = ({
  team,
  handleChangeTeam,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const intl = useIntl()

  const handleEditGroupDetails = () => {
    setStatus('pending')
    authenticatedFetch(
      `${environment.API_URL}/teams/${team.id}`,
      { method: 'PATCH' },
      { name: team.name, description: team.description }
    )
      .then(() => {
        setStatus('idle')
        setEditMode(false)
      })
      .catch(() => {
        setStatus('failure')
      })
  }
  return (
    <TeamCard>
      {status === 'pending' ? (
        <MessageLoader />
      ) : (
        <>
          {editMode && <h1>{intl.formatMessage(Edit_Team)}</h1>}

          {!editMode ? (
            <>
              <div
                style={{
                  position: 'absolute',
                  top: spacing.md,
                  right: spacing.md,
                }}
              >
                <Button
                  ariaLabel={intl.formatMessage(Common_Edit)}
                  label={intl.formatMessage(Common_Edit)}
                  onClick={() => setEditMode(!editMode)}
                  variant="secondary"
                  isSmall={true}
                  smallScreenIcon={<FontAwesomeIcon icon={faEdit} />}
                />
              </div>
              <ProfileHeader user={team.owner}>
                {team.description && (
                  <div style={{ textAlign: 'center' }}>{team.description}</div>
                )}
              </ProfileHeader>
            </>
          ) : (
            <div style={{ maxWidth: '50%' }}>
              <TextField
                id="name"
                type="text"
                label={Common_Name}
                value={team.name}
                onChange={(value) => handleChangeTeam({ ...team, name: value })}
              />
              <TextField
                id="lastName"
                type="text"
                label={Common_Description}
                value={team.description}
                onChange={(value) =>
                  handleChangeTeam({ ...team, description: value })
                }
                required={true}
              />
            </div>
          )}
          {status === 'failure' && (
            <div
              style={{
                color: colors.error,
                marginTop: spacing.md,
                textAlign: 'right',
              }}
            >
              {intl.formatMessage(Common_ErrorOccurred)}
            </div>
          )}
          {editMode && (
            <ButtonGroup>
              <Button
                ariaLabel={intl.formatMessage(Common_Close)}
                label={intl.formatMessage(Common_Close)}
                onClick={() => setEditMode(!editMode)}
                variant="secondary"
                isSmall={true}
              />
              <Button
                ariaLabel={intl.formatMessage(Common_Save)}
                label={intl.formatMessage(Common_Save)}
                onClick={() => handleEditGroupDetails()}
                variant="primary"
                isSmall={true}
              />
            </ButtonGroup>
          )}
        </>
      )}
    </TeamCard>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { PageContainer } from '../../shared/layout/Layout'
import { TextAreaField } from '../../shared/form/TextArea'
import styled from 'styled-components'
import { Card } from '../../shared/card/Card'
import {
  breakpoints,
  smallScreenBreakpoint,
  spacing,
} from '../../../styles/styles'
import { Button } from '../../shared/Buttons'
import { OwnerSelector } from '../../shared/owner-selector/OwnerSelector'
import {
  authenticatedFetch,
  uploadImages,
  uploadVideos,
} from '../../../lib/service'
import { environment } from '../../../environments'
import { MessageLoader } from '../../shared/Loading'
import { AddMedia } from '../../shared/AddMedia'
import {
  Common_Cancel,
  Common_Save,
  Common_Text,
} from '../../../translations/messages'
import { useIntl } from 'react-intl'

const Form = styled(Card)`
  padding: ${spacing.lg};
  margin-left: auto;
  margin-right: auto;
  max-width: ${breakpoints.LARGE};
`

const ButtonGroup = styled.div`
  margin-top: ${spacing.lg};
  display: flex;
  flex-direction: column;

  button:last-child {
    margin-top: ${spacing.md};
  }

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    justify-content: flex-start;
    flex-direction: row-reverse;

    button:last-child {
      margin-top: 0;
      margin-right: ${spacing.md};
    }
  }
`

const Heading = styled.h1`
  display: none;

  @media only screen and (min-width: ${smallScreenBreakpoint}px) {
    display: block;
    text-align: center;
    margin-bottom: ${spacing.lg};
  }
`

const initialPost = {
  owner_ids: [],
  text: '',
  images: [],
  videos: [],
}

export const CreatePostPage = () => {
  const history = useHistory()
  const intl = useIntl()
  const { ownerId } = useParams<{ ownerId: string }>()

  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [post, setPost] = useState<{
    owner_ids: string[]
    text: string
    images: any[]
    videos: any[]
  }>(initialPost)
  const [bookStatus, setBookStatus] = useState<'idle' | 'pending' | 'failure'>(
    'idle'
  )
  const [owners, setOwners] = useState<any[]>([])
  const [owner, setOwner] = useState<any>({})

  useEffect(() => {
    if (ownerId) {
      setBookStatus('pending')

      authenticatedFetch(
        `${environment.API_URL}/books/${ownerId}?contentType=Post`
      )
        .then((response) => {
          setBookStatus('idle')
          setOwner(response)
        })
        .catch(() => setBookStatus('failure'))
    }
  }, [ownerId, setOwner])

  const createPost = async () => {
    setStatus('pending')
    Promise.all([uploadImages(post.images), uploadVideos(post.videos)])
      .then(([imageIds, videoIds]) => {
        return authenticatedFetch(
          `${environment.API_URL}/posts/bulk`,
          { method: 'POST' },
          {
            text: post.text ? post.text : null,
            owner_ids:
              owners && owners.length > 0 ? owners.map((o) => o.id) : [ownerId],
            image_ids: imageIds,
            video_ids: videoIds,
          }
        )
      })
      .then(() => {
        setStatus('idle')
        history.goBack()
      })
      .catch(() => {
        setStatus('failure')
      })
  }
  const onImageUploadAction = async (media: any[], mediaType: string) => {
    if (mediaType === 'image') {
      setPost({ ...post, images: media })
    } else if (mediaType === 'video') {
      setPost({ ...post, videos: media })
    }
  }
  const hasMedia =
    (post.images && post.images.length > 0) ||
    (post.videos && post.videos.length > 0)
  const postIsValid = (hasMedia || post.text) && (owners.length > 0 || ownerId)
  return (
    <PageContainer>
      <Form>
        {status === 'pending' ? (
          <MessageLoader />
        ) : (
          <React.Fragment>
            {owner && owner.name ? (
              <Heading>{owner.name}</Heading>
            ) : (
              <OwnerSelector
                selected={owners}
                contentType="Post"
                disabled={bookStatus === 'pending'}
                onChange={(selected) => setOwners(selected)}
              />
            )}
            <TextAreaField
              id="text"
              label={Common_Text}
              value={post.text}
              onChange={(value) => setPost({ ...post, text: value })}
            />

            <AddMedia onChange={onImageUploadAction} />

            <ButtonGroup>
              <Button
                label={intl.formatMessage(Common_Save)}
                ariaLabel={intl.formatMessage(Common_Save)}
                variant="primary"
                onClick={createPost}
                disabled={!postIsValid}
              />
              <Button
                label={intl.formatMessage(Common_Cancel)}
                ariaLabel={intl.formatMessage(Common_Cancel)}
                variant="danger"
                onClick={() => history.goBack()}
              />
            </ButtonGroup>
          </React.Fragment>
        )}
      </Form>
    </PageContainer>
  )
}

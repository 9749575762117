import React, { FunctionComponent } from 'react'
import { Dialog } from './Dialog'
import styled from 'styled-components'
import { breakpoints, spacing } from '../../../styles/styles'
import { Button } from '../Buttons'
import { FormattedMessage, useIntl } from 'react-intl'
import { TranslatedMessage } from '../../../types'
import { Common_Ok } from '../../../translations/messages'
import { DialogCard } from './ConfirmDialog'

const DialogTitle = styled.h1`
  margin: 0;

  @media only screen and (max-width: ${breakpoints.MEDIUM}) {
    margin-bottom: ${spacing.md};
    font-size: 1.2rem;
  }
`

const DialogText = styled.p`
  margin: ${spacing.lg} 0 0 0;
`

const DialogActions = styled.div`
  display: flex;
  flex-direction: column;

  button {
    height: 3rem;

    &:last-of-type {
      margin: ${spacing.md} 0 0 0;
    }
  }

  @media only screen and (min-width: ${breakpoints.LARGE}) {
    margin-top: ${spacing.lg};
    flex-direction: row;
    justify-content: flex-end;

    button {
      height: 2.5rem;

      &:last-of-type {
        margin: 0 0 0 ${spacing.md};
      }
    }
  }
`

type Props = {
  show: boolean
  title: TranslatedMessage
  description?: TranslatedMessage
  confirmButtonText?: TranslatedMessage
  onClose: () => void
  onConfirm: () => void
}

export const AlertDialog: FunctionComponent<Props> = ({
  show,
  title,
  description,
  confirmButtonText,
  onClose,
  onConfirm,
}) => {
  const intl = useIntl()

  return show ? (
    <Dialog onClose={onClose}>
      <DialogCard>
        <DialogTitle>
          <FormattedMessage
            id={title.id}
            defaultMessage={title.defaultMessage}
          />
        </DialogTitle>

        {description && <DialogText>{description}</DialogText>}

        <DialogActions>
          <Button
            ariaLabel={intl.formatMessage(confirmButtonText ?? Common_Ok)}
            label={intl.formatMessage(confirmButtonText ?? Common_Ok)}
            onClick={onConfirm}
            isSmall={true}
            variant="primary"
          />
        </DialogActions>
      </DialogCard>
    </Dialog>
  ) : null
}
